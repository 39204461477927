import Vue from 'vue'
import VueRouter from 'vue-router'
import BlankPage from '../components/common/BlankPage.vue'
import Home from '../views/Home.vue'
import AcneBurden from '../views/AcneBurden.vue'
import PatientProfiles from '../views/PatientProfiles.vue'
import SkinOfColor from '../views/SkinOfColor.vue'
import Innovation from '../views/Innovation.vue'
import RarY from '../views/RarY.vue'
import CreamVehicle from '../views/CreamVehicle.vue'
import DualStudyIga from '../views/DualStudyIga.vue'
import DualStudyEfficacy from '../views/DualStudyEfficacy.vue'
import DualStudyResults from '../views/DualStudyResults.vue'
import DualStudyTolerability from '../views/DualStudyTolerability.vue'
import EfficacyFace from '../views/EfficacyPS1Face.vue'
import EfficacyTrunk from '../views/EfficacyPS1Trunk.vue'
import EfficacyPS2Face from '../views/EfficacyPS2Face.vue'
import EfficacyPS2Trunk from '../views/EfficacyPS2Trunk.vue'
import LeapResultsPatients from '../views/LeapResultsPatients.vue'
import TolerabilityFace from '../views/TolerabilityFace.vue'
import TolerabilityTrunk from '../views/TolerabilityTrunk.vue'
import TolerabilityHowToUse from '../views/TolerabilityHowToUse.vue'
import TolerabilityWeek52 from '../views/TolerabilityWeek52.vue'
import TolerabilityWeek52Trunk from '../views/TolerabilityWeek52Trunk.vue'
import SeeResults from '../views/SeeResults.vue'
import StartEfficacy from '../views/StartEfficacy.vue'
import StartTolerability from '../views/StartTolerability.vue'
import Summary from '../views/Summary.vue'
import PatientQol from '../views/PatientQol.vue'
import StartResults from '../views/StartResults.vue'
import StartResultsPatients from '../views/StartResultsPatients.vue'
import LeapEfficacy from '../views/LeapEfficacy.vue'
import LeapResults from '../views/LeapResults.vue'
import LeapTolerability from '../views/LeapTolerability.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    meta: {
      datapointId: 699
    }
  },
  {
    path: '/acne-burden',
    name: 'acne-burden',
    component: AcneBurden,
    meta: {
      datapointId: 700
    }
  },
  {
    path: '/patient-profiles',
    component: BlankPage,
    children: [
      {
        path: '',
        name: 'patient-profiles',
        component: PatientProfiles,
        meta: {
          datapointId: 594
        }
      },
      {
        path: 'teen',
        name: 'patient-profiles:teen',
        component: PatientProfiles,
        meta: {
          datapointId: 701
        }
      },
      {
        path: 'skin-of-color',
        name: 'patient-profiles:skin-of-color',
        component: SkinOfColor,
        meta: {
          datapointId: 702
        }
      }
    ]
  },
  {
    path: '/innovation',
    component: BlankPage,
    children: [
      {
        path: '',
        name: 'innovation',
        component: Innovation,
        meta: {
          datapointId: 703
        }
      },
      {
        path: 'galderma-legacy',
        name: 'innovation:galderma-legacy',
        component: Innovation,
        meta: {
          datapointId: 703
        }
      },
      {
        path: 'rar-y',
        name: 'innovation:rar-y',
        component: RarY,
        meta: {
          datapointId: 704
        }
      },
      {
        path: 'cream-vehicle',
        name: 'innovation:cream-vehicle',
        component: CreamVehicle,
        meta: {
          datapointId: 706
        }
      }
    ]
  },
  {
    path: '/efficacy',
    name: 'efficacy',
    component: BlankPage,
    children: [
      {
        path: '',
        name: 'efficacy',
        component: EfficacyFace,
        meta: {
          datapointId: 707
        }
      },
      {
        path: 'ps1',
        name: 'efficacy:ps1',
        component: EfficacyFace,
        meta: {
          datapointId: 707
        }
      },
      {
        path: 'ps1trunk',
        name: 'efficacy:ps1trunk',
        component: EfficacyTrunk,
        meta: {
          datapointId: 708
        }
      },
      {
        path: 'ps2',
        name: 'efficacy:ps2',
        component: EfficacyPS2Face,
        meta: {
          datapointId: 709
        }
      },
      {
        path: 'ps2trunk',
        name: 'efficacy:ps2trunk',
        component: EfficacyPS2Trunk,
        meta: {
          datapointId: 710
        }
      },
      {
        path: 'see-results',
        name: 'efficacy:see-results',
        component: SeeResults,
        meta: {
          datapointId: 712
        }
      }
    ]
  },
  {
    path: '/tolerability',
    component: BlankPage,
    children: [
      {
        path: '',
        name: 'tolerability',
        component: TolerabilityFace,
        meta: {
          datapointId: 717
        }
      },
      {
        path: 'week12',
        name: 'tolerability:week12',
        component: TolerabilityFace,
        meta: {
          datapointId: 717
        }
      },
      {
        path: 'week12Trunk',
        name: 'tolerability:week12trunk',
        component: TolerabilityTrunk,
        meta: {
          datapointId: 718
        }
      },
      {
        path: 'week52',
        name: 'tolerability:week52',
        component: TolerabilityWeek52,
        meta: {
          datapointId: 719
        }
      },
      {
        path: 'week52trunk',
        name: 'tolerability:week52trunk',
        component: TolerabilityWeek52Trunk,
        meta: {
          datapointId: 720
        }
      },
      {
        path: 'how-to-use',
        name: 'tolerability:how-to-use',
        component: TolerabilityHowToUse,
        meta: {
          datapointId: 721
        }
      }
    ]
  },
  {
    path: '/studies',
    component: BlankPage,
    children: [
      {
        path: '',
        name: 'dual-study',
        component: DualStudyEfficacy,
        meta: {
          datapointId: 722
        }
      },
      {
        path: 'efficacy',
        name: 'dual-study:efficacy',
        component: DualStudyEfficacy,
        meta: {
          datapointId: 722
        }
      },
      {
        path: 'dual-study-iga',
        name: 'dual-study:dual-study-iga',
        component: DualStudyIga,
        meta: {
          datapointId: 724
        }
      },
      {
        path: 'dual-study-results',
        name: 'dual-study:dual-study-results',
        component: DualStudyResults,
        meta: {
          datapointId: 725
        }
      },
      {
        path: 'dual-study-tolerability',
        name: 'dual-study:dual-study-tolerability',
        component: DualStudyTolerability,
        meta: {
          datapointId: 729
        }
      },
      {
        path: 'start-efficacy',
        name: 'dual-study:start-efficacy',
        component: StartEfficacy,
        meta: {
          datapointId: 1082
        }
      },
      {
        path: 'start-results',
        name: 'dual-study:start-results',
        component: StartResults,
        meta: {
          datapointId: 1083
        }
      },
      {
        path: 'start-results',
        name: 'dual-study:start-results-patients',
        component: StartResultsPatients,
        meta: {
          datapointId: 1085
        }
      },
      {
        path: 'start-tolerability',
        name: 'dual-study:start-tolerability',
        component: StartTolerability,
        meta: {
          datapointId: 1090
        }
      },
      /*
        *** Leap routing ***
      */
      {
        path: 'leap-efficacy',
        name: 'dual-study:leap-efficacy',
        component: LeapEfficacy,
        meta: {
          datapointId: 1092
        }
      },
      {
        path: 'leap-results',
        name: 'dual-study:leap-results',
        component: LeapResults,
        meta: {
          datapointId: 1093
        }
      },
      {
        path: 'leap-results',
        name: 'dual-study:leap-results-patients',
        component: LeapResultsPatients,
        meta: {
          datapointId: 1096
        }
      },
      {
        path: 'leap-tolerability',
        name: 'dual-study:leap-tolerability',
        component: LeapTolerability,
        meta: {
          datapointId: 1100
        }
      }
    ]
  },
  {
    path: '/patient-qol',
    name: 'patient-qol',
    component: PatientQol,
    meta: {
      datapointId: 730
    }
  },
  {
    path: '/summary',
    name: 'summary',
    component: Summary,
    meta: {
      datapointId: 731
    }
  }
]

const router = new VueRouter({
  routes
})

export default router
