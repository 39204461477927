<template>
  <ul class="secondary-menu">
    <li>
      <a @click="toggleModal('ImportantSafetyInformation')">
        Important safety information
      </a>
    </li>
    <li>
      <a @click="toggleModal('PrescribingInformation')"
        >Prescribing information</a
      >
    </li>
    <li>
      <a @click="toggleModal('References')">References</a>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'SecondaryNavigation'
}
</script>

<style lang="scss">
.secondary-menu {
  height: 100%;
  margin-bottom: 0;
  margin-top: 0;
  padding: 0 44px;
  display: flex;
  flex-direction: row;
  list-style: none;
  align-items: center;

  li {
    font-family: "Sofia Pro Regular Condensed";
    font-size: 9px;
    font-weight: bold;
    text-transform: uppercase;
    margin-right: 17px;
    letter-spacing: -0.7px;

    a {
      color: $white;
      text-decoration: none;
      border-bottom: 0.5px solid;
    }
  }
}
</style>
