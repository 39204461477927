<template>
  <div :class="className" v-if="isVisible">
    <div class="modal__backdrop" v-if="isVisible"></div>
    <div class="modal__body">
      <div class="modal__close">
        <button @click="close()"></button>
      </div>
      <slot></slot>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Modal',
  methods: {
    close: function () {
      this.isVisible = false
      this.onClose()
    }
  },
  computed: {
    className: function () {
      return [
        'modal',
        'modal-' + this.$store.state.modal.component
          .replace(/([a-z])([A-Z])/g, '$1-$2')
          .replace(/\s+/g, '-')
          .toLowerCase()
          .split(':')
      ]
    }
  },
  props: {
    visible: {
      default: false,
      type: Boolean
    },
    onClose: {
      type: Function
    }
  },
  data: function () {
    return {
      isVisible: this.visible
    }
  },
  watch: {
    visible (nextProps) {
      this.isVisible = nextProps
    }
  }
}
</script>
<style lang="scss">
.modal {
  z-index: 9999999999;
  .title {
    font-size: 37px;
    color: $modal-title-color;
    text-transform: uppercase;
    font-family: "AG Bold Condensed";
  }
  .pretitle {
    font-size: 11px;
    color: $light-blue-2;
  }
  &,
  &__backdrop {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column;
  }
  &__backdrop {
    z-index: 1000;
    background-color: $font-color-gray;
    opacity: 0.85;
  }
  &__body {
    width: 929px;
    border: 6px solid $modal-border-color;
    background: #fff;
    box-shadow: 0px 10px 11.83px 1.17px rgba(22, 22, 22, 0.19);
    padding: 44px 72px 21px;
    position: relative;
    box-sizing: border-box;
    z-index: 1001;
  }
  &__close {
    z-index: 1002;
    position: absolute;
    right: 17px;
    top: 17px;
    button {
      position: relative;
      background: url(~@/assets/images/version-4/jpg-png/modal-close-btn.png);
      background-repeat: no-repeat;
      background-size: contain;
      background-position: right;
      width: 15px;
      height: 15px;
      margin: 0 0 10px 0;
      background-color: transparent;
      border: none;
      cursor: pointer;
      z-index: 9999999999;
      left: 0;
      right: 0;
    }
  }
}
</style>
