<template>
  <div class="modal-patient-three">
    <div class="main-title">
      <span>Dual Study</span>
      <h1>Patient 3</h1>
    </div>
    <img src="@/assets/images/version-4/jpg-png/results-patient-3.jpg" />
    <div class="img-footnote">
      <p>
        <span>Study:</span> Phase 4 double-blind, randomized, placebo and
        vehicle-controlled safety and efficacy 12-week study of trifarotene
        cream <br />
        and doxycycline vs vehicle cream and placebo.
      </p>
    </div>
    <div class="footnote">
      <p>IGA=Investigator's Global Assessment.</p>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss">
.modal-results-patient-three {
  .modal__body {
    width: 899px;
    left: -6px;
    top: -4px;
    height: 632px;
    padding: 35px 80px 21px 74px;
  }
}
.modal-patient-three {
  .main-title {
    text-transform: uppercase;
    margin-left: 1px;
    span {
      font-family: "Sofia Pro Bold";
      font-size: 11px;
      color: $light-blue-2;
      margin-left: 1px;
      letter-spacing: 1px;
    }
    h1 {
      font-family: "AG Bold Condensed";
      font-size: 37px;
      color: $dark-blue;
      margin-top: 0;
      margin-bottom: 11px;
      line-height: 1;
      display: block;
    }
  }
  img {
    max-width: 100%;
  }
  .img-footnote {
    margin-left: 2px;
    p {
      font-family: "Sofia Pro";
      font-size: 12px;
      color: $font-color-dark-gray;
      margin-bottom: 3px;
      line-height: 1.2;
      letter-spacing: -0.04px;
      span {
        font-family: "AG Bold Regular";
        color: $dark-blue;
        text-transform: uppercase;
      }
    }
  }
  .footnote {
    margin-left: 2px;
    p {
      font-family: "Sofia Pro";
      font-size: 10px;
      color: $font-color-gray;
      margin-top: 0;
      letter-spacing: -0.04px;
    }
  }
}
</style>
