<template>
  <div class="patient3">
    <header>
      <div class="pretitle"><strong>Trunk</strong></div>
      <h1 class="title">Patient 3</h1>
    </header>
    <main>
      <img src="@/assets/images/version-4/jpg-png/patient-6-lg.jpg" alt="" />
      <p>
        <strong>STUDY 118295:</strong> Open-label, single-arm study of
        trifarotene cream for 24 weeks.
      </p>
      <small>PGA=Physician's Global Assessment.</small>
    </main>
  </div>
</template>
<script>
export default {}
</script>
<style lang="scss">
.modal-patient3 {
  .modal {
    &__body {
      padding: 0;
      height: 678px;
      width: 846px;
      margin-left: -20px;
      margin-top: 3px;
    }
  }
}
.patient3 {
  header {
    padding: 30px 0 0 92px;
    margin-bottom: 21px;
  }

  h2 {
    font-size: 18.3px;
  }

  .title {
    line-height: 40px;
    margin: 0 0 0 -1px;
  }

  .pretitle {
    font-size: 11px;
    color: $light-blue-2;
    margin-top: 2px;
    text-transform: uppercase;
  }
  img {
    max-width: 100%;
  }
  main {
    padding: 0 108px 0 93px;
    img {
      margin-bottom: 5px;
    }
    p {
      margin: 0;
      font-size: 12.2px;
      letter-spacing: -0.03px;
      color: $font-color-dark-gray;
      strong {
        color: $modal-title-color;
        font-family: "AG Bold Regular";
      }
    }
    small {
      font-size: 10px;
      color: $font-color-gray;
    }
  }
}
</style>
