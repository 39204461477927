<template>
  <div class="leap-result-patient1">
    <header>
      <div class="pretitle"><strong>Leap Study</strong></div>
      <h1 class="title">Patient 1</h1>
    </header>
    <main>
      <div class="patient-state">
        <div class="censorship">
          <div>
            <p class="product">Aklief Cream</p>
            <p class="right">Success</p>
          </div>
        </div>
        <div class="patient-state-container">
          <div class="patient-info">
            <h6>Baseline</h6>
            <p>ODS: 5 | PAHPI: 17</p>
            <p>Lesion Count: 86 (IGA 3)</p>
            <img class="patient-separator" src="@/assets/images/version-4/jpg-png/leap-results/patient-separator.png"
              alt="" />
          </div>
          <div class="patinen-ph-container">
            <img class="patinent-ph"
              src="@/assets/images/version-4/jpg-png/leap-results/Female (Patient 1)/8606-010_Left-Face_BASELINE_CP_recalee_anonymisee.jpg"
              alt="" />
          </div>
        </div>
        <div class="patient-state-container">
          <div class="patient-info">
            <h6>Week 12</h6>
            <p>ODS: 3 | PAHPI: 17</p>
            <p>Lesion Count: 5 (IGA 1)</p>
            <img class="patient-separator" src="@/assets/images/version-4/jpg-png/leap-results/patient-separator.png"
              alt="" />
          </div>
          <div class="patinen-ph-container">
            <img class="patinent-ph"
              src="@/assets/images/version-4/jpg-png/leap-results/Female (Patient 1)/8606-010_Left-Face_W12_CP_recalee_anonymisee.jpg"
              alt="" />
          </div>
        </div>
        <div class="patient-state-container">
          <div class="patient-info">
            <h6>Week 24</h6>
            <p>ODS: 2 | PAHPI: 13</p>
            <p>Lesion Count: 3 (IGA 1)</p>
          </div>
          <div class="patinen-ph-container">
            <img class="patinent-ph"
              src="@/assets/images/version-4/jpg-png/leap-results/Female (Patient 1)/8606-010_Left-Face_W24_CP_recalee_anonymisee.jpg"
              alt="" />
          </div>
        </div>
      </div>
    </main>
  </div>
</template>
<script>
export default {}
</script>
<style lang="scss">
.modal-leap-result-patient1 {
  .modal {
    &__body {
      padding: 35px 27px 0 27px;
      height: 611px;
      width: 922.5px;
      margin-left: -20px;
      margin-top: 3px;
    }
  }

  .leap-result-patient1 {
    display: flex;
    flex-direction: column;

    header {
      width: 100%;
      margin-bottom: 47px;
    }

    .title {
      line-height: 40px;
      margin: 0 0 0 -1px;
    }

    .pretitle {
      font-size: 11px;
      color: $light-blue-2;
      margin-top: 4px;
      text-transform: uppercase;
    }

    main {
      display: flex;
      justify-content: center;

      .patient-state {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        position: relative;

        .censorship {
          position: absolute;
          background-color: $font-color-gray;
          width: 100%;
          top: 154px;
          height: 53.5px;

          div {
            display: flex;
            justify-content: space-between;
            margin: 0 57.5px 0 33.5px;
            align-items: center;
            height: 100%;

            p {
              text-transform: uppercase;
              color: $white;
              font-family: "AG Bold Condensed";
              font-size: 25px;
              line-height: 28.5px;
              margin: 0;
              &.product {
                font-style: italic;
              }
            }

            .right {
              margin: 0;
              font-family: "AG Bold Regular";
              font-size: 25px;
              line-height: 20px;
            }
          }
        }

        .patient-state-container {

          .patient-info {
            display: flex;
            flex-direction: column;
            justify-content: center;
            background-color: $light-gray;
            height: 80px;
            position: relative;

            .patient-separator {
              margin-right: -3px;
              right: 0;
              position: absolute;
              width: auto;
              height: 57px;
              z-index: 999;
            }

            h6 {
              text-transform: uppercase;
              color: $modal-title-color;
              font-family: "AG Bold Regular";
              font-size: 15.5px;
              text-align: center;
              line-height: 18px;
              margin: 0;
            }

            p {
              color: $font-color-gray;
              font-family: "AG BQ Regular";
              font-size: 15.5px;
              line-height: 18px;
              text-align: center;
              margin: 0;
            }
          }

          .patinen-ph-container {

            .patinent-ph {
              height: 246.5px;
              object-fit: cover;
              width: 239px;
            }
          }
        }
      }
    }
  }
}
</style>
