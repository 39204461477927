<template>
  <page>
    <div class="content">
      <h1 class="content-main-heading">
        A commitment to furthering treatment <br />
        in dermatology.
      </h1>
      <div class="content-wrapper">
        <div class="content-wrapper-sidebar">
          <img
            src="@/assets/images/version-4/svg/galderma-logo-1.svg"
            width="157"
            alt=""
          />
          <p>
            Galderma has a decades-long <br />
            commitment to meeting the <br />
            skin health needs of patients <br />
            like yours. For Galderma, <br />
            innovation is a cornerstone in <br />
            advancing dermatology, and <br />
            we will continue to develop new <br />
            solutions for the acne category <br />
            well into the future.
          </p>
        </div>
        <div class="content-wrapper-main">
          <div class="first-testimonial">
            <img
              class="quotes quote-one"
              src="@/assets/images/version-4/svg/quote.svg"
              width="73"
              alt=""
            />
            <img
              class="quotes quote-two"
              src="@/assets/images/version-4/svg/quotes-inverse.svg"
              width="20"
              alt=""
            />
            <p>
              Galderma’s approach and <br />
              influence on acne have been<br />
              based on commitment. Being <br />in clinical practice for the
              <br />past <span class="year">30</span> years, Galderma has<br />
              innovated and investigated <br />over that period of time.
            </p>
            <h5 class="author">—International Dermatology Expert</h5>
          </div>
          <!-- <div class="second-testimonial">
            <img
              class="quotes quote-one"
              src="@/assets/images/version-4/svg/quote.svg"
              height="20"
              alt=""
            />
            <img
              class="quotes quote-two"
              src="@/assets/images/version-4/svg/quotes-inverse.svg"
              height="20"
              alt=""
            />
            <p>
              Galderma has gone above and beyond because it brought <br />
              us acne treatments that changed the way we practice.
            </p>
            <h5 class="author">—Director of Dermatology, Clinical Research</h5>
          </div> -->
        </div>
      </div>
    </div>
  </page>
</template>

<script>
import Page from '@/components/common/Page'

export default {
  name: 'innovation',
  components: {
    page: Page
  }
}
</script>
<style lang="scss" scoped>
.page.innovation {
  background: url(~@/assets/images/version-4/jpg-png/purple-bg.png) no-repeat;
  background-size: 100%;
  .content {
    @mixin author($letter-spacing: -0.2px) {
      font-family: "AG BQ Regular";
      font-size: 15px;
      color: $font-color-rose;
      margin-top: 10px;
      margin-bottom: 0;
      letter-spacing: $letter-spacing;
    }

    &-main-heading {
      font-family: "AG Bold Condensed";
      font-size: 40px;
      color: $dark-blue;
      text-transform: uppercase;
      line-height: 1;
      margin-top: 5px;
      margin-left: 42px;
      margin-bottom: 17px;
      letter-spacing: 0.4px;
    }
    &-wrapper {
      width: 988.5px;
      height: 484.5px;
      background: $white;
      display: flex;
      &-sidebar {
        position: relative;
        width: 290px;
        background: $dark-violet;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        img {
          position: absolute;
          top: 122px;
        }
        p {
          font-family: "Sofia Pro";
          font-size: 15px;
          color: $white;
          line-height: 1.55;
          margin-top: 95.6px;
          margin-left: 7px;
        }
      }
      &-main {
        position: relative;
        width: 698px;
        .first-testimonial {
          position: absolute;
          top: 80px;
          left: 200px;
          .quotes {
            position: absolute;
          }
          .quote-one {
            top: 39px;
            left: -100px;
          }
          .quote-two {
            bottom: 36px;
            right: 45px;
          }
          p {
            font-family: "AG Light Regular";
            font-size: 31px;
            color: $font-color-gray;
            margin-bottom: -4px;
            line-height: 35px;
            letter-spacing: -0.6px;
          }
          h5 {
            @include author;
          }
        }
        .second-testimonial {
          position: absolute;
          bottom: 32px;
          left: 91px;
          .quotes {
            position: absolute;
          }
          .quote-one {
            bottom: 57px;
            left: -34px;
          }
          .quote-two {
            bottom: 27px;
            right: 2px;
          }
          p {
            font-family: "AG Light Regular";
            font-size: 22.62px;
            color: $font-color-gray;
            margin-bottom: 29px;
            line-height: 24px;
            letter-spacing: -0.5px;
            position: relative;
          }
          h5 {
            @include author(0);
            position: absolute;
            bottom: 3px;
          }
        }
      }
    }
  }
}
</style>
