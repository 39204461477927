var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ul',{staticClass:"nav"},[_c('li',{staticClass:"first"},[_c('router-link',{attrs:{"to":{ name: 'acne-burden' }}},[_c('span',[_vm._v("Acne Burden")])])],1),_c('li',[_c('router-link',{staticClass:"custom-link invisible-link",attrs:{"to":{ name: 'patient-profiles:teen' }}},[_c('span',[_vm._v("Patient Profiles")])]),_c('router-link',{staticClass:"custom-link router",attrs:{"to":{ name: 'patient-profiles' }}},[_c('span',[_vm._v("Patient Profiles")])]),_c('ul',{staticClass:"inner-nav"},[_c('li',[_c('router-link',{class:{
            'router-link-active':
              ['patient-profiles'].indexOf(this.$route.name) > -1
          },attrs:{"to":{ name: 'patient-profiles:teen' }}},[_c('span',[_vm._v("Teen")])])],1),_c('li',[_c('router-link',{staticClass:"custom-link",attrs:{"to":{ name: 'patient-profiles:skin-of-color' }}},[_c('span',[_vm._v("Skin of Color")])])],1)])],1),_c('li',[_c('router-link',{staticClass:"custom-link invisible-link",attrs:{"to":{ name: 'innovation:galderma-legacy' }}},[_c('span',[_vm._v("Innovation")])]),_c('router-link',{staticClass:"custom-link",attrs:{"to":{ name: 'innovation' }}},[_c('span',[_vm._v("Innovation")])]),_c('ul',{staticClass:"inner-nav inner-nav-innovation"},[_c('li',[_c('router-link',{class:{
            'router-link-active':
              ['innovation'].indexOf(this.$route.name) > -1
          },attrs:{"to":{ name: 'innovation:galderma-legacy' }}},[_c('span',[_vm._v("GALDERMA LEGACY")])])],1),_c('li',[_c('router-link',{staticClass:"custom-link",attrs:{"to":{ name: 'innovation:rar-y' }}},[_c('span',[_vm._v("RAR-"),_c('small',{staticClass:"light"},[_vm._v("γ")])])])],1),_c('li',[_c('router-link',{staticClass:"custom-link",attrs:{"to":{ name: 'innovation:cream-vehicle' }}},[_c('span',[_vm._v("AKLIEF "),_c('small',{staticClass:"sentence-case"},[_vm._v("Cream")]),_vm._v(" VEHICLE")])])],1)])],1),_c('li',[_c('router-link',{staticClass:"custom-link invisible-link",attrs:{"to":{ name: 'efficacy:ps1' }}},[_c('span',[_vm._v("Efficacy & Results")])]),_c('router-link',{staticClass:"custom-link",attrs:{"to":{ name: 'efficacy' }}},[_c('span',[_vm._v("Efficacy & Results")])]),_c('ul',{staticClass:"inner-nav"},[_c('li',[_c('router-link',{class:{
            'router-link-active':
              ['efficacy', 'efficacy:ps1trunk'].indexOf(this.$route.name) > -1
          },attrs:{"to":{ name: 'efficacy:ps1' }}},[_c('span',[_vm._v("Pivotal Study 1")])])],1),_c('li',[_c('router-link',{staticClass:"custom-link",class:{
            'router-link-active':
              ['efficacy:ps2trunk'].indexOf(this.$route.name) > -1
          },attrs:{"to":{ name: 'efficacy:ps2' }}},[_c('span',[_vm._v("Pivotal Study 2")])])],1),_c('li',[_c('router-link',{staticClass:"custom-link",class:{
            'router-link-exact-active': this.$route.name == ''
          },attrs:{"to":{ name: 'efficacy:see-results' }}},[_c('span',[_vm._v("See results")])])],1)])],1),_c('li',[_c('router-link',{staticClass:"custom-link invisible-link",attrs:{"to":{ name: 'tolerability:week12' }}},[_c('span',[_vm._v("Tolerability")])]),_c('router-link',{staticClass:"custom-link",attrs:{"to":{ name: 'tolerability' }}},[_c('span',[_vm._v("Tolerability")])]),_c('ul',{staticClass:"inner-nav"},[_c('li',[_c('router-link',{class:{
            'router-link-active':
              ['tolerability', 'tolerability:week12trunk'].indexOf(
                this.$route.name
              ) > -1
          },attrs:{"to":{ name: 'tolerability:week12' }}},[_c('span',[_vm._v("Week 12")])])],1),_c('li',[_c('router-link',{staticClass:"custom-link",class:{
            'router-link-active':
              ['tolerability:week52trunk'].indexOf(this.$route.name) > -1
          },attrs:{"to":{ name: 'tolerability:week52' }}},[_c('span',[_vm._v("Week 52")])])],1),_c('li',[_c('router-link',{staticClass:"custom-link",attrs:{"to":{ name: 'tolerability:how-to-use' }}},[_c('span',[_vm._v("How to use")])])],1)])],1),_c('li',[_c('router-link',{staticClass:"custom-link invisible-link",attrs:{"to":{ name: 'dual-study:efficacy' }}},[_c('span',[_vm._v("Studies")])]),_c('router-link',{staticClass:"custom-link",attrs:{"to":{ name: 'dual-study' }}},[_c('span',[_vm._v("Studies")])]),_c('ul',{staticClass:"inner-nav inner-nav-dual-study"},[_c('li',[_c('router-link',{class:{
            'router-link-active':
              ['dual-study:efficacy', 'dual-study:dual-study-iga', 'dual-study:dual-study-results', 'dual-study:dual-study-tolerability'].indexOf(this.$route.name) > -1
          },attrs:{"to":{ name: 'dual-study:efficacy' }}},[_c('span',[_vm._v("Dual")])])],1),_c('li',[_c('router-link',{staticClass:"custom-link",class:{
            'router-link-active':
              ['dual-study:start-efficacy', 'dual-study:start-results', 'dual-study:start-results-patients', 'dual-study:start-tolerability'].indexOf(this.$route.name) > -1
            },attrs:{"to":{ name: 'dual-study:start-efficacy' }}},[_c('span',[_vm._v("Start")])])],1),_c('li',[_c('router-link',{staticClass:"custom-link",class:{
            'router-link-active':
              ['dual-study:leap-efficacy', 'dual-study:leap-results', 'dual-study:leap-results-patients','dual-study:leap-tolerability'].indexOf(this.$route.name) > -1
            },attrs:{"to":{ name: 'dual-study:leap-efficacy' }}},[_c('span',[_vm._v("Leap")])])],1)])],1),_c('li',[_c('router-link',{staticClass:"custom-link",attrs:{"to":{ name: 'patient-qol' }}},[_c('span',[_vm._v("Patient QOL")])])],1),_c('li',{staticClass:"last"},[_c('router-link',{attrs:{"to":{ name: 'summary' }}},[_c('span',[_vm._v("Summary")])])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }