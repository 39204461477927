<template>
  <page>
    <div class="content">
      <h1 class="content-main-heading">
        Relationship between trifarotene <br />
        and Rar-<span>&gamma;</span>.
      </h1>
      <div class="content-wrapper">
        <div class="content-wrapper-sidebar">
          <p class="bold-note first-note">
            In a study of human skin <br />
            biopsies, the percentage of <br />
            RAR found in the skin was<sup>9</sup>:
          </p>
          <img
            src="@/assets/images/version-4/jpg-png/rar-y-figure.png"
            width="193.5"
            alt=""
          />
          <p class="image-foonote">
            <small>
              <span>RAR-α (12-14%)</span> and
              <span
                >RAR-β <br />
                (not detected)</span
              >
              were significantly less <br />
              prevalent.<sup>9</sup>
            </small>
          </p>
          <p class="bold-note second-note">
            Targeting RAR-&gamma; with retinoid <br />
            molecules has several <br />
            important functions in acne, <br />
            via gene modification<sup>10</sup>
          </p>
          <ul class="gene-functions">
            <li>Inflammation modulated with AP-1<sup>11,12</sup></li>
            <li>
              Epidermal turnover and <br />
              normalizing cell growth <br />
              - Keratinization<sup>11</sup> <br />
              - Desquamation<sup>10</sup>
            </li>
          </ul>
        </div>
        <div class="content-wrapper-main">
          <h2 class="content-wrapper-main-title">
            Trifarotene is the first-of-its-kind, topical retinoid molecule
            <br />
            to specifically target RAR-&gamma;<sup>4,9</sup>
          </h2>
          <img
            src="@/assets/images/version-4/jpg-png/moa-image.jpg"
            width="552.5"
            alt=""
          />
          <div class="btn-video" @click="toggleModal('InnovationMoa')">
            <primary-button>Watch video</primary-button>
          </div>
          <div class="footnote">
            <p>
              AP-1=activator protein 1; RAR=retinoic acid receptor;
              RAR-α=retinoic acid receptor alpha; RAR-β=retinoic acid receptor
              beta; RAR-&gamma;=retinoic acid <br />
              receptor gamma.
            </p>
          </div>
        </div>
      </div>
    </div>
  </page>
</template>

<script>
import Page from '@/components/common/Page'
import PrimaryButton from '@/components/common/PrimaryButton'

export default {
  name: 'rar-y',
  components: {
    page: Page,
    PrimaryButton
  }
}
</script>
<style lang="scss" scoped>
.page.rar-y {
  background: url(~@/assets/images/version-4/jpg-png/purple-bg.png) no-repeat;
  background-size: 100%;
  .content {
    &-main-heading {
      font-family: "AG Bold Condensed";
      font-size: 40px;
      color: $dark-blue;
      text-transform: uppercase;
      line-height: 1;
      margin-top: 5px;
      margin-left: 42px;
      margin-bottom: 17px;
      letter-spacing: 0.4px;
      span {
        text-transform: lowercase;
      }
    }
    &-wrapper {
      width: 988.5px;
      height: 484.5px;
      background: $white;
      display: flex;
      sup {
        font-size: 55%;
      }
      &-sidebar {
        position: relative;
        width: 260px;
        height: 100%;
        padding-left: 36px;
        background: $dark-blue;
        img {
          position: absolute;
          top: 100px;
          left: 58px;
        }
        .first-note {
          margin-bottom: 130px;
          margin-top: 36px;
        }
        .second-note {
          margin-top: 25px;
        }
        .bold-note {
          font-family: "AG Medium";
          font-size: 16px;
          line-height: 1.1;
          sup {
            font-family: "AG Light";
          }
        }
        .gene-functions {
          font-family: "Sofia Pro";
          font-size: 12px;
          color: $white;
          line-height: 1.3;
          padding-left: 0;
          margin-left: 20px;
          margin-top: -5px;
        }
        p {
          font-family: "Sofia Pro";
          font-size: 15px;
          color: $white;
          line-height: 1.55;
          margin-left: 7px;
        }
        .image-foonote {
          font-family: "Sofia Pro";
          font-size: 14px;
          line-height: 1.2;
          span {
            color: $light-green;
          }
        }
      }
      &-main {
        position: relative;
        &-title {
          margin-top: 52px;
          margin-left: 68px;
          font-family: "AG Medium";
          font-size: 20px;
          color: $dark-blue;
          letter-spacing: -0.15px;
          line-height: 1.2;
        }
        img {
          position: absolute;
          top: 122px;
          left: 69px;
        }
        .btn-video {
          margin-top: 308px;
          margin-left: 286px;
          font-family: "AG Medium";
          a {
            padding: 9px 12px;
          }
        }
        .footnote {
          margin-top: -4px;
          margin-left: 20px;
          p {
            font-family: "Sofia Pro";
            font-size: 10px;
            color: $font-color-gray;
            letter-spacing: -0.08px;
          }
        }
      }
    }
  }
}
</style>
