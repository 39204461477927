<template>
  <page>
    <div class="content">
      <!-- Title -->
      <div class="title">
        <h1>
          THERE MAY BE AN ADDITIONAL ACNE BURDEN <br />BEYOND WHAT YOU FIRST
          SEE.
        </h1>
      </div>

      <!-- Description -->
      <div class="description">
        <div class="left">
          <img src="~@/assets/images/version-4/jpg-png/stat-2.png" alt="" />
        </div>

        <div class="right">
          <div class="text-top">
            Patients with acne may not express the full extent
            <br />of their acne burden. For patients with both facial <br />and
            truncal acne:
          </div>

          <div class="text-center">
            <div class="left">
              <img src="~@/assets/images/version-4/svg/60.svg" alt="" />
              <p>
                have been <span>bullied</span> and/or <br />verbally or
                physically abused <br />because of their acne<sup>6</sup>
              </p>
            </div>

            <div class="right">
              <img src="~@/assets/images/version-4/svg/74.svg" alt="" />
              <p>
                said their acne <span>holds <br />them back</span> from
                <br />doing things they enjoy<sup>6</sup>
              </p>
            </div>
          </div>

          <div class="text-bottom">
            <h3>
              Dealing with acne is a burden, but treating acne <br />shouldn't
              be.
            </h3>
            <p>
              AKLIEF<sup>&reg;</sup> (trifarotene) Cream, 0.005% is the first
              topical treatment <br />specifically studied to treat both facial
              and truncal acne.<sup>4</sup>
            </p>
            <div class="image">
              <img
                src="~@/assets/images/version-4/jpg-png/aklief-1.png"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </page>
</template>

<script>
import Page from '@/components/common/Page'

export default {
  components: {
    page: Page
  }
}
</script>

<style lang="scss">
.page.acne-burden {
  background-image: url("~@/assets/images/version-4/jpg-png/bg-screen.jpg");
  background-repeat: no-repeat;
  background-position: center 0;
  background-size: 100%;
  color: #fff;
  img {
    max-width: 100%;
  }
  sup {
    font-size: 60%;
  }
  .title {
    h1 {
      color: $dark-blue;
      font-family: "AG Bold Condensed";
      font-size: 41px;
      line-height: 0.975;
      margin: 6px 0 18px 40px;
    }
  }
  .description {
    display: flex;
    color: $dark-blue;

    > .left {
      flex: 0 0 372px;
    }

    > .right {
      background: #fff;
      padding: 40px 50px 0px;
      height: 445px;
      width: 518px;

      .text {
        &-top {
          font-family: "AG Medium";
          font-weight: bold;
          font-size: 18px;
          letter-spacing: -0.5px;
          line-height: 1.15;
          margin: 6px 0 0 30px;
        }
        &-center {
          font-family: "Sofia Pro";
          display: flex;
          width: 497px;
          padding: 18px 0 0 0;

          img {
            width: 97px;
            display: block;
            margin: auto;
          }

          > div {
            flex: auto;
          }

          > .left {
            flex: 0 0 256px;
            p {
              position: relative;
              top: -6px;
              left: 31px;
              font-size: 17px;
              line-height: 1;
              text-align: left;
              span {
                font-family: "Sofia Pro Bold";
                font-size: 19px;
              }
            }
          }

          > .right {
            p {
              position: relative;
              top: -10px;
              left: 51px;
              font-size: 17px;
              line-height: 0.95;
              text-align: left;
              span {
                font-family: "Sofia Pro Bold";
                font-size: 19px;
              }
            }
          }
        }
        &-bottom {
          background: $light-gray;
          width: 511px;
          height: 123px;
          margin: 10px 0 0 4px;
          padding: 6px 25px;
          box-sizing: border-box;
          position: relative;

          img {
            width: 88px;
            height: auto;
            position: absolute;
            right: 20px;
            top: -16px;
          }

          h3 {
            font-family: "AG Medium";
            font-size: 15px;
            margin: 22px 0 0 1px;
          }

          p {
            font-size: 12px;
            color: $font-color-gray;
            margin: 2px 0 0 0;
            line-height: 1.2;
          }
        }
      }
    }
  }
  .footer > ul > li > ul.inner-nav.inner-nav-dual-study {
    display: none;
  }
}
</style>
