<template>
  <page>
    <div class="content">
      <div class="sub-header">
        <h1 class="title">
          SAFE AND WELL-TOLERATED FOR PATIENTS WITH<br />SEVERE ACNE.<sup
            >19</sup
          >
        </h1>
        <div>
          <h4 class="subtitle">
            Phase 4 Study of AKLIEF<sup>&reg;</sup> (trifarotene) Cream, 0.005%
            with enteric<br />
            coated oral doxycycline in a population with severe acne.<sup
              >19</sup
            >
          </h4>
          <ul class="nav inner-nav-dual">
            <li>
              <router-link
                :to="{ name: 'dual-study:efficacy' }"
                :class="{
                  'router-link-active':
                    ['dual-study'].indexOf(this.$route.name) > -1
                }"
                ><span>Efficacy</span></router-link
              >
            </li>
            <li>
            <router-link
              class="custom-link"
              :to="{ name: 'dual-study:dual-study-iga' }"
              ><span>IGA success</span></router-link
            >
          </li>
            <li>
              <router-link
                class="custom-link"
                :to="{ name: 'dual-study:dual-study-results' }"
                ><span>Results</span></router-link
              >
            </li>
            <li>
              <router-link
                class="custom-link"
                :to="{ name: 'dual-study:dual-study-tolerability' }"
                ><span>Tolerability</span></router-link
              >
            </li>
          </ul>
        </div>
      </div>
      <div class="body">
        <div class="wrapper">
          <div class="col chart">
            <p class="chart-title">
              Local Tolerability (Safety Population)<sup>19</sup>
            </p>
            <img src="@/assets/images/version-4/svg/chart-11.svg" alt="" />
            <p class="chart-note">
              FSDT=final score during treatment; WPBS=worst post-baseline score.
            </p>
          </div>
          <div class="col info">
            <div class="info-box">
              <p class="info-box-secondary">Local irritation was</p>
              <p class="info-box-primary">mild &#38; <br/>transient</p>
              <p class="info-box-secondary first">at week 1 and REDUCED</p>
              <p class="info-box-secondary last">thereafter<sup>19</sup></p>
            </div>
            <div class="info-list">
              <ul>
                <li>
                  The most common adverse reactions<br />(incidence ≥ 1%) in
                  patients treated with<br /><span></span>AKLIEF Cream were
                  application site<br /><span></span>irritation, application
                  site pruritus<br /><span></span>(itching), and sunburn<sup
                    >19</sup
                  >
                </li>
                <li>
                  AKLIEF Cream and enteric coated oral<br />
                  doxycycline 120 mg was well tolerated,<br />
                  with tolerability comparable to placebo<sup>18</sup><br />
                  – The incidence of treatment-emergent <br/>adverse events was
                  low<sup>19</sup>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </page>
</template>

<script>
import Page from '@/components/common/Page'

export default {
  name: 'dual-study-tolerability',
  components: {
    page: Page
  }
}
</script>

<style lang="scss">
.page.dual-study-tolerability {
  background-repeat: no-repeat;
  background-position: center 0;
  background-size: 100%;

  .content {
    padding: 0 0;
  }

  .sub-header {
    padding-left: 0px;
    padding-bottom: 32px;
    padding-left: 41px;
    margin-top: -7px;
    .title {
      letter-spacing: 0.4px;
      font-size: 41px;
      line-height: 33px;
      margin-bottom: 14px;
      sup {
        font-family: "AG Light Condensed";
        font-size: 18px;
      }

      .inner-nav-dual{
        top: 50px;
      }
    }
    .subtitle {
      font-size: 16px;
      font-family: "AG Medium";
      line-height: 14px;
      margin: 0;
      sup {
        font-family: "AG BQ Regular";
      }
    }
  }
  .body {
    border-top: 6.5px solid $light-green;
    background-color: transparent;
    margin-top: 0px;
    .wrapper {
      .chart {
        padding: 13px 118px 14px 44px;
        position: relative;
        background-color: $white;
        .chart-title {
          sup {
            font-family: "AG BQ Regular";
            font-size: 10px;
          }
        }
        img {
          padding: 18px 0 0 1px;
          width: 550px;
        }
        &-note {
          position: absolute;
          right: 94px;
          bottom: 10px;
          font-family: "Sofia Pro";
          color: $font-color-gray;
          font-size: 10px;
          letter-spacing: -0.02px;
        }
      }
      .info {
        background-color: $dark-violet;
        width: 100%;
        &-box {
          padding-top: 80px;
          p {
            text-transform: uppercase;
            text-align: center;
          }
          &-primary {
            font-family: "AG Bold Regular";
            font-size: 30px;
            color: $light-green;
            line-height: 35px;
            margin-top: 10px;
            margin-left: -4px;
            margin-bottom: 8px;
            line-height: 35px;
          }
          &-secondary {
            margin: 0px;
            font-family: "AG Medium";
            font-size: 15px;
            letter-spacing: -0.1px;
            color: $white;
            line-height: 18px;
            &:nth-child(1) {
              margin-left: -3px;
              line-height: 0px;
            }
            &.first {
              margin-top: 12px;
              margin-left: -4px;
              letter-spacing: 0.2px;
            }
            &.last {
              margin-top: -1px;
              margin-left: -5px;
              letter-spacing: 0.2px;
              sup {
                font-family: "AG BQ Regular";
                font-size: 9px;
                line-height: 0px;
                letter-spacing: -1px;
              }
            }
          }
        }
        &-list {
          ul {
            padding-left: 37px;
            margin-top: 57px;
            list-style: none;
            font-family: "Sofia Pro";
            font-size: 11px;
            line-height: 13px;
            letter-spacing: -0.1px;
            color: $white;
            li {
              position: relative;
              margin-bottom: 5px;
              span {
                margin: 0 1px;
                &.last {
                  margin: 0 5px;
                }
              }
              &:before {
                position: absolute;
                content: "■";
                margin-right: 5px;
                font-size: 6px;
                font-weight: bold;
                top: 0px;
                left: -10px;
              }
              sup {
                font-family: "Sofia Pro";
                font-size: 7px;
                line-height: 0px;
                margin-left: 1px;
              }
            }
          }
        }
      }
    }
  }
}
</style>
