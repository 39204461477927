<template>
  <div class="sally-cream">
    <div class="main-content">
      <div class="col-content">
        <h1><span>About</span> AKLIEF<sup>&reg;</sup> <span>Cream</span></h1>
        <ul>
          <li>
            <span class="bold-font">55%</span> of the >2,000 patients included in
            the AKLIEF Cream<br />
            clinical trials <span class="bold-font">were female</span
            ><sup>2</sup>
          </li>
          <li>
            AKLIEF Cream is the first topical treatment with
            <span class="bold-font"
              >proven<br />
              results in treating facial and truncal acne</span
            ><sup>3</sup>
          </li>
          <li class="pivotal-text">
            In the PIVOTAL 1 Study, powerful reduction in
            <span class="bold-font">facial <br />inflammatory lesions</span> was
            seen at <span class="bold-font">Week 12</span> vs vehicle<sup>2</sup
            ><br />
            <span class="small-text"
              >- Subjects treated with AKLIEF Cream achieved
              <span class="bold-font">54.4% fewer <br />
              <span class="tab-text">inflammatory</span> lesions </span> vs baseline at Week 12 vs 44.8%
              <br /><span class="tab-text">reduction</span> with vehicle<sup>2</sup></span>
          </li>
          <li>
            In a long-term safety study for both the face and trunk,<br />
            <span class="bold-font"
              >AKLIEF Cream was proven safe and well-tolerated</span
            ><sup>4</sup>
          </li>
        </ul>
        <p class="footnote">The most common adverse reactions (incidence ≥ 1%) in patients treated with AKLIEF Cream were application site irritation, application site pruritus (itching), and sunburn.</p>
      </div>
      <div class="col-image">
        <img
          src="@/assets/images/version3/aklief-packshot-2.png"
          height="492"
          alt=""
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss">
.modal-sally-aklief-cream {
  .modal__body {
    padding: 55px 72px 85px 72px;
    margin-top: 34px;
    height: 550px;
    overflow: hidden;
  }
  .modal__close {
    button {
      bottom: -34px;
    }
  }
  video {
    display: block;
    margin: 0 auto;
  }
  .main-content {
    display: flex;
    h1 {
      font-family: "Sofia Pro Medium";
      font-size: 31px;
      color: $font-color-blue;
      margin-top: -10px;
      sup {
        font-family: "Myriad Pro";
        vertical-align: text-bottom;
        font-size: 90%;
      }
      span {
        font-family: "Sofia Pro Medium";
        font-size: 31px;
      }
    }
    .col-content {
      margin-top: 10px;
      margin-right: 108.5px;
      margin-left: -7px;
      ul {
        font-family: "Sofia Pro";
        font-size: 16.5px;
        color: $font-color-gray;
        padding-left: 20px;
        margin-top: -6px;
        margin-left: -3px;
        letter-spacing: -0.3px;
        .tab-text {
          margin-left: 10px;
        }
        li {
          line-height: 1;
          margin-bottom: 15px;
          list-style-type: none;
          position: relative;
          &:before {
            content: "•";
            color: $font-color-blue;
            display: inline-block;
            position: absolute;
            left: -17px;
            top: 1px;
          }
        }
        .pivotal-text {
          margin-top: 20px;
          letter-spacing: -0.3px;
        }
        .bold-font {
          font-family: "Sofia Pro Bold";
          line-height: 1.15;
        }
        .small-text {
          display: inline-block;
          font-size: 13px;
          margin-top: 7px;
          margin-left: 2px;
          letter-spacing: -0.1px;
          line-height: 1.4;
        }
        sup {
          font-size: 50%;
        }
      }
      .footnote {
        font-family: "Sofia Pro";
        font-size: 13px;
        color: $font-color-gray;
        line-height: 1.34;
      }
    }
    .col-image {
      margin-left: -26px;
      margin-top: -3px;
    }
  }
}
</style>
