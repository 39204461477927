<template>
  <page>
    <div class="content">
      <div>
        <div class="title">
        <h1 class="margin-null">SIGNIFICANTLY REDUCED RISK AND<br/>SEVERITY OF ACNE SCARRING</h1>
        <div>
          <p>Significant >55% IGA success rate in acne clearance<br/> and significant 50% reduction in acne scars with<br/> AKLIEF<sup>®</sup>  (trifarotene) Cream, 0.005% from baseline to Week 24<sup>2*</sup></p>
          <ul class="nav inner-nav-dual">
                <li>
                <router-link
                    :to="{ name: 'dual-study:start-efficacy' }"
                    ><span>Efficacy</span></router-link
                >
                </li>
                <li>
                <router-link
                    class="custom-link"
                    :to="{ name: 'dual-study:start-results' }"
                    ><span>Results</span></router-link
                >
                </li>
                <li>
                <router-link
                    class="custom-link"
                    :to="{ name: 'dual-study:start-tolerability' }"
                    ><span>Tolerability</span></router-link
                >
                </li>
          </ul>
        </div>
        </div>
        <div class="stats-content">
          <div class="head-content">
            <span class="head-title">Absolute change in total atrophic acne scar count from baseline (ITT)<sup>†</sup></span>
            <router-link
              class="ctn-results"
              :to="{ name: 'dual-study:start-results-patients' }"
              ><span class="text">VIEW RESULTS IMAGES</span>
              <span class="arrow">&rsaquo;</span>
            </router-link>
          </div>
          <div class="middle-content">
            <img class="chart-1" src="@/assets/images/version-4/jpg-png/start-results/start-results-graph.png">
            <img class="chart-2" src="@/assets/images/version-4/jpg-png/start-results/50-percent-image.png">
            <div class="label">
              <span>The <strong>difference in<br/> absolute change<br/> in total atrophic<br/> acne scar count<br/></strong> between the AKLIEF<br/> Cream group and<br/> the vehicle group<br/> was statistically <strong>significant at<br/> Week 2<sup>2</sup></strong></span>
            </div>
          </div>
          <div class="footer-content">
            <span class="text"> <strong>ITT</strong> = intent-to-treat.<br/> *By imputing missing data using multiple imputation under the assumption of missing at random (ITT population).<br/> †For absolute change in total atrophic acne scar count from baseline (MI), the bilateral difference between (intra-subject) treatment groups [95% CI] was 0.0 [–0.3,<br> 0.3] at Week 1 (P=0.9476), –0.6 [–1.1, –0.1] at Week 2 (P=0.0248), –0.8 [–1.4, –0.2] at Week 4 (P=0.0072), –1.4 [–2.1, –0.7] at Week 8 (P≤0.0001), –2.1 [–2.9, –1.3] at <br>Week 12 (P≤0.0001), –2.7 [–3.8, –1.6] at Week 16 (P≤0.0001), –2.5 [–3.5, –1.5] at Week 20 (P≤0.0001), and –3.2 [–4.4, –2.0] at Week 24 (P≤0.0001).<sup>2</sup></span>
            <div class="ctn-button">
              <a  href="#" class="button" @click="toggleModal('StartStudyDesign')">
                <p>Study Design</p>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </page>
</template>

<script>
import Page from '@/components/common/Page'

export default {
  name: 'start-results',
  components: {
    page: Page
  },

  data () {
    return {
    }
  },

  computed: {
  },

  methods: {
  }

}
</script>
<style lang="scss" scoped>
.page.start-results {
  .title {
    position: relative;
    color: $dark-blue;
    margin: 0 0 11px 41px;
    div{
      ul{
        top: 92px;
      }
    }
    h1 {
      font-family: "AG Bold Condensed";
      font-size: 41px;
      line-height: 0.975;
      margin: 0 0 6px 0;
      text-transform: uppercase;
    }
    p {
      margin: 0;
      line-height: 1.1;
      font-family: "AG Medium";
      display: block;
    }
    sup {
      font-size: 9px;
    }
  }

  .margin-null{
    margin: 0 !important
  }

  .stats-content {
    border-top: 7px solid $light-green;
    color: $font-color-gray;
    background: $white;
    padding: 10px 25px 0px 42px;
    height: 438.5px;
    width: 990px;
    position: relative;
    box-sizing: border-box;

    .head-content{
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-family: "AG Medium";
      margin-bottom: 26px;

      .head-title{
        color: $modal-title-color;
        font-size: 19px;

        sup{
          font-size: 60%;
          line-height: 0;
        }
      }

      .ctn-results{
        color: $medium-violet;
        display: flex;
        align-items: center;
        text-decoration: none;
        .text{
          font-size: 10px;
          margin-right: 8px;
        }

        .arrow{
          font-size: 25px;
          font-family: monospace;
        }
      }
    }

    .middle-content{
      display: flex;
      margin-bottom: 13px;
      .chart-1{
        width: 700px;
      }
      .chart-2{
        position: absolute;
        top: 4px;
        right: 215px;
        width: 130px;
      }
      .label{
        color: $modal-title-color;
        font-family: "AG BQ Regular";
        font-size: 17.5px;
        margin-left: 45px;
        margin-right: 15px;
        line-height: 21px;

        strong{
          font-family: "AG Bold Regular";

          sup{
            font-size: 60%;
            line-height: 0;
          }
        }
      }
    }

    .footer-content{
      display: flex;
      justify-content: space-between;
      align-items: center;

      .text{
        font-size: 9.8px;
        font-family: "Sofia Pro";
        line-height: 13px;
        color: $modal-title-color;

        sup{
          font-size: 70%;
          line-height: 0;
        }

        strong{
          font-family: "Sofia Pro Bold";
        }
      }
      .ctn-button{
        margin-right: 2.1rem;
      }
    }
  }
  .button{
    padding: 9.5px 15px;
    font-family: "AG Medium";
    -webkit-transform: skew(-15deg);
    transform: skew(-15deg);
    font-size: 14px;
    text-transform: uppercase;
    text-decoration: none;
    display: inline-block;
    color: $white;
    background-color: $dark-blue;

    p{
      margin: 0;
      -webkit-transform: skew(15deg);
      transform: skew(15deg);
    }
  }
}
</style>
