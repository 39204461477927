<template>
  <page>
    <div class="content">
      <!-- <div class="ic">Image Compare</div> -->
      <div class="title">
        <h1>
          SEE WHAT PATIENTS THINK ABOUT<br />
          AKLIEF<sup>&reg;</sup> (trifarotene) Cream, 0.005%.
        </h1>
      </div>
      <div class="main-content">
        <div class="main-content__inner">
          <div class="box one">
            <img
              class="icon"
              src="@/assets/images/version-4/jpg-png/icon-6.png"
              alt=""
            />
            <p>
              <strong>90%</strong> of AKLIEF Cream patients reported their acne
              had small to no impact on<br />
              quality of life after 1 year.<sup>3</sup>
            </p>
          </div>
          <hr />
          <div class="box two">
            <img
              class="icon"
              src="@/assets/images/version-4/jpg-png/icon-1.png"
              alt=""
            />
            <p>
              <span class="p p-1">In the DUAL Study:</span>
              <span class="p p-2"
                ><strong>86%</strong> were satisfied or very satisfied with
                treatment.<sup>19</sup></span
              >
              <span class="p p-3"
                ><strong>77%</strong> were satisfied or very satisfied with
                quick onset of action.<sup>19</sup>
              </span>
              <span class="p p-4"
                ><strong>90%</strong> reported a high level of AKLIEF Cream
                acceptability and a willingness to use the regimen again.<sup
                  >19</sup
                ></span
              >
            </p>
          </div>
          <img
            class="feature-img"
            src="@/assets/images/version-4/jpg-png/img-1.png"
            alt=""
          />
        </div>
      </div>
    </div>
  </page>
</template>

<script>
import Page from '@/components/common/Page'

export default {
  name: 'patient-qol',
  components: {
    page: Page
  }
}
</script>

<style lang="scss">
// Image Compare
// .ic {
//   width: 100%;
//   height: 1536px;
//   background: url("~@/assets/images/version-4/jpg-png/IC-32.png") 0 0 no-repeat;
//   background-size: contain;
//   position: absolute;
//   top: 0;
//   left: 0;
//   opacity: 0.5;
//   z-index: 999;
// }
.page.patient-qol {
  background-image: url("~@/assets/images/version-4/jpg-png/bg-screen.jpg");
  background-repeat: no-repeat;
  background-position: center 0;
  background-size: 100%;
  color: #fff;
  .title {
    color: $dark-blue;
    margin: 0 0 21px 41px;
    position: relative;
    letter-spacing: 0.04px;
    h1 {
      font-family: "AG Bold Condensed";
      font-size: 41px;
      line-height: 0.98;
      margin: 0;
      sup {
        font-size: 18px;
      }
    }
  }
  .main-content {
    color: $font-color-gray;
    background: $white;
    margin-left: 42px;
    padding: 60px 49px 0px 45px;
    height: 485px;
    width: 982px;
    box-sizing: border-box;
    position: relative;
    right: 0;
    &__inner {
      width: 435px;
    }
    .box {
      display: flex;
      color: $modal-title-color;
      &.one {
        margin-bottom: 27px;
      }
      &.two {
        padding-top: 17px;
        font-size: 15px;
        .icon {
          margin-top: -23px;
        }
        span {
          display: block;
          &.p-1 {
            font-family: "AG Medium";
            margin-bottom: 5px;
          }
          &.p-2 {
            margin-bottom: 7px;
            strong {
              margin-right: 1px;
            }
          }
          &.p-3 {
            margin-bottom: 6px;
          }
          &.p-4 {
            strong {
              font-size: 22px;
              padding-left: 1px;
              position: relative;
              display: inline-block;
              &::before {
                content: ">";
                display: block;
                font-size: 15px;
                position: absolute;
                top: 6px;
                left: -9px;
              }
            }
          }
        }
      }
      .icon {
        width: 76px;
        margin-top: 8px;
        margin-right: 34px;
        align-self: center;
      }
      p {
        letter-spacing: -0.16px;
        line-height: 1.25;
        sup {
          font-size: 8px;
        }
        strong {
          font-family: "AG Bold Regular";
          font-size: 22.4px;
        }
      }
    }
    hr {
      border: 0 none;
      height: 1px;
      /* Set the hr color */
      color: $light-gray; /* old IE */
      background-color: $light-gray; /* Modern Browsers */
      width: 384px;
      margin-left: 17px;
    }
    .feature-img {
      width: 421px;
      position: absolute;
      bottom: -86px;
      right: 74px;
    }
  }
}
</style>
