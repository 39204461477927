<template>
  <page>
    <div class="galderma-logo">
      <img
        src="@/assets/images/version-4/svg/galderma-log-white.svg"
        width="126.5"
        alt=""
      />
    </div>
    <div class="content">
      <div class="content-paragraph-upper">
        <h3 class="uppercase">
          A powerful treatment proven in clinical trials to
        </h3>
        <h1>
          Rapidly <span class="b-bottom">CLEAR ACNE<span>*</span></span> and REDUCE the
          <br />
          Risk and Severity<sup>†</sup> of
          <span class="b-bottom">ACNE SCARRING</span>
          <sup class="light-text">1-3</sup>
        </h1>
      </div>
      <div class="content-paragraph-middle">
        <p class="content-paragraph-middle__first">
          AKLIEF<sup>®</sup>(trifarotene) Cream 0.005% is a retinoid indicated
          for the<br />
          topical treatment of acne vulgaris in patients 9 years of age and
          older.
        </p>
        <p class="content-paragraph-middle__second">
          AKLIEF Cream contains the retinoid molecule trifarotene which
          specifically targets RAR-y,<br />
          the most common retinoic acid receptor in the skin.<sup>4</sup> <br />
          The exact process by which trifarotene ameliorates acne is unknown.
        </p>
      </div>
      <div class="content-footnote">
        <p>
          *In the pivotal trials of AKLIEF Cream, IGA (face) and PGA (trunk)
          success were each defined on a 5 point <br />
          scale (0 - Clear, to 4 - Severe) as 2-grade improvement and a score of
          0 (clear) or 1 (almost clear). All <br />
          subjects had an IGA and PGA scores of 3 (moderate) at baseline. After
          12 weeks of treatment, 29.4% of <br />
          subjects in Pivotal 1 and 42.3% of subjects in Pivotal 2 achieved IGA
          success on the face, and 35.7% of <br />
          subjects in Pivotal 1 and 42.6% of subjects in Pivotal 2 achieved PGA
          success on the trunk.<sup>1</sup> <br />
          <sup>†</sup>Acne-scarring was assessed by scar counts and SGA (Scars
          Global Assessment). SGA assesses severity <br />
          on a scale from 0 (clear) to 4 (severe).
        </p>
      </div>
      <div class="disclaimer-text">
        <p>Not an actual patient.</p>
      </div>
    </div>
  </page>
</template>

<script>
import Page from '@/components/common/Page'

export default {
  components: {
    page: Page
  }
}
</script>

<style lang="scss">
.page.home {
  background-image: url("~@/assets/images/version-4/jpg-png/bg-home-v2.png");
  background-size: 101%;
  color: #fff;
}

.home {
  .galderma-logo {
    position: absolute;
    top: 22px;
    left: 395px;
  }
  .content {
    position: relative;
    color: $dark-blue;
    &-paragraph-upper {
      position: absolute;
      left: 295px;
      top: 110px;
      h3 {
        font-family: "AG Medium";
        font-size: 20px;
        letter-spacing: 0.2px;
        margin: 0;
        margin-bottom: 5px;
      }
      h1 {
        /* font-family: "AG Bold Italic Condensed"; */
        font-family: "AG Bold Condensed";
        font-style: italic;
        font-size: 44px;
        line-height: 1.4;
        margin: 0;
        .b-bottom {
          /* border-bottom: 2px solid $dark-blue; */
          text-decoration: underline;
          text-decoration-thickness:3px;
          text-underline-offset: 1px;
          span{
            font-size: 60%;
            padding-left: 2px;
            position: absolute;
            top: 32px;
            transform: rotate(9deg);
          }
        }
        sup {
          font-size: 28px;
        }
        .light-text {
          font-family: "AG Condensed Italic";
          margin-left: 2px;
        }
      }
    }
    &-paragraph-middle {
      position: absolute;
      right: 10px;
      top: 340px;
      &__first {
        font-size: 15px;
        font-family: "AG Medium";
        font-style: italic;
        sup {
          font-size: 10px;
          margin-left: 1.5px;
        }
      }
      &__second {
        font-size: 12.5px;
        line-height: 1.3;
        sup {
          font-size: 6px;
        }
      }
    }
    &-footnote {
      position: absolute;
      right: 70px;
      top: 500px;
      font-family: "Sofia Pro";
      font-size: 10px;
      color: #fff;
      line-height: 1.3;
      sup {
        font-size: 4px;
      }
    }
    .disclaimer-text {
      position: absolute;
      top: 600px;
      color: #fff;
      font-family: "AG BQ Regular";
      font-size: 12.5px;
      left: 290px;
    }
  }
}
</style>
