<template>
  <div class="leap-tolerability-graph1">
    <header>
      <h1 class="title">Face: Tolerability Signs and Symptoms at Week 24</h1>
    </header>
    <main>
      <img src="@/assets/images/version-4/jpg-png/leap-tolerability/leap-tolerability-graph-1.jpg" alt="" />
    </main>
  </div>
</template>
<script>
export default {}
</script>
<style lang="scss">
.modal-leap-tolerability-graph1 {
  .modal {
    &__body {
      padding: 55px 62px 65.5px 62px;
      height: 611px;
      width: 922.5px;
      margin-left: -20px;
      margin-top: 3px;
    }
  }

  .leap-tolerability-graph1 {
    display: flex;
    flex-direction: column;

    header {
      width: 100%;
    }

    .title {
      font-family: "AG Medium";
      font-size: 25px;
      text-align: center;
      margin: 0;
      text-transform: none;
      display: block;
      z-index: 2;
      position: relative;
    }

    main {
      display: flex;
      justify-content: center;

      img {
        width: 100%;
        margin-top: -25px;
      }
    }
  }
}
</style>
