<template>
  <div class="leap-results-graph">
    <header>
      <h1 class="title">Percent change in PIH ODS scores from baseline (ITT).<sup>†</sup> </h1>
    </header>
    <main>
      <img src="@/assets/images/version-4/jpg-png/leap-results/leap-results-graph.png" alt="" />
    </main>
  </div>
</template>
<script>
export default {}
</script>
<style lang="scss">
.modal-leap-results-stats {
  .modal {
    &__body {
      padding: 0 ;
    }
  }
}
.leap-results-graph {
    header{
      margin-left: 109px;
      margin-bottom: 11px;
      margin-top: 16px;
    }
    .title {
      line-height: 40px;
      margin: 0 0 0 -1px;
      font-size: 25px;
      font-family: "AG Medium";
      text-transform: none;

      sup{
        font-size: 65%;
        line-height: 0;
      }
    }
    img {
      max-width: 100%;
    }
    main {
      margin: 0 55px 45px 30px;
      img {
        margin-bottom: 9px;
      }
    }
  }
</style>
