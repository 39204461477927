<template>
  <page>
    <div class="content">
      <!-- Title -->
      <div class="title">
        <h1>
          SAFE AND WELL-TOLERATED ON THE FACE
          <br />AT WEEK 12 AND AT WEEK 52.<sup>1,3</sup>
        </h1>
      </div>

      <div class="content_copy">
        <tabs :class="'horizontal-layout'">
          <div slot="content">
            <div class="tabs__buttons">
              <router-link :to="{ name: 'tolerability:week52' }"
                ><span>FACE</span></router-link
              >
              <router-link :to="{ name: 'tolerability:week52trunk' }"
                ><span>TRUNK</span></router-link
              >
            </div>
            <div class="tabs__content">
              <tolerability-face></tolerability-face>
            </div>
          </div>
        </tabs>
      </div>
    </div>
  </page>
</template>

<script>
import Page from '@/components/common/Page'
import Tabs from '@/components/common/Tabs'
import TolerabilityFace from '@/components/tabs/TolerabilityWeek52'

export default {
  name: 'tolerability',
  components: {
    page: Page,
    tabs: Tabs,
    'tolerability-face': TolerabilityFace
  },
  data: function () {
    return {
      title: 'RAPID RESULTS* AND POWERFUL <br>EFFICACY VS VEHICLE.<sup>2</sup>',
      modals: {
        studyDesign: {
          isVisible: false
        }
      }
    }
  }
}
</script>

<style lang="scss">
.page.tolerability {
  background-image: url("~@/assets/images/version-4/jpg-png/bg-screen.jpg");
  background-repeat: no-repeat;
  background-position: center 0;
  background-size: 100%;
  color: #fff;
  img {
    max-width: 100%;
  }
  sup {
    font-size: 60%;
  }
  .title {
    h1 {
      color: $dark-blue;
      font-family: "AG Bold Condensed";
      font-size: 41px;
      line-height: 0.975;
      letter-spacing: 0px;
    }
    sup {
      font-size: 40%;
      font-family: "AG Light Condensed";
      position: relative;
      top: -1px;
      left: -1px;
    }
  }
  .content {
    .efficacy-face-content {
      .tabs__buttons {
        a {
          font-weight: 100;
          font-family: "Sofia Pro Medium";
          line-height: 48.2px;
        }
      }
    }
  }
}
// Fix tab buttons position
.tabs.horizontal-layout > div > .tabs__buttons {
  padding: 0 17px 0 0;
}
</style>
