<template>
  <page>
    <div class="content">
      <div>
        <div class="title">
        <h1 class="margin-null">MEAN TOLERABILITY SCORES<br>REMAINED BELOW MILDs<sup class="sup-h1">20</sup> </h1>
        <div>
          <p>During the Phase 4 LEAP study with AKLIEF<sup>®</sup>  (trifarotene) Cream, 0.005%</p>
          <ul class="nav inner-nav-dual">
            <li>
              <router-link
                :to="{ name: 'dual-study:leap-efficacy' }"
                class="custom-link"
                ><span>Efficacy</span></router-link
              >
            </li>
            <li>
              <router-link
                class="custom-link"
                :to="{ name: 'dual-study:leap-results' }"
                ><span>Results</span></router-link
              >
            </li>
            <li>
              <router-link
                class="custom-link router-link-active"
                :to="{ name: 'dual-study:leap-tolerability' }"
                ><span>Tolerability</span></router-link
              >
            </li>
          </ul>
        </div>
        </div>
        <div class="stats-content">
          <div class="head-content">
            <span class="head-title">Face: Tolerability Signs and Symptoms at Week 24</span>
          </div>
          <div class="middle-content">
              <div>
                <div class="col-6">
                  <img class="chart-1" src="@/assets/images/version-4/jpg-png/leap-tolerability/leap-tolerability-graph-1.jpg">
                </div>
                <a
                  class="enlarge-chart-1"
                  href="#"
                  @click="toggleModal('LeapTolerabilityGraph1')"
                ></a>
              </div>
              <div>
                <div class="col-6">
                  <img class="chart-2" src="@/assets/images/version-4/jpg-png/leap-tolerability/leap-tolerability-graph-2.jpg">
                </div>
                <a
                  class="enlarge-chart-2"
                  href="#"
                  @click="toggleModal('LeapTolerabilityGraph2')"
                ></a>
              </div>
          </div>
          <div class="footer-content">
            <span class="text"> <strong>FSDT</strong>=final score during treatment; <strong>WPBS</strong>=worst post-baseline score.</span>
            <div class="ctn-button">
              <a  href="#" class="button" @click="toggleModal('LeapStudyDesign')">
                <p>Study Design</p>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </page>
</template>

<script>
import Page from '@/components/common/Page'

export default {
  name: 'leap-tolerability',
  components: {
    page: Page
  },

  data () {
    return {
    }
  },

  computed: {
  },

  methods: {
  }

}
</script>
<style lang="scss" scoped>
.page.leap-tolerability {
  .title {
    color: $dark-blue;
    margin: 0 0 45px 41px;
    position: relative;
    div{
      ul{
        top: 92px;
      }
    }
    h1 {
      font-family: "AG Bold Condensed";
      font-size: 41px;
      line-height: 0.975;
      margin: 0 0 6px 0;
      text-transform: uppercase;
    }
    p {
      margin: 0;
      line-height: 1.1;
      font-family: "AG Medium";
      display: block;
    }
    sup {
      font-size: 9px;
    }
    .sup-h1 {
      vertical-align: top;
      font-size: 18px;
    }
  }

  .margin-null{
    margin: 0 !important
  }

  .stats-content {
    border-top: 7px solid $light-green;
    color: $font-color-gray;
    background: $white;
    padding: 50px 25px 0px 15px;
    height: 438.5px;
    width: 990px;
    position: relative;
    box-sizing: border-box;

    .head-content{
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-family: "AG Medium";
      margin-top: 22px;
      margin-bottom: 0px;

      .head-title{
        color: $modal-title-color;
        font-size: 14px;
      position: absolute;
      top: 40px;
      }

      .ctn-results{
        color: $medium-violet;
        display: flex;
        align-items: center;
        .text{
          font-size: 10px;
          margin-right: 8px;
        }

        .arrow{
          font-size: 25px;
          font-family: monospace;
        }
      }
    }

    .middle-content{
      display: flex;
      margin-bottom: 13px;
      margin-top: -15px;
      .chart-1{
        width: 450px;
        margin-left: 0;
      }
      .chart-2{
        width: 450px;
        margin-left: 40px;
      }
      .label{
        color: $modal-title-color;
        font-family: "AG BQ Regular";
        font-size: 17.5px;
        margin-left: 45px;
        margin-right: 15px;
        line-height: 21px;

        strong{
          font-family: "AG Bold Regular";
        }
      }
    }

    .footer-content{
      display: flex;
      justify-content: space-between;
      align-items: center;

      .text{
        font-size: 9.8px;
        font-family: "Sofia Pro";
        line-height: 13px;
        color: $modal-title-color;

        strong{
          font-family: "Sofia Pro Bold";
        }
      }
      .ctn-button{
        margin-right: 2.1rem;
      }
    }
  }
  .button{
    padding: 9.5px 15px;
    font-family: "AG Medium";
    -webkit-transform: skew(-15deg);
    transform: skew(-15deg);
    font-size: 14px;
    text-transform: uppercase;
    text-decoration: none;
    display: inline-block;
    color: $white;
    background-color: $dark-blue;

    p{
      margin: 0;
      -webkit-transform: skew(15deg);
      transform: skew(15deg);
    }
  }
  .enlarge-chart-1 {
    width: 29px;
    height: 29px;
    position: absolute;
    top: 85px;
    left: 450px;
    z-index: 99;
    background: url("~@/assets/images/version-4/svg/enlarge.svg") no-repeat;
    background-size: cover;
    display: block;
  }
  .enlarge-chart-2 {
    width: 29px;
    height: 29px;
    position: absolute;
    top: 85px;
    right: 20px;
    z-index: 99;
    background: url("~@/assets/images/version-4/svg/enlarge.svg") no-repeat;
    background-size: cover;
    display: block;
  }
}
</style>
