<template>
  <page>
    <div class="content">
      <div class="sub-header">
        <h1 class="title">
          THE DUAL STUDY SHOWED SIGNIFICANT<br />RESULTS FOR PATIENTS WITH
          SEVERE ACNE.<sup>19</sup>
        </h1>
        <div>
          <h4 class="subtitle">
            Phase 4 Study of AKLIEF<sup>&reg;</sup> (trifarotene) Cream, 0.005%<br />
            with 120 mg enteric coated oral doxycycline in a<br />
            population with severe acne.<sup>19</sup>
        </h4>
          <ul class="nav inner-nav-dual">
            <li>
              <router-link
                :to="{ name: 'dual-study:efficacy' }"
                :class="{
                  'router-link-active':
                  this.$route.name === 'efficacy'
                }"
                ><span>Efficacy</span></router-link
              >
            </li>
            <li>
            <router-link
              class="custom-link"
              :to="{ name: 'dual-study:dual-study-iga' }"
              ><span>IGA success</span></router-link
            >
            </li>
            <li>
              <router-link
                class="custom-link"
                :to="{ name: 'dual-study:dual-study-results' }"
                ><span>Results</span></router-link
              >
            </li>
            <li>
              <router-link
                class="custom-link"
                :to="{ name: 'dual-study:dual-study-tolerability' }"
                ><span>Tolerability</span></router-link
              >
            </li>
          </ul>
        </div>
      </div>
      <div class="body">
        <div class="wrapper">
          <div class="col chart">
            <p class="chart-title">
              Significant Reduction in Total Facial Lesion Count at Week 12 vs
              Baseline<sup>19</sup>
            </p>
            <div class="chart-image">
              <img src="@/assets/images/version-4/svg/chart-9.svg" alt="" />
            </div>
          </div>
          <div class="col chart-extra">
            <img src="@/assets/images/version-4/svg/dual-efficacy/stat-3-v2.svg" alt="" />
          </div>
        </div>
        <div class="foot">
          <div class="col right">
            <a href="#" class="button" @click="toggleModal('StudyDesign')">
              <p>Study Design</p></a
            >
          </div>
          <div class="notes col">
            <p>
              Figure is based on observed case analysis. Results of MI
              statistical analysis at Week 12 are included. For percent change
              in total lesion count from baseline<br />(MI), the difference
              between treatment groups [95% CI] was -8.6 [-16.5, -0.7] at Week 4
              (<i>P</i>=0.0334), -12.1 [-20.0, -4.2] at Week 8 (<i>P</i>=0.0028)
              and -21.5 [-28.9,<br />
              -14.1] at Week 12 (<i>P</i>&#60;0.0001). CI=confidence interval;
              MI=multiple imputation statistical modeling.<sup>19</sup>
            </p>
          </div>
        </div>
      </div>
    </div>
  </page>
</template>

<script>
import Page from '@/components/common/Page'

export default {
  name: 'dual-study-efficacy',
  components: {
    page: Page
  }
}
</script>

<style lang="scss">
.page.dual-study {
  background-image: url("~@/assets/images/version-4/jpg-png/bg-nologo.png");
  background-repeat: no-repeat;
  background-position: center 0;
  background-size: 100%;

  .sub-header {
    padding-left: 41.5px;
    margin-top: -11px;
    .title {
      font-family: "AG Bold Condensed";
      font-size: 41px;
      text-transform: uppercase;
      line-height: 0.975;
      /* letter-spacing: 0.4px; */
      color: #071d49;
      margin: 0 0 3px 0;
      sup {
        font-family: "AG Light Condensed";
        font-size: 20px;
        font-weight: 600;
        margin-left: -3px;
        vertical-align: text-top;
      }
    }
    .subtitle {
      margin: -2px 0 0 0;
      font-family: "AG Medium";
      color: #071d49;
      line-height: 17.5px;
      font-size: 17.5px;
      sup {
        font-family: "AG Light Regular";
        font-size: 9px;
        vertical-align: top;
      }
    }
    div{
      ul{
        top: 143px;
        font-family: "AG BQ Regular";
      }
    }
  }
  .body {
    background-color: $white;
    margin-top: 15px;
    margin-right: 35px;
    .wrapper {
      display: flex;
      .chart {
        padding-left: 43px;
        padding-top: 10px;
        &-title {
          margin: 0;
          font-family: "AG Medium";
          font-size: 19px;
          color: #071d49;
          sup {
            font-family: "AG BQ Regular";
            font-size: 12px;
          }
        }
        &-image {
          width: 575.5px;
          padding-top: 24px;
          padding-left: 4px;
        }
        &-extra {
          padding: 0 33px;
          img {
            width: 208.5px;
          }
        }
      }
    }
  }
  .foot {
    display: flex;
    padding-bottom: 3px;
    .right {
      margin: 11px 0 0 49px;
    }
    .button {
      padding: 9.5px 15px;
      font-family: "AG Medium";
      transform: skew(-15deg);
      font-size: 14px;
      text-transform: uppercase;
      text-decoration: none;
      display: inline-block;
      color: $white;
      background-color: $dark-blue;
      p {
        margin: 0;
        transform: skew(15deg);
      }
    }
    .notes {
      margin: 0px 0px 0 39px;
      p {
        font-family: "Sofia Pro";
        font-size: 10px;
        color: $font-color-gray;
        letter-spacing: -0.07px;
        line-height: 13px;
        sup {
          font-size: 6px;
          vertical-align: middle;
          line-height: 0px;
        }
      }
    }
  }
}
</style>
