<template>
  <div class="tabs">
    <slot name="content"></slot>
  </div>
</template>

<script>
export default {
  name: 'tabs'
}
</script>

<style lang="scss">
.tabs.horizontal-layout {
  right: 35px;
  position: absolute;
  left: 0;
  top: 108px;
  > div {
    > .tabs__buttons {
      text-align: right;
      border-bottom: 6px solid $light-green;
      padding: 0 37px 0 0;

      a {
        display: inline-block;
        text-decoration: none !important;
        width: 110px;
        height: 44px;
        line-height: 49px;
        text-align: center;
        color: #fff;
        background: $dark-blue;
        font-size: 15px;
        font-weight: bold;
        transform: skew(-13.3deg);
        margin: 0 0 0 11px;
        text-indent: -2px;
        span {
          letter-spacing: -1px;
          transform: skew(13.3deg);
          display: block;
        }

        &:first-child {
        }

        &:last-child {
          text-indent: 7px;
        }

        &.router-link-active {
          background-color: $light-green;
          color: $dark-blue;
        }

      }
    }

    > .tabs__content {
      height: 479px;
      background: #fff;
    }
  }
}
.tabs.vertical-layout {
  position: relative;
  > div {
    > .tabs__buttons {
      right: 0;
      position: absolute;
      left: initial;
      top: 37px;
      a {
        display: block;
        text-decoration: none !important;
        width: 262px;
        height: 43px;
        line-height: 46px;
        color: $font-color-blue;
        background: $light-gray;
        font-size: 15px;
        font-weight: bold;
        padding: 0 0 0 17px;
        margin: 0 0 5px 0;
        box-sizing: border-box;

        &.router-link-active, &.active {
          background-color: #5fc3d3;
          color: #fff;
          position: relative;
          &::before {
            content: '';
            display: block;
            position: absolute;
            bottom: 0;
            right: 100%;
            border-top: 22px solid #fff;
            border-right: 12px solid #5fc3d3;
            border-bottom: 22px solid #fff;
          }
        }

      }
    }

    > .tabs__content {
      height: 450px;
      background: #fff;
    }
  }
}
</style>
