<template>
  <page>
    <div class="content">
      <!-- Title -->
      <div class="title">
        <h1>
          NEARLY HALF THE PATIENTS IN AKLIEF<sup>&reg;</sup> (trifarotene)<br />
          Cream, 0.005% PIVOTAL STUDIES WERE TEENS.<sup>1</sup>
        </h1>
      </div>

      <!-- Description -->
      <div class="description">
        <div class="main-image">
          <img src="~@/assets/images/version-4/jpg-png/img-2.jpg" alt="" />
        </div>

        <div class="main-content">
          <div class="text-top">
            Facial and truncal acne negatively
            <br />impacts teen patients' participation in <br />activities they
            love.<sup>6</sup>
          </div>

          <div class="separator-line"></div>

          <div class="text-center">
            <div class="left">
              <img src="~@/assets/images/version-4/svg/85.svg" alt="" />
              <p>
                of people aged <br />12 to 25 experience <br />some form of
                acne<sup>6</sup>
              </p>
            </div>

            <div class="middle">
              <img src="~@/assets/images/version-4/svg/50.svg" alt="" />
              <p>
                of teens experience <br /><span
                  >unfair treatment at <br />school</span
                >
                and negative <br />effects on their school <br />performance
                because <br />of their acne<sup>6</sup>
              </p>
            </div>

            <div class="right">
              <img src="~@/assets/images/version-4/svg/39.svg" alt="" />
              <p>
                <span
                  >of teens avoid <br />participating in <br />activities</span
                >
                that <br />revealed their acne<sup>6</sup>
              </p>
            </div>
          </div>

          <div class="text-bottom">
            <h3>AKLIEF Cream and teen patients</h3>
            <ul>
              <li>
                Can provide acne relief for your teen patients who have both
                facial and<br />
                truncal acne.<sup>4</sup>
              </li>
              <li>
                In clinical trials, the most common adverse reactions (incidence
                ≥ 1%) in<br />
                patients treated with AKLIEF Cream were application site
                irritation,<br />
                application site pruritus (itching), and sunburn.<sup>7</sup>
              </li>
            </ul>
            <div class="image">
              <img
                src="~@/assets/images/version-4/jpg-png/aklief-1.png"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </page>
</template>

<script>
import Page from '@/components/common/Page'

export default {
  components: {
    page: Page
  }
}
</script>

<style lang="scss">
.page.patient-profiles {
  background-image: url("~@/assets/images/version-4/jpg-png/bg-screen.jpg");
  background-repeat: no-repeat;
  background-position: center 0;
  background-size: 100%;
  color: #fff;
  img {
    max-width: 100%;
  }
  sup {
    font-size: 60%;
  }
  .title {
    h1 {
      color: $dark-blue;
      font-family: "AG Bold Condensed";
      font-size: 41px;
      line-height: 0.975;
      margin: 6px 0 18px 40px;
    }
    sup {
      font-size: 50%;
      font-family: "AG Light Condensed";
    }
  }
  .description {
    display: flex;
    color: $dark-blue;

    p {
      text-align: left;
    }

    > .main-image {
      order: 2;
      flex: 0 0 362px;
    }

    > .main-content {
      background: #fff;
      padding: 40px 50px 0px;
      height: 444px;
      width: 528px;

      .text {
        &-top {
          font-family: "AG Medium";
          font-size: 17px;
          margin: 8px 0 0 201px;
          sup {
            font-family: "AG BQ Regular";
          }
        }
        &-center {
          font-family: "Sofia Pro";
          display: flex;
          width: 530px;
          padding: 18px 0 0 0;

          img {
            width: 97px;
            display: block;
            margin: auto;
          }

          > div {
            flex: auto;
          }

          > .left {
            padding: 22px 0 0 0;
            flex: 0 0 175px;
            font-family: "AG Medium";
            img {
              position: relative;
              left: -36px;
              width: 119px;
            }
            p {
              position: relative;
              top: -10px;
              left: -13px;
              font-size: 19px;
              line-height: 1.1;
              sup {
                font-family: "AG BQ Regular";
              }
            }
          }

          > .middle {
            flex: 0 0 216px;
            img {
              position: relative;
              top: 5px;
              left: -20px;
            }
            p {
              position: relative;
              top: -5px;
              left: 25px;
              font-size: 15px;
              line-height: 15px;
              span {
                font-family: "Sofia Pro Bold";
                letter-spacing: 0;
                font-size: 17.5px;
                line-height: 17.5px;
              }
            }
          }

          > .right {
            img {
              position: relative;
              top: 4px;
              left: -11px;
              width: 100px;
            }
            p {
              position: relative;
              top: -10px;
              left: -2px;
              font-size: 15px;
              line-height: 15px;
              span {
                font-family: "Sofia Pro Bold";
                letter-spacing: 0;
                font-size: 17.5px;
                line-height: 17.5px;
              }
            }
          }
        }
        &-bottom {
          width: 593px;
          height: 123px;
          margin: 16px 0 0 4px;
          padding: 6px 25px;
          box-sizing: border-box;
          position: relative;
          top: -32px;
          left: -40px;

          img {
            width: 74px;
            height: auto;
            position: absolute;
            right: 20px;
            top: -5px;
          }

          h3 {
            font-family: "AG Medium";
            font-size: 13px;
            margin: 22px 0 0 1px;
          }

          ul {
            margin: 0;
            padding: 1px 0 0 15px;
          }

          p,
          ul,
          li {
            font-size: 12px;
            color: $font-color-gray;
            margin: 2px 0 0 0;
            line-height: 1.2;
          }
        }
      }
    }
  }
}
</style>
