<template>
  <div class="sally-cream">
    <div class="main-content">
      <div class="col-content">
        <h1><span>About</span> AKLIEF<sup>&reg;</sup> <span>Cream</span></h1>
        <ul>
          <li>
            AKLIEF Cream is indicated for <span class="bold-font">patients 9 years or older</span
            ><sup>5</sup>
          </li>
          <li>
            <span class="bold-font">Almost half</span> of the 2,000 patients included in the <br>
            AKLIEF Cream clinical trials <span class="bold-font">were teens</span>—with 1.4% as <br>
            young as 9 years old<sup>2,5</sup>
          </li>
          <li class="pivotal-text">
            In the PIVOTAL 1 Study, powerful reduction in facial <br>
            inflammatory lesions was seen at <span class="bold-font">Week 12</span> vs vehicle<sup>2</sup><br />
            <span class="small-text"
              >- Subjects treated with AKLIEF Cream achieved <span class="bold-font">54.4% fewer<br>
              inflammatory lesions</span> vs baseline at Week 12 vs 44.8% <br>
              reduction with vehicle<sup>2</sup></span
            >
          </li>
          <li>
            AKLIEF Cream was <span class="bold-font">proven safe and well-tolerated</span> for <br>
            face and trunk across 4 areas (erythema, scaling, <br>
            dryness, stinging/burning) in all the clinical trials<sup>2</sup>
          </li>
          <li>
            In fact, <span class="bold-font">9 out of 10 clinical trials patients</span> completed <br>
            the trials<sup>2</sup>
          </li>
        </ul>
        <div class="footnote">
          The most common adverse reactions (incidence ≥ 1%) in patients treated <br>
          with AKLIEF Cream were application site irritation, application site pruritus <br>
          (itching), and sunburn.
        </div>
      </div>
      <div class="col-image">
        <img
          src="@/assets/images/version3/aklief-packshot-2.png"
          height="492"
          alt=""
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss">
.modal-ava-aklief-cream {
  .modal__body {
    padding: 16px 72px 85px 72px;
    margin-top: 34px;
    height: 550px;
    overflow: hidden;
  }
  .modal__close {
    button {
      bottom: -34px;
    }
  }
  .main-content {
    display: flex;
    h1 {
      font-family: "Sofia Pro Medium";
      font-size: 31px;
      color: $font-color-blue;
      margin-top: 20px;
      sup {
        font-family: "Myriad Pro";
        vertical-align: text-bottom;
        font-size: 90%;
      }
      span {
        font-family: "Sofia Pro Medium";
        font-size: 31px;
      }
    }
    .col-content {
      margin-top: 0px;
      margin-right: 112.5px;
      margin-left: -7px;
      font-family: "Sofia Pro";
      font-size: 16.5px;
      color: $font-color-gray;
      ul {
        padding-left: 20px;
        margin-top: -14px;
        margin-left: 0px;
        letter-spacing: -0.35px;
        li {
          line-height: 1.3;
          margin-bottom: 15px;
          list-style-type: none;
          position: relative;
          &:before {
            content: "•";
            color: $font-color-blue;
            display: inline-block;
            position: absolute;
            left: -20px;
            top: 1px;
          }
          &:nth-child(4),&:nth-child(5) {
            line-height: 1.2;
            margin: 0 0 10px 0;
          }
        }
        .pivotal-text {
          margin-top: 20px;
          letter-spacing: -0.35px;
        }
        .bold-font {
          font-family: "Sofia Pro Bold";
          line-height: 15px;
        }
        .small-text {
          display: inline-block;
          font-size: 13px;
          margin-top: 7px;
          margin-left: 10px;
          letter-spacing: -0.1px;
          line-height: 12px;
          text-indent: -10px;
        }
        sup {
          font-size: 50%;
        }
      }
      .footnote {
        font-size: 13px;
        position: absolute;
        line-height: 1.34;
      }
    }
    .col-image {
      margin-left: -26px;
      margin-top: 30px;
    }
  }
}
</style>
