<template>
  <div class="tolerability-face-content">
    <tabs :class="'vertical-layout'">
      <div slot="content">
        <div class="tabs__content">
          <!-- Description -->
          <div class="description">
            <div class="inner">
              ALL MEAN<br />
              TOLERABILITY SCORES <br />
              ON THE 4 PARAMETERS <br />
              MEASURED <br />
              REMAINED BELOW <br />
              <div class="large">MILD<sup class="light">1</sup></div>
              <br />
              <div class="line"></div>
              <div class="bottom">
                <div class="large">
                  <span class="number">87.4<sup class="percent">%</sup></span>
                </div>
                <br />
              </div>
              <div class="bottom2">
                OF PATIENTS<br />
                DID NOT REPORT ANY<br />
                TREATMENT-EMERGENT<br />
                ADVERSE EVENTS<br />
                RELATED TO<br />
                AKLIEF<sup>&reg;</sup> (trifarotene)<br />
                <span>Cream, 0.005%<sup>3</sup></span>
              </div>
            </div>
          </div>

          <!--  Chart -->
          <div class="chart">
            <div class="chart__title">
              Face: Tolerability Signs and Symptoms at Week 52<sup>3</sup>
            </div>
            <div class="chart__image">
              <img src="~@/assets/images/version-4/svg/chart-7.svg" alt="" />
            </div>
          </div>
        </div>
      </div>
    </tabs>
    <!-- Footnote -->
    <div class="foot-note">
      <ul>
        <li>
          Mean tolerability scores for erythema, scaling, dryness, and
          stinging/burning peaked at Week 1 on the face and decreased
          thereafter<sup>3</sup>
        </li>
        <li>
          Local tolerability signs and symptoms were evaluated on a 4-point
          severity scale: 0 (none), 1 (mild), 2 (moderate), and 3 (severe) from
          <br />baseline to Week 52—all scores stayed below 1 through Week
          52<sup>3</sup>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'tolerability-face-tab'
}
</script>

<style lang="scss" scoped>
.description {
  position: absolute;
  width: 276px;
  right: 0px;
  bottom: 0;
  left: initial;
  top: 51px;
  background: $modal-border-color;
  text-align: center;
  padding: 60px 0px 0 0;
  font-family: "Sofia Pro Bold";

  .inner {
    position: relative;
    left: -3px;
    letter-spacing: -1.1px;
    line-height: 18px;
  }

  .large {
    font-size: 48px;
    font-family: "AG Bold Condensed";
    color: $light-green;
    font-weight: normal;
    line-height: 40px;

    sup.light {
      font-family: "AG Light Condensed";
      font-size: 45% !important;
    }
  }

  .line {
    border-top: 1px solid $light-green;
    width: 160px;
    margin: 10px auto 30px;
  }

  .bottom {
    letter-spacing: -1.4px;

    .large {
      font-size: 34px;
      margin-top: -10px;
      margin-bottom: -16px;
      line-height: 40px;
    }

    .number {
      font-size: 61px;
      position: relative;
      left: -4px;
    }

    .out-of {
      position: relative;
      left: 0px;
      letter-spacing: 0.5px;
    }

    .percent {
      font-size: 50%;
    }
  }

  .bottom2 {
    padding: 0 4px 0 0;
    letter-spacing: -1.4px;
    sup {
      font-family: "Sofia Pro";
    }
    span {
      margin-top: -3px;
      display: block;
    }
  }
}

.chart {
  position: absolute;
  width: 670px;
  left: 0;
  &__title {
    color: $dark-blue;
    margin: 24px 0 19px 44px;
    font-size: 19.5px;
    font-family: "AG Medium";
    letter-spacing: -0.3px;
    sup {
      font-family: "AG Light";
    }
  }
  &__image {
    padding: 10px 30px 0 45px;
  }
}

.foot-note {
  font-family: "Myriad Pro";
  color: $font-color-gray;
  position: absolute;
  top: 472px;
  color: #58595b;
  font-size: 10px;
  left: 53px;
  letter-spacing: 0.39px;
  line-height: 1.2;
  ul {
    margin: 0;
    padding: 0;
  }
}
</style>
