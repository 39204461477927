<template>
  <div class="container">
    <span class="pretitle">PATIENT SAVINGS CARD</span>
    <h1 class="title">
      PATIENTS MAY PAY AS LITTLE AS <span class="saving">$20</span>.<sup
        class="asterisk"
        >*</sup
      ><sup>†</sup>
    </h1>
    <div class="description">
      <div class="text">
        <p>
          Covered* patients may pay as little as $20 and<br/> uncovered/cash<sup>†</sup> paying patients pay $75 for Aklief<sup>®</sup> Cream.
          Patients can download a Patient Savings Card at
          <strong>
            <a
              href="#"
              onClick="M3.Pivot.Asset.OpenWebView('https://galdermacc.com/patients');return false;"
              >GaldermaCC.com/patients</a>.
          </strong><sup>‡∆</sup>
        </p>
      </div>

      <div class="text-2">
        <span class="bullet">*</span>”Covered” refers to commercial insurance product coverage without restrictions such as prior authorization approval, meeting step-edit and/or deductible requirements, and other criteria. <br/>
        <span class="bullet dagger"><sup class="dagger-icon">†</sup></span>”Not Covered” refers to commercial insurance product coverage with restrictions or no product coverage. “Cash” is available to patients without insurance.<br/>
        <span class="bullet dagger"><sup class="dagger-icon">‡</sup></span>Restrictions apply. Visit GaldermaCC.com/patients for program details. <br />
        <span class="bullet dagger"><sup class="">∆</sup></span
        >The Galderma<sup>&reg;</sup> CAREConnect<sup>TM</sup> Program (“Program”) is brought to you by
        Galderma Laboratories, L.P. (“Galderma”). The Program is only available at
        participating pharmacies for patients with commercial insurance or patients
        without insurance. Patients who are enrolled in a state or federal government
        run or government sponsored healthcare plan can not participate in the
        Program. Any claim under the  Program must be submitted by participating
        pharmacies to one of the Administrators of the Program. <br>

        The Program is subject to applicable state and federal law and is void where
        prohibited by law, rule or regulation. In the event a lower cost generic drug that
        the  FDA has designated as a therapeutic equivalent product is available for one
        of the Galderma products covered by the Program, or if the active ingredient of
        a Galderma product is available at a lower cost without a prescription, this
        offer will become void  in California with respect to the Galderma product.
      </div>
    </div>
  </div>
</template>
<script>
export default {}
</script>
<style lang="scss">
.modal-summary-patient-savings-card {
  .modal__body {
    top: -18px;
    // padding-top: 42px;
    // padding-left: 71px;
    background: $white url("~@/assets/images/version-4/jpg-png/card-bg.jpg")
      no-repeat bottom left;
    background-size: contain;
    height: 475px;
    box-sizing: border-box;
    padding-top: 42px;
    padding-left: 71px;
  }
  .pretitle {
    color: $light-blue-2;
    font-family: "Sofia Pro Bold";
    font-size: 11px;
    margin-bottom: 1px;
    margin-top: 0;
    letter-spacing: 1.1px;
    display: inline-block;
  }
  .description {
    position: absolute;
    right: 52px;
    top: 152px;
    width: 420px;
    color: $font-color-gray;
    p {
      strong {
        display: inline-block;
        margin-top: 5px;
        letter-spacing: -0.7px;
      }
    }
    .text {
      //line-height: 15px;
      margin-bottom: 52px;
      sup {
        font-size: 9px;
      }
      a {
        color: $light-blue-2;
      }
    }
    .text-2 {
      line-height: 1.2;
      sup {
        font-size: 7px;
        vertical-align: text-top;
      }
      .bullet {
        margin-left: -5px;
        display: inline-block;
        width: 5px;
      }
      .dagger {
        position: relative;
        &-icon {
          position: absolute;
          top: -6px;
          left: 1px;
        }
      }
    }
  }
  .title {
    font-size: 37px;
    margin: 0;
    line-height: 1;
    sup {
      font-size: 17px;
    }
    span {
      color: $light-blue-2;
    }
  }
  .text {
    font-family: "Sofia Pro";
    font-size: 16px;
    color: $font-color-gray;
    //line-height: 1.25;
    letter-spacing: 0.2px;
    box-sizing: border-box;
  }
  .text-2 {
    font-size: 9px;
    font-family: "Sofia Pro Light";
    box-sizing: border-box;
    line-height: 1.2;
    letter-spacing: -0.1px;
  }
}
</style>
