<template>
  <page>
      <div class="content">
          <div class="title">
              <h1>
                  LEAP STUDY DESIGN
              </h1>
              <div>
                  <p>
                      Phase 4 study of reduction in PIH subsequent to acne topical
                      <br /> treatment with AKLIEF<sup>&reg;</sup> (trifarotene) Cream, 0.005%
                  </p>
                  <p class="small-parag">
                      A phase 4, multicenter, randomized, double-blind,
                      vehicle-controlled study to evaluate the efficacy and safety of trifarotene<br />
                      cream in the treatment of moderate acne vulgaris with acne-induced post-inflammatory
                      hyperpigmentation. Following the<br /> baseline visit, all subjects returned to the clinic
                      for efficacy and safety evaluations at Weeks 1, 2, 4, 8, 12, 16, 20 and 24.<sup class="sup">20</sup>
                  </p>
                  <ul class="nav inner-nav-dual">
                    <li>
                      <router-link
                        :to="{ name: 'dual-study:leap-efficacy' }"
                        class="custom-link"
                        ><span>Efficacy</span></router-link
                      >
                    </li>
                    <li>
                      <router-link
                        class="custom-link"
                        :to="{ name: 'dual-study:leap-results' }"
                        ><span>Results</span></router-link
                      >
                    </li>
                    <li>
                      <router-link
                        class="custom-link"
                        :to="{ name: 'dual-study:leap-tolerability' }"
                        ><span>Tolerability</span></router-link
                      >
                    </li>
                  </ul>
              </div>
          </div>
          <div class="main-content">
              <div class="main-content__inner">
                  <div class="top-content">
                      <div class="left-content">
                          <h2>Key Inclusion Criteria</h2>
                          <p>• Aged 13–35 years </p>
                          <p>• Clinical diagnosis of acne vulgaris defined by: </p>
                          <div class="indented-content">
                              <p>• IGA score of 3 </p>
                              <p>• ≥20 inflammatory lesions and 25 noninflammatory lesions </p>
                          </div>
                          <p>• ODS hyperpigmentation scale of 4-6</p>
                          <p>• ≤1 nodules or cysts (≥1 cm)</p>
                          <p>• Any Fitzpatrick skin type I to VI</p>
                      </div>
                      <div class="right-content">
                          <h2>Key Endpoints</h2>
                          <h5>PIH</h5>
                          <p>• Absolute change from baseline in PIH overall disease severity<br />
                              <span>(ODS) scores at Week 24 &lpar;Primary Endpoint&rpar;</span></p>
                          <p>• Percent change from baseline in PIH ODS scores at Week 24, and <br />
                              <span>absolute and percent changes at Weeks 12, 16 and 20</span></p>
                          <p>• PAHPI (size, intensity, number) at Weeks 12, 16, 20 and 24</p>
                          <p>• Investigator’s Global Assessment (IGA) success, absolute and <br/>
                            <span>percent change from BL in total lesion count (sum of NIL and IL)</span>,
                            <br/>
                            <span>IL count, NIL count, at each post-BL visit</span>
                        </p>
                      </div>
                  </div>
                  <div class="bottom-content">
                      <img
                      class="graph"
                      src="@/assets/images/version-4/jpg-png/leap-efficacy/leap-efficacy-graph.png"
                      alt=""
                      />
                      <div class="compliance-container">
                          <img
                          class="compliance"
                          src="@/assets/images/version-4/jpg-png/leap-efficacy/leap-efficacy-day-night.png"
                          alt=""
                          />
                      </div>
                  </div>
              </div>
              <small>
                  <span>BL</span>=baseline;
                  <span>IGA</span>=Investigator’s Global Assessment;
                  <span>ODS</span>=overall disease severity.
              </small>
          </div>
      </div>
  </page>
</template>

<script>
import Page from '@/components/common/Page'

export default {
  name: 'leap-efficacy',
  components: {
    page: Page
  }
}
</script>
<style lang="scss" scoped>
.page.leap-efficacy {
  .title {
      color: $dark-blue;
      margin: 2px 0 16px 41px;
      position: relative;

      h1 {
          font-family: "AG Bold Condensed";
          font-size: 41px;
          line-height: 0.975;
          margin: 0 0 6px 0;
          text-transform: uppercase;
          sup {
              font-family: "AG Light Condensed";
              margin-left: -11px;
              font-size: 18px;
          }
      }

      p {
          font-size: 18px;
          margin: -2px 0 2px 0;
          line-height: 17px;
          font-family: "AG Medium";
          top: 42px;
          left: 192px;
          sup {
              font-size: 10px;
              font-family: "AG BQ Regular";
          }
      }

      .small-parag {
        font-family: "Sofia Pro";
        font-size: 9px;
        text-align: left;
        letter-spacing: 0px;
        color: $white;
        line-height: 1.4;
        margin-top: 5px;

        sup {
            font-size: 5px;

        }
      }
  }

  .main-content {
      border-top: 7px solid $light-green;
      color: $font-color-gray;
      background: $white;
      padding: 10px 49px 0px 42px;
      height: 438.5px;
      width: 990px;
      box-sizing: border-box;
      position: relative;

      .top-content {
          display: flex;
          flex-wrap: nowrap;
          gap: 24px;
          height: 192px;

          .left-content {
              background-color: $medium-violet;
              padding: 26px 59px 185px 19px;
              color: $white;
              width: 530px;
              line-height: 18.5px;

              h2 {
                  font-size: 12.5px;
                  font-family: "AG Bold Regular";
                  margin: 0;
              }

              p{
                  color: var(--unnamed-color-ffffff);
                  font-family: "AG BQ Regular";
                  text-align: left;
                  font-size: 12.5px;
                  letter-spacing: 0px;
                  margin: 0;
              }

              .indented-content {
                  margin-left: 14px;
              }
          }

          .right-content {
              background-color: $light-violet;
              color: $dark-blue;
              padding: 6px 12px 205px 15px;
              line-height: 11.8px;
              width: 361.5px;

              h2 {
                  font-family: "Sofia Pro Bold";
                  margin: 0 0 10px 0;
                  font-size: 17px;
              }

              h5 {
                margin-bottom: 4px;
                font-weight: 600;
              }

              p{
                  font-family: "AG Medium";
                  text-align: left;
                  font-size: 10px;
                  letter-spacing: 0px;
                  margin: 0;

                  span {
                        margin-left: 6px;
                  }
              }
          }
      }

      .bottom-content {
          display: flex;
          flex-wrap: nowrap;
          gap: 24px;
          .graph {
              width: 529px;
              margin-top: 18px;
              padding-bottom: 24px;
          }

          .compliance-container {
              display: flex;
              flex-direction: column;
              align-items: center;
              width: 340px;
              widows: 100%;
              margin-top: 80px;
              .compliance {
                  width: 250px;
                  height: auto;
              }
          }
      }

      small {
          font-size: 10px;
          width: 100%;
          font-family: "Sofia Pro";
          line-height: 12.9px;
          display: block;
          letter-spacing: -0.05px;
          color: $black;

          sup {
              font-size: 5px;
          }

          span {
              font-family: "Sofia Pro Bold";
          }
      }
  }
}

.page.dual-study {
  .inner-nav-dual {
    top: 90px;
  }
}
</style>
