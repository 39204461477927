<template>
  <page>
    <div class="content">
      <div class="title">
        <h1>MEANINGFUL RESULTS YOUR PATIENTS CAN SEE</h1>
        <div>
          <p>Representational photographs of the Phase 4 study subjects with severe<br />
            acne who applied AKLIEF<sup>®</sup> (trifarotene) Cream, 0.005% and took
            120 mg<br /> enteric coated oral doxycycline for 24 weeks.
          </p>
          <ul class="nav inner-nav-dual">
            <li>
              <router-link :to="{ name: 'dual-study:leap-efficacy' }"><span>Efficacy</span></router-link>
            </li>
            <li>
              <router-link class="custom-link"
                :to="{ name: 'dual-study:leap-results' }"><span>Results</span></router-link>
            </li>
            <li>
              <router-link class="custom-link"
                :to="{ name: 'dual-study:leap-tolerability' }"><span>Tolerability</span></router-link>
            </li>
          </ul>
        </div>
      </div>
      <div class="main-content">
        <div class="patient-img-containter">
          <div class="box">
            <div class="box__image">
              <div class="patient-state">
                <div class="censorship">
                  <div>
                    <p class="product">Aklief Cream</p>
                    <p class="right">Success</p>
                  </div>
                </div>
                <div class="patient-state-container">
                  <div class="patient-info">
                    <h6>Baseline</h6>
                    <p>ODS: 5 | PAHPI: 17</p>
                    <p>Lesion Count: 86 (IGA 3)</p>
                    <img class="patient-separator"
                      src="@/assets/images/version-4/jpg-png/leap-results/patient-separator.png" alt="" />
                  </div>
                  <div class="patinen-ph-container">
                    <img class="patinent-ph"
                      src="@/assets/images/version-4/jpg-png/leap-results/Female (Patient 1)/8606-010_Left-Face_BASELINE_CP_recalee_anonymisee.jpg"
                      alt="" />
                  </div>
                </div>
                <div class="patient-state-container">
                  <div class="patient-info">
                    <h6>Week 12</h6>
                    <p>ODS: 3 | PAHPI: 17</p>
                    <p>Lesion Count: 5 (IGA 1)</p>
                    <img class="patient-separator"
                      src="@/assets/images/version-4/jpg-png/leap-results/patient-separator.png" alt="" />
                  </div>
                  <div class="patinen-ph-container">
                    <img class="patinent-ph"
                      src="@/assets/images/version-4/jpg-png/leap-results/Female (Patient 1)/8606-010_Left-Face_W12_CP_recalee_anonymisee.jpg"
                      alt="" />
                  </div>
                </div>
                <div class="patient-state-container">
                  <div class="patient-info">
                    <h6>Week 24</h6>
                    <p>ODS: 2 | PAHPI: 13</p>
                    <p>Lesion Count: 3 (IGA 1)</p>
                  </div>
                  <div class="patinen-ph-container">
                    <img class="patinent-ph"
                      src="@/assets/images/version-4/jpg-png/leap-results/Female (Patient 1)/8606-010_Left-Face_W24_CP_recalee_anonymisee.jpg"
                      alt="" />
                  </div>
                </div>
              </div>
            </div>
            <a class="enlarge" href="#" @click="toggleModal('LeapResultPatient1')"></a>
          </div>
          <div class="box">
            <div class="box__image">
              <div class="patient-state">
                <div class="censorship">
                  <div>
                    <p class="product">Aklief Cream</p>
                    <p class="right">Success</p>
                  </div>
                </div>
                <div class="patient-state-container">
                  <div class="patient-info">
                    <h6>Baseline</h6>
                    <p>ODS: 5 | PAHPI: 9</p>
                    <p>Lesion Count: 93 (IGA 3)</p>
                    <img class="patient-separator"
                      src="@/assets/images/version-4/jpg-png/leap-results/patient-separator.png" alt="" />
                  </div>
                  <div class="patinen-ph-container">
                    <img class="patinent-ph"
                      src="@/assets/images/version-4/jpg-png/leap-results/Male (Patient 2)/8108-006_Left-Face_BASELINE_CP.jpg"
                      alt="" />
                  </div>
                </div>
                <div class="patient-state-container">
                  <div class="patient-info">
                    <h6>Week 12</h6>
                    <p>ODS: 1 | PAHPI: 6</p>
                    <p>Lesion Count: 2 (IGA 1)</p>
                    <img class="patient-separator"
                      src="@/assets/images/version-4/jpg-png/leap-results/patient-separator.png" alt="" />
                  </div>
                  <div class="patinen-ph-container">
                    <img class="patinent-ph"
                      src="@/assets/images/version-4/jpg-png/leap-results/Male (Patient 2)/8108-006_Left-Face_W12_CP.jpg"
                      alt="" />
                  </div>
                </div>
                <div class="patient-state-container">
                  <div class="patient-info">
                    <h6>Week 24</h6>
                    <p>ODS: 1 | PAHPI: Missing</p>
                    <p>Lesion Count: 0 (IGA 1)</p>
                  </div>
                  <div class="patinen-ph-container">
                    <img class="patinent-ph"
                      src="@/assets/images/version-4/jpg-png/leap-results/Male (Patient 2)/8108-006_Left-Face_W24_CP.jpg"
                      alt="" />
                  </div>
                </div>
              </div>
            </div>
            <a class="enlarge" href="#" @click="toggleModal('LeapResultPatient2')"></a>
          </div>
          <div class="box">
            <div class="box__image">
              <div class="patient-state">
                <div class="censorship">
                  <div>
                    <p class="product">Aklief Cream</p>
                    <p class="right">Success</p>
                  </div>
                </div>
                <div class="patient-state-container">
                  <div class="patient-info">
                    <h6>Baseline</h6>
                    <p>ODS: 4 | PAHPI: 20</p>
                    <p>Lesion Count: 75 (IGA 3)</p>
                    <img class="patient-separator"
                      src="@/assets/images/version-4/jpg-png/leap-results/patient-separator.png" alt="" />
                  </div>
                  <div class="patinen-ph-container">
                    <img class="patinent-ph"
                      src="@/assets/images/version-4/jpg-png/leap-results/Female (Patient 3)/9953-001_Right Face_BASELINE_CP_recalee_anonymisee.jpg"
                      alt="" />
                  </div>
                </div>
                <div class="patient-state-container">
                  <div class="patient-info">
                    <h6>Week 12</h6>
                    <p>ODS: 3 | PAHPI: 20</p>
                    <p>Lesion Count: 25 (IGA 1)</p>
                    <img class="patient-separator"
                      src="@/assets/images/version-4/jpg-png/leap-results/patient-separator.png" alt="" />
                  </div>
                  <div class="patinen-ph-container">
                    <img class="patinent-ph"
                      src="@/assets/images/version-4/jpg-png/leap-results/Female (Patient 3)/9953-001_Right Face_Week 12_CP_recalee_anonymisee.jpg"
                      alt="" />
                  </div>
                </div>
                <div class="patient-state-container">
                  <div class="patient-info">
                    <h6>Week 24</h6>
                    <p>ODS: 2 | PAHPI: 17</p>
                    <p>Lesion Count: 17 (IGA 1)</p>
                  </div>
                  <div class="patinen-ph-container">
                    <img class="patinent-ph"
                      src="@/assets/images/version-4/jpg-png/leap-results/Female (Patient 3)/9953-001_Right Face_Week 24_CP_recalee_anonymisee.jpg"
                      alt="" />
                  </div>
                </div>
              </div>
            </div>
            <a class="enlarge" href="#" @click="toggleModal('LeapResultPatient3')"></a>
          </div>
        </div>
        <div class="study-design-container">
          <a href="#" class="button" @click="toggleModal('LeapStudyDesign')">
            <p>Study Design</p>
          </a>
        </div>
      </div>
    </div>
  </page>
</template>

<script>
import Page from '@/components/common/Page'

export default {
  name: 'leap-results',
  components: {
    page: Page
  }
}
</script>
<style lang="scss" scoped>
.page.leap-results-patients {

  .title {
    color: $dark-blue;
    margin: 0 0 43px 41px;
    position: relative;

    div {
      ul {
        top: 92px;
      }
    }

    h1 {
      letter-spacing: -0.5px;
      font-family: "AG Medium Condensed";
      font-size: 40px;
      line-height: 0.975;
      margin: 0 0 5px 0;
      text-transform: uppercase;
    }

    p {
      margin: 0;
      line-height: 17px;
      font-family: "AG Medium";
      display: block;
    }

    sup {
      font-size: 9px;
      vertical-align: text-top;
    }
  }

  .main-content {
    border-top: 7px solid $light-green;
    color: $font-color-gray;
    background: $white;
    padding: 20px 49px 0px 42px;
    height: 438.5px;
    width: 990px;
    box-sizing: border-box;

    .patient-img-containter {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      .box {
        display: flex;
        position: relative;
        align-items: center;
        justify-content: space-between;
        box-sizing: border-box;

        .patient-state {
          position: relative;
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;

          .censorship {
            position: absolute;
            background-color: $font-color-gray;
            width: 100%;
            top: 74px;
            height: 25.5px;

            div {
              display: flex;
              justify-content: space-between;
              margin: 0 32.5px 0 16.5px;
              align-items: center;
              height: 100%;

              p {
                text-transform: uppercase;
                color: $white;
                font-family: "AG Bold Condensed";
                font-size: 13.5px;
                line-height: 15.5px;
                margin: 0;
                &.product {
                font-style: italic;
                }
              }

              .right {
                margin: 0;
                font-family: "AG Bold Regular";
                font-size: 10px;
                line-height: 20px;
              }
            }
          }

          .patient-state-container {

            .patient-info {
              display: flex;
              flex-direction: column;
              justify-content: center;
              position: relative;
              background-color: $light-gray;
              height: 38.5px;

              .patient-separator {
                margin-right: -3px;
                right: 0;
                position: absolute;
                width: auto;
                height: 32px;
                z-index: 999;
              }

              h6 {
                text-transform: uppercase;
                color: $modal-title-color;
                font-family: "AG Bold Regular";
                font-size: 8px;
                text-align: center;
                line-height: 9.5px;
                margin: 0;
              }

              p {
                color: $font-color-gray;
                font-family: "AG BQ Regular";
                font-size: 8px;
                line-height: 9.5px;
                text-align: center;
                margin: 0;
              }
            }

            .patinen-ph-container {

              .patinent-ph {
                height: 118.5px;
                object-fit: cover;
                width: 116px;
              }
            }
          }
        }

        img {
          width: 349px;
          display: block;
        }

        &:nth-child(1) {
          margin-right: 95px;
        }

        &:nth-child(3) {
          margin-top: 37px;
        }
      }
    }

    small {
      font-size: 10px;
      letter-spacing: -0.07px;
    }

    .study-design-container {
      width: 100%;
      display: flex;
      justify-content: flex-end;

      .button {
        padding: 9.5px 15px;
        font-family: "AG Medium";
        -webkit-transform: skew(-15deg);
        transform: skew(-15deg);
        font-size: 14px;
        text-transform: uppercase;
        text-decoration: none;
        display: inline-block;
        color: $white;
        background-color: $dark-blue;

        p {
          margin: 0;
          -webkit-transform: skew(15deg);
          transform: skew(15deg);
        }
      }
    }

    .enlarge {
      width: 29px;
      height: 29px;
      position: absolute;
      top: -14px;
      right: -14px;
      z-index: 99;
      background: url("~@/assets/images/version-4/svg/enlarge.svg") no-repeat;
      background-size: cover;
      display: block;
    }
  }

}
</style>
