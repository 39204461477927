<template>
  <page>
    <div class="content">
      <div class="title">
        <ul class="nav inner-nav-dual">
            <li>
              <router-link
                :to="{ name: 'dual-study:efficacy' }"
                ><span>Efficacy</span></router-link
              >
            </li>
            <li>
            <router-link
              class="custom-link"
              :to="{ name: 'dual-study:dual-study-iga' }"
              ><span>IGA success</span></router-link
            >
            </li>
            <li>
              <router-link
                class="custom-link"
                :to="{ name: 'dual-study:dual-study-results' }"
                ><span>Results</span></router-link
              >
            </li>
            <li>
              <router-link
                class="custom-link"
                :to="{ name: 'dual-study:dual-study-tolerability' }"
                ><span>Tolerability</span></router-link
              >
            </li>
          </ul>
        <h1>
          DOUBLE THE IGA SUCCESS RATE AT<br/> WEEK 12
          VS VEHICLE. <sup>19</sup>
        </h1>
        <p>
          Phase 4 Study of AKLIEF<sup>&reg;</sup> (trifarotene) Cream, 0.005%<br/>
          with 120 mg
          enteric coated oral doxycycline in a<br/> population with severe acne.<sup
            >19</sup
          >
        </p>
      </div>
      <div class="main-content">
        <div class="main-content__inner">
          <h2>
            Proportion of Subjects Achieving IGA Success (ITT)<sup>19</sup>
          </h2>
          <img
            class="chart"
            src="@/assets/images/version-4/svg/chart-10.svg"
            alt=""
          />
        </div>
        <img
          class="stat"
          src="@/assets/images/version-4/jpg-png/stat-4.png"
          alt=""
        />
        <small>
          IGA=Investigator’s Global Assessment; ITT=intent to treat; MI=multiple
          imputation.<br />
          Figure is based on observed case analysis. Results of statistical
          analysis at Week 12 are included. IGA Success is defined as IGA score
          0 (clear) or 1 (almost clear), and at least a 2-grade improvement from
          baseline. (31.7% vs 15.8%; P=0.0107; MI).<sup>19</sup>
        </small>
      </div>
    </div>
  </page>
</template>

<script>
import Page from '@/components/common/Page'

export default {
  name: 'dual-study-iga',
  components: {
    page: Page
  }
}
</script>
<style lang="scss" scoped>
.page.dual-study-iga {
  .title {
    color: $dark-blue;
    margin: -11px 0 12px 41px;
    ul{
      top: 143px;
    }
    h1 {
      font-family: "AG Bold Condensed";
      font-size: 41px;
      line-height: 0.975;
      margin: 0 0 3px 0;
      text-transform: uppercase;
      sup {
        font-family: "AG Light Condensed";
        margin-left: -11px;
        font-size: 18px;
      }
    }
    p {
      margin: -2px 0 0 0;
      font-family: "AG Medium";
      sup {
        font-size: 10px;
        font-family: "AG BQ Regular";
      }
    }
  }
  .main-content {
    border-top: 7px solid $light-green;
    color: $font-color-gray;
    background: $white;
    padding: 20px 49px 0px 42px;
    height: 438px;
    width: 990px;
    box-sizing: border-box;
    position: relative;
    .chart {
      width: 538px;
      margin-left: 3px;
      margin-bottom: 20px;
    }
    .stat {
      position: absolute;
      top: 0;
      right: 0;
      width: 236.1px;
    }
    h2 {
      color: $modal-title-color;
      font-size: 21px;
      margin-top: 0px;
      margin-bottom: 32px;
      letter-spacing: -0.25px;
      sup {
        font-size: 12px;
        font-family: "AG BQ Regular";
      }
    }
    small {
      font-size: 10px;
      width: 635px;
      display: block;
      letter-spacing: -0.05px;
      margin-left: -0.5px;
      sup {
        font-size: 5px;
      }
    }
  }
}
</style>
