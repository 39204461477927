<template>
  <div class="efficacy-trunk-content">
    <!-- Image -->
    <div class="image">
      <img src="~@/assets/images/version-4/jpg-png/stat-3.png" alt="" />
    </div>

    <!--  Chart -->
    <div class="chart">
      <div class="chart__title">
        Significant Improvement in Total Truncal Lesions at Week 12 vs
        Vehicle<sup>18</sup>
      </div>
      <div class="chart__image">
        <img src="~@/assets/images/version-4/svg/chart-4.svg" alt="" />
      </div>
    </div>

    <!-- Footnote -->
    <div class="footnote">
      PIVOTAL Primary endpoints: 1) Absolute change in truncal inflammatory and
      non-inflammatory lesion counts from baseline to Week 12; 2) Investigator's
      Global
      <br />Assessment (IGA) success rate on the trunk.<sup>1</sup>
    </div>
  </div>
</template>

<script>
export default {
  name: '',
  components: {},
  data: function () {
    return {
      tabs: {
        visible: 'inflammatory'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.image {
  position: absolute;
  width: 224px;
  left: 44px;
}

.chart {
  position: absolute;
  width: 670px;
  right: 0;
  &__title {
    color: $dark-blue;
    margin: 33px 0 19px 3px;
    font-size: 19.5px;
    font-family: "AG Medium";
    letter-spacing: -0.5px;
    // sup{
    //   font-family: 'AG Light';
    // }
  }
  &__image {
    padding: 2px 73px 0 3px;
  }
}

.footnote {
  font-family: "Myriad Pro";
  position: absolute;
  top: 486px;
  color: $font-color-gray;
  font-size: 11px;
  left: 44px;
  letter-spacing: -0.1px;
}
</style>
