<template>
    <page>
        <div class="content">
            <div class="title">
                <h1>
                    START STUDY DESIGN
                </h1>
                <div>
                    <p>
                        Phase 4 study of reduction of acne scarring risk subsequent to
                        acne topical<br /> treatment with AKLIEF<sup>&reg;</sup> (trifarotene) Cream, 0.005%.
                    </p>
                    <p class="small-parag">
                        A phase 4, 24-week multicenter, randomized, double-blind,
                        vehicle-controlled  clinical study of the risk of atrophic acne scar<br />
                        formation during treatment of acne vulgaris with trifarotene cream 0.005%,
                        using a split-face design for intra-individual<br /> comparison (right half-face
                        versus left half-face), in subjects with moderate (IGA 3) to severe (IGA 4)
                        acne vulgaris, a minimum<br /> of 10 atrophic acne scars at baseline, and a
                        symmetrical distribution of acne and acne scars on each half-face at baseline.<sup>2</sup>
                    </p>
                    <ul class="nav inner-nav-dual">
                        <li>
                        <router-link
                            :to="{ name: 'dual-study:start-efficacy' }"
                            ><span>Efficacy</span></router-link
                        >
                        </li>
                        <li>
                        <router-link
                            class="custom-link"
                            :to="{ name: 'dual-study:start-results' }"
                            ><span>Results</span></router-link
                        >
                        </li>
                        <li>
                        <router-link
                            class="custom-link"
                            :to="{ name: 'dual-study:start-tolerability' }"
                            ><span>Tolerability</span></router-link
                        >
                        </li>
                    </ul>
                </div>
            </div>
            <div class="main-content">
                <div class="main-content__inner">
                    <div class="top-content">
                        <div class="left-content">
                            <h2>Key Inclusion Criteria</h2>
                            <p>• Aged 17–35 years </p>
                            <p>• Clinical diagnosis of acne vulgaris defined by: </p>
                            <div class="indented-content">
                                <p>• IGA score of 3 or 4 with same score on both sides of the face </p>
                                <p>• ≥20 ILs with ≥10 on each side</p>
                                <p>• ≤2 nodules (≥1 cm in diameter)</p>
                                <p>• ≥10 atrophic acne scars in total (>2 mm) </p>
                            </div>
                            <p>• Symmetrical number of ILs and NILs and atrophic acne scars (≥4 per half-face)</p>
                        </div>
                        <div class="right-content">
                            <h2>Key Endpoints</h2>
                            <p>• Absolute change from baseline in total atrophic acne scar count<br />
                                <span>per half-face at Week 24 (primary endpoint) </span></p>
                            <p>• Absolute change in total atrophic acne scar count per half-face<br />
                                <span>from baseline to Week 20</span> </p>
                            <p>• Total and percentage change in total atrophic acne scar count from<br />
                                <span>baseline per half-face </span></p>
                            <p>• Change in SGA score from baseline and SGA success rate* per<br />
                                <span>half-face</span> </p>
                            <p>• TEAEs</p>
                        </div>
                    </div>
                    <div class="bottom-content">
                        <img
                        class="graph"
                        src="@/assets/images/version-4/svg/start-efficacy/start-efficacy-graph.svg"
                        alt=""
                        />
                        <div class="compliance-container">
                            <img
                            class="compliance"
                            src="@/assets/images/version-4/jpg-png/start-efficacy/start-efficacyimage-compliance.png"
                            alt=""
                            />
                            <small>Video assessment or recording of every application to assess compliance</small>
                        </div>
                    </div>
                </div>
                <small>
                    *Success defined as an IGA or SGA score of 1 (almost clear) or 0 (clear) and at least a 2-grade
                    improvement from baseline to Week 24.<br /><span>IGA</span>=Investigator’s Global Assessment;
                    <span>IL</span>=inflammatory lesion; <span>NIL</span>=noninflammatory lesion; <span>PRO</span>
                    =patient-reported outcome; <span>QoL</span>=quality of life;<br /> <span>SCARS</span>=self-assessment
                    of clinical acne-related scars; <span>SGA</span>=scars global assessment; <span>TEAE</span>=treatment-emergent
                    adverse event.
                </small>
            </div>
        </div>
    </page>
</template>

<script>
import Page from '@/components/common/Page'

export default {
  name: 'start-efficacy',
  components: {
    page: Page
  }
}
</script>
<style lang="scss" scoped>
.page.start-efficacy {
    .title {
        color: $dark-blue;
        margin: 2px 0 10px 41px;
        position: relative;

        h1 {
            font-family: "AG Bold Condensed";
            font-size: 41px;
            line-height: 0.975;
            margin: 0 0 6px 0;
            text-transform: uppercase;
            sup {
                font-family: "AG Light Condensed";
                margin-left: -11px;
                font-size: 18px;
            }
        }

        p {
            font-size: 16px;
            margin: -2px 0 2px 0;
            line-height: 14px;
            font-family: "AG Medium";
            top: 42px;
            left: 192px;
            sup {
                font-size: 10px;
                font-family: "AG BQ Regular";
            }
        }

        .small-parag {
            font-family: "Sofia Pro";
            font-size: 9px;
            text-align: left;
            letter-spacing: 0px;
            color: $white;
            line-height: 1.4;
            margin-top: 5px;

            sup {
                font-family: "AG Light Condensed";
                font-size: 5px;

            }
        }
    }

    .main-content {
        border-top: 7px solid $light-green;
        color: $font-color-gray;
        background: $white;
        padding: 10px 49px 0px 42px;
        height: 438.5px;
        width: 990px;
        box-sizing: border-box;
        position: relative;

        .top-content {
            display: flex;
            flex-wrap: nowrap;
            gap: 24px;
            height: 192px;

            .left-content {
                background-color: $medium-violet;
                padding: 26px 59px 0 19px;
                color: $white;
                width: 530px;
                line-height: 18.5px;

                h2 {
                    font-size: 12.5px;
                    font-family: "AG Bold Regular";
                    margin: 0;
                }

                p{
                    color: var(--unnamed-color-ffffff);
                    font-family: "AG BQ Regular";
                    text-align: left;
                    font-size: 12.5px;
                    letter-spacing: 0px;
                    margin: 0;
                }

                .indented-content {
                    margin-left: 14px;
                }
            }

            .right-content {
                background-color: $light-violet;
                color: $dark-blue;
                padding: 6px 12px 0 15px;
                line-height: 11.8px;
                width: 361.5px;

                h2 {
                    font-family: "Sofia Pro Bold";
                    margin: 0 0 10px 0;
                    font-size: 17px;
                }

                p{
                    font-family: "AG Medium";
                    text-align: left;
                    font-size: 10px;
                    letter-spacing: 0px;
                    margin: 0;

                    span {
                        margin-left: 6px;
                    }
                }
            }
        }

        .bottom-content {
            display: flex;
            flex-wrap: nowrap;
            gap: 24px;
            .graph {
                width: 549px;
                margin-top: -22px;
            }

            .compliance-container {
                display: flex;
                flex-direction: column;
                align-items: center;
                width: 339.5px;
                margin-top: 15px;

                small {
                    margin-top: 10px;
                    width: 161px;
                    text-align: center;
                    font-size: 7px;
                    font-family: "Sofia Pro";
                    letter-spacing: 0px;
                    line-height: 12.9px;
                    color: $font-color-medium-gray;

                }
                .compliance {
                    width: 206.5px;
                    height: 108px;
                }
            }
        }

        small {
            font-size: 10px;
            width: 100%;
            font-family: "Sofia Pro";
            line-height: 12.9px;
            display: block;
            letter-spacing: -0.05px;
            color: $black;

            sup {
                font-size: 5px;
            }

            span {
                font-family: "Sofia Pro Bold";
            }
        }
    }
}

.page.dual-study {
      .inner-nav-dual {
    top: 90px;
  }
}
</style>
