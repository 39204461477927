<template>
  <page>
    <div class="content">
      <div class="title">
        <h1>MEANINGFUL RESULTS YOUR PATIENTS CAN SEE.</h1>
        <div>
          <p>
            Representational photographs of the Phase 4 study subjects with severe
            acne who applied<br />
            AKLIEF<sup>&reg;</sup> (trifarotene) Cream, 0.005% and took 120 mg
            enteric coated oral doxycycline for 12 weeks.
          </p>
          <ul class="nav inner-nav-dual">
            <li>
              <router-link
                :to="{ name: 'dual-study:efficacy' }"
                :class="{
                  'router-link-active':
                  this.$route.name === 'dual-study'
                }"
                ><span>Efficacy</span></router-link
              >
            </li>
            <li>
            <router-link
              class="custom-link"
              :to="{ name: 'dual-study:dual-study-iga' }"
              ><span>IGA success</span></router-link
            >
            </li>
            <li>
              <router-link
                class="custom-link"
                :to="{ name: 'dual-study:dual-study-results' }"
                ><span>Results</span></router-link
              >
            </li>
            <li>
              <router-link
                class="custom-link"
                :to="{ name: 'dual-study:dual-study-tolerability' }"
                ><span>Tolerability</span></router-link
              >
            </li>
          </ul>
        </div>
      </div>
      <div class="main-content">
        <div class="d-flex column one">
          <div class="box">
            <div class="box__content">
              <h3>Patient 1</h3>
              <p>
                Baseline: IGA 4 <br />Subject: 8433-003<br />Treatment failure
              </p>
            </div>
            <div class="box__image">
              <img
                src="@/assets/images/version-4/jpg-png/patient-3-sm.jpg"
                alt=""
              />
            </div>
            <a
              class="enlarge"
              href="#"
              @click="toggleModal('DualStudyPatient1')"
            ></a>
          </div>
          <div class="box">
            <div class="box__content">
              <h3>Patient 2</h3>
              <p>
                Baseline: IGA 4 <br />Subject: 8546-002<br />Treatment success
              </p>
            </div>
            <div class="box__image">
              <img
                src="@/assets/images/version-4/jpg-png/patient-2-sm.jpg"
                alt=""
              />
            </div>
            <a
              class="enlarge"
              href="#"
              @click="toggleModal('DualStudyPatient2')"
            ></a>
          </div>
        </div>
        <div class="d-flex column two">
          <div class="box">
            <div class="box__content">
              <h3>Patient 3</h3>
              <p>
                Baseline: IGA 4 <br />Subject: 8108-007<br />Treatment success
              </p>
            </div>
            <div class="box__image">
              <img
                src="@/assets/images/version-4/jpg-png/patient-1-sm.jpg"
                alt=""
              />
            </div>
            <a
              class="enlarge"
              href="#"
              @click="toggleModal('ResultsPatientThree')"
            ></a>
          </div>
        </div>
        <small>IGA=Investigator's Global Assessment.</small>
      </div>
    </div>
  </page>
</template>

<script>
import Page from '@/components/common/Page'

export default {
  name: 'dual-study-results',
  components: {
    page: Page
  }
}
</script>
<style lang="scss" scoped>
.page.dual-study-results {
  .title {
    color: $dark-blue;
    margin: -11px 0 74px 41px;
    div{
      ul{
        top: 143px;
      }
    }
    h1 {
      font-family: "AG Bold Condensed";
      font-size: 41px;
      line-height: 0.975;
      margin: 0 0 4px 0;
      text-transform: uppercase;
    }
    p {
      margin: 10px 0 0 0;
      line-height: 1.1;
      font-family: "AG Medium";
      display: block;
    }
    sup {
      font-size: 9px;
    }
  }

  .main-content {
    border-top: 7px solid $light-green;
    color: $font-color-gray;
    background: $white;
    padding: 25px 49px 0px 80px;
    height: 438.5px;
    width: 990px;
    box-sizing: border-box;
    margin-top: -10px;
    .column {
      .box {
        width: 380px;
        display: flex;
        position: relative;
        background-color: $light-gray;
        align-items: center;
        justify-content: space-between;
        box-sizing: border-box;
        h3 {
          font-size: 13px;
          margin: -4px 0 6px 0;
          letter-spacing: 0.2px;
          color: $modal-title-color;
          text-transform: uppercase;
        }
        p {
          font-size: 12px;
          margin: 0;
          line-height: 1.3;
        }
        img {
          width: 257px;
          display: block;
        }
        &:nth-child(1) {
          margin-right: 26px;
        }
        &:nth-child(2) {
          margin-top: 2px;
        }
        &__content {
          padding-left: 8px;
          box-sizing: border-box;
        }
      }
      &.one {
        margin-bottom: 30px;
      }
      &.two {
        justify-content: center;
        margin-bottom: 34px;
        .box {
          margin-left: -6px;
        }
      }
    }

    small {
      font-size: 10px;
      letter-spacing: -0.07px;
    }
    .enlarge {
      width: 29px;
      height: 29px;
      position: absolute;
      top: -14px;
      right: -14px;
      z-index: 99;
      background: url("~@/assets/images/version-4/svg/enlarge.svg") no-repeat;
      background-size: cover;
      display: block;
    }
  }
}
</style>
