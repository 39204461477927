<template>
  <page>
    <div class="content">
      <!-- Title -->
      <div class="title">
        <h1>
          A SIMPLE DAILY ROUTINE. <br />
          CLEANSE. TREAT. MOISTURIZE. PROTECT.
        </h1>
      </div>

      <div class="content_copy">
        <tabs :class="'horizontal-layout'">
          <div slot="content">
            <div class="tabs__content">
              <how-to-use></how-to-use>
            </div>
          </div>
        </tabs>
      </div>
    </div>
  </page>
</template>

<script>
import Page from '@/components/common/Page'
import Tabs from '@/components/common/Tabs'
import TolerabilityHowToUse from '@/components/tabs/HowToUse.vue'

export default {
  name: 'tolerability',
  components: {
    page: Page,
    tabs: Tabs,
    'how-to-use': TolerabilityHowToUse
  },
  data: function () {
    return {
      title: 'RAPID RESULTS* AND POWERFUL <br>EFFICACY VS VEHICLE.<sup>2</sup>',
      modals: {
        studyDesign: {
          isVisible: false
        }
      }
    }
  }
}
</script>

<style lang="scss">
.page.tolerability.how-to-use {
  background-image: url("~@/assets/images/version-4/jpg-png/bg-screen.jpg");
  background-repeat: no-repeat;
  background-position: center 0;
  background-size: 100%;
  color: #fff;
  img {
    max-width: 100%;
  }
  sup {
    font-size: 60%;
  }
  .title {
    h1 {
      color: $dark-blue;
      font-family: "AG Bold Condensed";
      font-size: 41px;
      line-height: 0.975;
      margin: 0px 0 18px 20px;
      letter-spacing: 0px;
    }
    sup {
      font-size: 50%;
      font-family: "AG Regular Condensed";
      position: relative;
      top: -1px;
      left: -1px;
    }
  }
  .content {
    .efficacy-face-content {
      .tabs__buttons {
        a {
          font-weight: 100;
          font-family: "Sofia Pro Medium";
          line-height: 48.2px;
        }
      }
    }
    .tabs.horizontal-layout {
      top: 152px;
      > div > .tabs__content {
        height: 484px;
      }
    }
  }
}
// Fix tab buttons position
.tabs.horizontal-layout > div > .tabs__buttons {
  padding: 0 17px 0 0;
}
</style>
