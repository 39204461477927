<template>
  <ul class="nav">
    <li class="first">
      <router-link :to="{ name: 'acne-burden' }"
        ><span>Acne Burden</span></router-link
      >
    </li>
    <li>
      <router-link
        class="custom-link invisible-link"
        :to="{ name: 'patient-profiles:teen' }"
        ><span>Patient Profiles</span></router-link
      >
      <router-link class="custom-link router" :to="{ name: 'patient-profiles' }"
        ><span>Patient Profiles</span></router-link
      >
      <ul class="inner-nav">
        <li>
          <router-link
            :to="{ name: 'patient-profiles:teen' }"
            :class="{
              'router-link-active':
                ['patient-profiles'].indexOf(this.$route.name) > -1
            }"
            ><span>Teen</span></router-link
          >
        </li>
        <li>
          <router-link
            class="custom-link"
            :to="{ name: 'patient-profiles:skin-of-color' }"
            ><span>Skin of Color</span></router-link
          >
        </li>
      </ul>
    </li>
    <li>
      <router-link
        class="custom-link invisible-link"
        :to="{ name: 'innovation:galderma-legacy' }"
        ><span>Innovation</span></router-link
      >
      <router-link class="custom-link" :to="{ name: 'innovation' }"
        ><span>Innovation</span></router-link
      >
      <ul class="inner-nav inner-nav-innovation">
        <li>
          <router-link
            :to="{ name: 'innovation:galderma-legacy' }"
            :class="{
              'router-link-active':
                ['innovation'].indexOf(this.$route.name) > -1
            }"
            ><span>GALDERMA LEGACY</span></router-link
          >
        </li>
        <li>
          <router-link class="custom-link" :to="{ name: 'innovation:rar-y' }"
            ><span>RAR-<small class="light">&#947;</small></span></router-link
          >
        </li>
        <li>
          <router-link
            class="custom-link"
            :to="{ name: 'innovation:cream-vehicle' }"
            ><span
              >AKLIEF <small class="sentence-case">Cream</small> VEHICLE</span
            ></router-link
          >
        </li>
      </ul>
    </li>
    <li>
      <router-link
        class="custom-link invisible-link"
        :to="{ name: 'efficacy:ps1' }"
        ><span>Efficacy & Results</span></router-link
      >
      <router-link class="custom-link" :to="{ name: 'efficacy' }"
        ><span>Efficacy &amp; Results</span></router-link
      >
      <ul class="inner-nav">
        <li>
          <router-link
            :to="{ name: 'efficacy:ps1' }"
            :class="{
              'router-link-active':
                ['efficacy', 'efficacy:ps1trunk'].indexOf(this.$route.name) > -1
            }"
            ><span>Pivotal Study 1</span></router-link
          >
        </li>
        <li>
          <router-link
            class="custom-link"
            :to="{ name: 'efficacy:ps2' }"
            :class="{
              'router-link-active':
                ['efficacy:ps2trunk'].indexOf(this.$route.name) > -1
            }"
            ><span>Pivotal Study 2</span></router-link
          >
        </li>
        <li>
          <router-link
            class="custom-link"
            :to="{ name: 'efficacy:see-results' }"
            :class="{
              'router-link-exact-active': this.$route.name == ''
            }"
            ><span>See results</span></router-link
          >
        </li>
      </ul>
    </li>
    <li>
      <router-link
        class="custom-link invisible-link"
        :to="{ name: 'tolerability:week12' }"
        ><span>Tolerability</span></router-link
      >
      <router-link class="custom-link" :to="{ name: 'tolerability' }"
        ><span>Tolerability</span></router-link
      >
      <ul class="inner-nav">
        <li>
          <router-link
            :to="{ name: 'tolerability:week12' }"
            :class="{
              'router-link-active':
                ['tolerability', 'tolerability:week12trunk'].indexOf(
                  this.$route.name
                ) > -1
            }"
            ><span>Week 12</span></router-link
          >
        </li>
        <li>
          <router-link
            class="custom-link"
            :to="{ name: 'tolerability:week52' }"
            :class="{
              'router-link-active':
                ['tolerability:week52trunk'].indexOf(this.$route.name) > -1
            }"
            ><span>Week 52</span></router-link
          >
        </li>
        <li>
          <router-link
            class="custom-link"
            :to="{ name: 'tolerability:how-to-use' }"
            ><span>How to use</span></router-link
          >
        </li>
      </ul>
    </li>
    <li>
      <router-link
        class="custom-link invisible-link"
        :to="{ name: 'dual-study:efficacy' }"
        ><span>Studies</span></router-link
      >
      <router-link class="custom-link" :to="{ name: 'dual-study' }"
        ><span>Studies</span></router-link
      >
      <ul class="inner-nav inner-nav-dual-study">
        <li>
          <router-link
            :to="{ name: 'dual-study:efficacy' }"
            :class="{
              'router-link-active':
                ['dual-study:efficacy', 'dual-study:dual-study-iga', 'dual-study:dual-study-results', 'dual-study:dual-study-tolerability'].indexOf(this.$route.name) > -1
            }"
            ><span>Dual</span></router-link
          >
        </li>
        <li>
          <router-link
            class="custom-link"
            :to="{ name: 'dual-study:start-efficacy' }"
            :class="{
              'router-link-active':
                ['dual-study:start-efficacy', 'dual-study:start-results', 'dual-study:start-results-patients', 'dual-study:start-tolerability'].indexOf(this.$route.name) > -1
              }"
            ><span>Start</span></router-link
          >
        </li>
        <li>
          <!-- Change results for efficacy -->
          <router-link
            class="custom-link"
            :to="{ name: 'dual-study:leap-efficacy' }"
            :class="{
              'router-link-active':
                ['dual-study:leap-efficacy', 'dual-study:leap-results', 'dual-study:leap-results-patients','dual-study:leap-tolerability'].indexOf(this.$route.name) > -1
              }"
            ><span>Leap</span></router-link
          >
        </li>
      </ul>
    </li>
    <li>
      <router-link class="custom-link" :to="{ name: 'patient-qol' }"
        ><span>Patient QOL</span></router-link
      >
    </li>
    <li class="last">
      <router-link :to="{ name: 'summary' }"><span>Summary</span></router-link>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'MainNavigation'
}
</script>

<style lang="scss">
ul.nav {
  display: flex;
  background: $dark-blue;
  padding: 0;
  list-style: none;
  height: 43px;
  align-items: initial;
  margin-bottom: 0;

  > li {
    flex-basis: auto;
    height: 100%;
    border-right: 1px solid $dark-blue;
    list-style-type: none;
    position: relative;
    display: inline-block;
    transform: skew(-13.3deg);
    position: relative;
    &:after {
      position: absolute;
      content: "";
      width: 1px;
      height: 30%;
      top: 15px;
      right: 1px;
      background: $light-green;
    }

    a.custom-link.invisible-link {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      opacity: 0;
      z-index: 5;
    }

    a.custom-link.router-link-active {
      margin-left: -3px;
    }

    &:nth-child(1) > a {
      padding: 0 16px 0 18px;
      &.custom-link.router-link-active {
        padding-left: 21px;
      }
    }
    &:nth-child(2) > a {
      padding: 0 15px 0 11px;
      letter-spacing: -0.95px;
      &.custom-link.router-link-active {
        padding-left: 14px;
      }
    }
    &:nth-child(3) > a {
      padding: 0 16px 0 12px;
      &.custom-link.router-link-active {
        padding-left: 15px;
      }
    }
    &:nth-child(4) > a {
      padding: 0 15px 0 11px;
      letter-spacing: -1.15px;
      &.custom-link.router-link-active {
        padding-left: 14px;
      }
    }
    &:nth-child(5) > a {
      padding: 0 16.5px 0 12px;
      letter-spacing: -0.9px;
      &.custom-link.router-link-active {
        padding-left: 15px;
      }
    }
    &:nth-child(6) > a {
      padding: 0 17px 0 14px;
      &.custom-link.router-link-active {
        padding-left: 17px;
      }
    }
    &:nth-child(7) > a {
      padding: 0 13px 0 13px;
      &.custom-link.router-link-active {
        padding-left: 16px;
      }
    }
    &:nth-child(8) a {
      padding: 0 5px 0 11px;
      &.custom-link.router-link-active {
        padding-left: 14px;
      }
    }

    .inner-nav {
      display: none;
      background: transparent;
      position: absolute;
      bottom: 51.25px;
      height: 34.5px;
      width: auto;
      align-items: center;
      justify-content: baseline;
      -webkit-box-align: baseline;
      margin: 0;
      padding: 0;
      > li {
        position: relative;
        display: flex;
        align-items: center;
        transform: skew(-0.1deg);

        a {
          color: $dark-blue;
          padding: 0 16px;
          height: 34.5px;
          background: none;
          letter-spacing: -1px;
          text-align: left;
          &:after {
            position: absolute;
            content: "";
            width: 1px;
            height: 44%;
            top: 9px;
            right: -1px;
            background: $light-green;
          }
          span {
            transform: skew(13.3deg);
            display: block;
            white-space: pre;
            margin-top: 1px;
          }
        }
        a.router-link-active {
          background: $light-green;
          text-align: left;
          text-decoration: none;
          color: $dark-blue;
          padding: 0 16px;
          font-size: 14px;
          letter-spacing: -1px;
          margin-left: 0;
          height: 34.5px;
          &:after {
            background: transparent;
          }
        }
      }
      > li {
        &:last-child {
          a:after {
            background: transparent;
          }
          &:after {
            display: none;
          }
        }
      }
    }

    &:last-child {
      border-right: 0;
    }

    a {
      color: $white;
      text-transform: uppercase;
      display: flex;
      height: 100%;
      align-self: initial;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      letter-spacing: -1px;
      text-decoration: none;

      span {
        transform: skew(13.3deg);
        display: block;
      }

      &:hover {
        text-decoration: none;
      }
    }

    .router-link-active {
      background-color: $light-green;
      text-decoration: none;
      color: $dark-blue;
    }
    .custom-link.router-link-active {
      // &:before {
      //   display: block;
      //   content: "";
      //   transform: skew(-1deg);
      //   position: absolute;
      //   height: 100%;
      //   width: 10px;
      //   left: -3.5px;
      //   background: $light-green;
      //   bottom: 0;
      // }
    }
  }
  .first {
    .router-link-active:before {
      display: block;
      content: "";
      transform: skew(13.3deg);
      position: absolute;
      height: 100%;
      width: 10px;
      left: 0;
      background: $light-green;
    }
  }

  .last {
    a.router-link-exact-active {
      margin-left: -3px;
      padding-left: 14px;
    }
    &:after {
      display: none;
    }
    .router-link-active:after {
      display: block;
      content: "";
      transform: skew(13.3deg);
      position: absolute;
      height: 100%;
      width: 25px;
      right: -20px;
      background: $light-green;
    }
  }
}
.page.efficacy {
  .inner-nav {
    left: -134px;
  }
}
.page.innovation {
  .inner-nav {
    left: -150px;
    sup.register {
      font-size: 8px;
      font-family: "AG Light";
    }
    .light {
      font-family: "AG Light";
    }
    li {
      &:nth-child(2) a {
        text-transform: inherit;
      }
      a,
      a.router-link-exact-active {
        padding: 0 16px;
      }
    }
    .sentence-case {
      text-transform: capitalize;
      font-size: 14px;
    }
    .lower-case {
      text-transform: lowercase;
      font-size: 14px;
    }
  }
}
.page.tolerability {
  .inner-nav {
    left: -75px;
  }
}

.page.patient-profiles {
  .inner-nav {
    left: -24px;
    li {
      a,
      a.router-link-exact-active {
        padding: 0 16px;
      }
    }
  }
}

.page.dual-study {
  .inner-nav-dual {
      display: flex;
      background: transparent;
      position: absolute;
      width: auto;
      align-items: center;
      justify-content: baseline;
      -webkit-box-align: baseline;
      margin: 0;
      padding: 0;
      right: 40px;
      top: 122px;

      > li {
        position: relative;
        display: flex;
        align-items: center;
        border-right: none;
        &:after {
        background: $light-green;
        }

        a {
          color: $dark-blue;
          height: 34.5px;
          letter-spacing: 0px;
          text-align: left;
          &:after {
            position: absolute;
            content: "";
            width: 1px;
            height: 44%;
            top: 9px;
            right: -1px;

          }
          span {
            font-family: "AG Medium";
            font-style: normal;
          }
        }
        a.router-link-active {
          background: $light-green;

          &:after {
            background: transparent;
          }
        }
      }
      > li {
        &:last-child {
          margin-left: -1px;
          a:after {
            background: transparent;
          }
          &:after {
            display: none;
          }
        }
      }
  }

  .inner-nav {
    left: 30px;
    li {
      a,
      a.router-link-exact-active {
        padding: 0 16px;

        &:last-child {
        margin-left: -1px;
        }
      }
    }
  }
}

.footer {
  > ul {
    > li {
      > .router-link-active + ul {
        display: flex;
      }
    }
  }
}

.page.efficacy.trunk {
  .footer {
    > ul {
      > li {
        ul {
          li {
            a.router-link-active {
              text-decoration: underline;
              color: #6fe0f3;
            }
          }
        }
      }
    }
  }
}
</style>
