<template>
  <page>
    <div class="content">
      <div>
        <div class="title">
        <h1>VISIBLY REDUCED ACNE SCARRING</h1>
        <div>
          <p>Significant >55% IGA success rate in acne clearance and significant 50% reduction <br/> in acne scars with AKLIEF<sup>®</sup> (trifarotene) Cream, 0.005% from baseline to Week 24<sup>2*</sup></p>
          <ul class="nav inner-nav-dual">
                <li>
                <router-link
                    :to="{ name: 'dual-study:start-efficacy' }"
                    ><span>Efficacy</span></router-link
                >
                </li>
                <li>
                <router-link
                    class="custom-link"
                    :to="{ name: 'dual-study:start-results' }"
                    ><span>Results</span></router-link
                >
                </li>
                <li>
                <router-link
                    class="custom-link"
                    :to="{ name: 'dual-study:start-tolerability' }"
                    ><span>Tolerability</span></router-link
                >
                </li>
          </ul>
        </div>
        </div>
        <div class="patient-content">
          <div class="d-flex column one" v-show="pagination == 0">
            <div class="box">
              <div class="box__image">
                <img
                  src="@/assets/images/version-4/jpg-png/start-results/col-1-results.png"
                  alt=""
                />
              </div>
              <a
                class="enlarge"
                href="#"
                @click="toggleModal('StartResultPatient1')"
              ></a>
            </div>
            <div class="box">
              <div class="box__image">
                <img
                  src="@/assets/images/version-4/jpg-png/start-results/col-2-results.png"
                  alt=""
                />
              </div>
              <a
                class="enlarge"
                href="#"
                @click="toggleModal('StartResultPatient2')"
              ></a>
            </div>
          </div>
          <div class="d-flex column two" v-show="pagination == 1">
            <div class="box">
              <div class="box__image">
                <img
                  src="@/assets/images/version-4/jpg-png/start-results/col-3-results.png"
                  alt=""
                  style="width: 363px;"
                />
              </div>
              <a
                class="enlarge"
                href="#"
                @click="toggleModal('StartResultPatient3')"
              ></a>
            </div>
          </div>
          <div class="ctn">
            <div class="pagination-ctn">
              <div class="pagination">
                <img @click="prevPage" class="arrow-img" src="@/assets/images/version-4/svg/left-arrow-button.svg" alt="">
                <div class="index">
                  <div class="index-bold" v-show="pagination == 0">
                    <span class="font-bold">1</span>
                    <span>2</span>
                  </div>
                  <div class="index-bold" v-show="pagination == 1">
                    <span>1</span>
                    <span class="font-bold">2</span>
                  </div>
                </div>
                <img @click="nextPage" class="arrow-img" src="@/assets/images/version-4/svg/right-arrow-button.svg" alt="">
              </div>
            </div>
            <div class="two">
              <a  href="#" class="button" @click="toggleModal('StartStudyDesign')">
                <p>Study Design</p>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </page>
</template>

<script>
import Page from '@/components/common/Page'
import { sendTracking } from '@/mixins/tracking.js'

export default {
  name: 'start-results',
  components: {
    page: Page
  },

  data () {
    return {
      pagination: 0
    }
  },

  computed: {
  },

  methods: {
    prevPage () {
      if (this.pagination > 0) {
        this.pagination--
        sendTracking(1085)
      }
    },
    nextPage () {
      if (this.pagination < 1) {
        this.pagination++
        sendTracking(1086)
      }
    }
  }

}
</script>
<style lang="scss" scoped>
.page.start-results-patients {
  .title {
    color: $dark-blue;
    margin: 0 0 61px 41px;
    position: relative;
    div{
      ul{
        top: 92px;
      }
    }
    h1 {
      font-family: "AG Bold Condensed";
      font-size: 41px;
      line-height: 0.975;
      margin: 0 0 6px 0;
      text-transform: uppercase;
    }
    p {
      margin: 0;
      line-height: 1.1;
      font-family: "AG Medium";
      display: block;
    }
    sup {
      font-size: 9px;
    }
  }

  .margin-null{
    margin: 0 !important
  }
  .patient-content {
    border-top: 7px solid $light-green;
    color: $font-color-gray;
    background: $white;
    padding: 20px 49px 0px 42px;
    height: 438.5px;
    width: 990px;
    box-sizing: border-box;
    .column {
      .box {
        display: flex;
        position: relative;
        align-items: center;
        justify-content: space-between;
        box-sizing: border-box;
        img {
          width:350px;
          display: block;
        }
        &:nth-child(1) {
          margin-right: 26px;
        }
        &:nth-child(2) {
          margin-top: 2px;
        }
        &__content {
          padding-left: 19px;
          box-sizing: border-box;
        }
      }
      &.one {
        margin-bottom: 20px;
        justify-content: space-evenly;
      }
      &.two{
        margin-bottom: 22px;
        justify-content: space-evenly;
      }
    }

    small {
      font-size: 10px;
      letter-spacing: -0.07px;
    }

    .ctn{
      position: relative;
      width: 100%;
      min-height: 20px;
      .pagination-ctn{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        .pagination{
          margin-top: 20px;
          display: flex;
          align-items: center;
          width: 140px;
          justify-content: space-between;

          .index{
            color: $dark-blue;
            font-size: 17px;
            .index-bold{
              display: flex;
              gap: 20px;
              align-items: baseline;
              .font-bold{
                font-family: "Sofia Pro Bold";
              }
            }
          }

          .arrow-img{
            width: 29px;
          }
        }
      }

      .two{
        position: absolute;
        top: 0;
        right: 0;
      }
    }
    .enlarge {
      width: 29px;
      height: 29px;
      position: absolute;
      top: -14px;
      right: -14px;
      z-index: 99;
      background: url("~@/assets/images/version-4/svg/enlarge.svg") no-repeat;
      background-size: cover;
      display: block;
    }
  }
  .button{
    padding: 9.5px 15px;
    font-family: "AG Medium";
    -webkit-transform: skew(-15deg);
    transform: skew(-15deg);
    font-size: 14px;
    text-transform: uppercase;
    text-decoration: none;
    display: inline-block;
    color: $white;
    background-color: $dark-blue;

    p{
      margin: 0;
      -webkit-transform: skew(15deg);
      transform: skew(15deg);
    }
  }
}
</style>
