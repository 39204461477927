<template>
  <page>
    <div class="content">
      <!-- Title -->
      <div class="title">
        <h1>
          THE FIRST TOPICAL ACNE TREATMENT WITH
          <br />PROVEN RESULTS ON THE FACE AND TRUNK.<sup>4</sup>
        </h1>
      </div>

      <div class="content_copy">
        <tabs :class="'horizontal-layout'">
          <div slot="content">
            <div class="tabs__buttons">
              <router-link :to="{ name: 'efficacy:ps1' }"
                :class="{ 'router-link-active': this.$route.name === 'efficacy' }"
                ><span>FACE</span></router-link
              >
              <router-link :to="{ name: 'efficacy:ps1trunk' }"
                ><span>TRUNK</span></router-link
              >
            </div>
            <div class="tabs__content">
              <efficacy-face></efficacy-face>
            </div>
          </div>
        </tabs>
        <a
          href="#"
          class="study-design-button"
          v-on:click="toggleModal('EfficacyStudyDesignModal')"
          ><span>STUDY DESIGN</span></a
        >
      </div>
    </div>
  </page>
</template>

<script>
import Page from '@/components/common/Page'
import Tabs from '@/components/common/Tabs'
import EfficacyFace from '@/components/tabs/EfficacyPS1Face'

export default {
  name: 'efficacy',
  components: {
    page: Page,
    tabs: Tabs,
    'efficacy-face': EfficacyFace
  },
  data: function () {
    return {
      title: 'RAPID RESULTS* AND POWERFUL <br>EFFICACY VS VEHICLE.<sup>2</sup>',
      modals: {
        studyDesign: {
          isVisible: false
        }
      }
    }
  }
}
</script>

<style lang="scss">
.page.efficacy {
  background-image: url("~@/assets/images/version-4/jpg-png/bg-screen.jpg");
  background-repeat: no-repeat;
  background-position: center 0;
  background-size: 100%;
  color: #fff;
  img {
    max-width: 100%;
  }
  sup {
    font-size: 60%;
  }
  .title {
    h1 {
      color: $dark-blue;
      font-family: "AG Bold Condensed";
      font-size: 41px;
      line-height: 0.975;
      margin: 2px 0 18px 40px;
      letter-spacing: -0.4px;
    }
    sup {
      font-size: 60%;
      font-family: "AG Light";
    }
  }
  .content {
    .efficacy-face-content {
      .tabs__buttons {
        a {
          font-weight: 100;
          font-family: "Sofia Pro Medium";
          line-height: 48.2px;
        }
      }
    }

    .study-design-button {
      display: block;
      position: absolute;
      bottom: 108px;
      right: 82px;
      text-decoration: none !important;
      width: 133px;
      height: 35px;
      line-height: 40px;
      text-align: center;
      text-indent: 0px;
      font-size: 14px;
      background: $dark-blue;
      color: #fff;
      font-weight: bold;
      letter-spacing: -0.7px;
      transform: skew(-13.3deg);
      span {
        transform: skew(13.3deg);
        display: block;
      }
    }
  }
}
</style>
