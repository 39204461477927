import App from './App.vue'
import Vue from 'vue'
import VueTouch from 'vue-touch'
import router from './router'
import store from './store'
import upperFirst from 'lodash/upperFirst'
import camelCase from 'lodash/camelCase'
import { mapMutations } from 'vuex'
import { tracking } from '@/mixins/tracking'

import './fonts.scss'

Vue.config.productionTip = false

Vue.use(VueTouch, {
  name: 'v-touch'
})

const requireComponent = require.context(
  // The relative path of the components folder
  './components/modals',
  // Whether or not to look in subfolders
  false,
  // The regular expression used to match base component filenames
  /[A-Z]\w+\.(vue|js)$/
)

requireComponent.keys().forEach(fileName => {
  // Get component config
  const componentConfig = requireComponent(fileName)

  // Get PascalCase name of component
  const componentName = upperFirst(
    camelCase(
      // Strip the leading `./` and extension from the filename
      fileName.replace(/^\.\/(.*)\.\w+$/, '$1')
    )
  )

  // Register component globally
  Vue.component(
    componentName,
    // Look for the component options on `.default`, which will
    // exist if the component was exported with `export default`,
    // otherwise fall back to module's root.
    componentConfig.default || componentConfig
  )
})

Vue.mixin({
  methods: {
    ...mapMutations([
      'toggleModal'
    ])
  }
})

const init = function () {
  new Vue({
    router,
    store,
    mixins: [tracking],
    render: h => h(App)
  }).$mount('#app')
}

if (window.location.origin === 'file://') {
  // eslint-disable-next-line
  M3.Pivot.Setup({
    API: 'v1', // default is "v1"
    AssetId: 3295, // required - check with M3 Health for correct Id
    ShowBottomNavExample: true,
    Success: init
    // ShowBottomNav: true // default is false and requires a correct AssetId
  })
} else {
  init()
}
