<template>
  <page>
    <div class="content">
      <h1 class="content-main-heading">
        THE AKLIEF<sup>&reg;</sup> (trifarotene) Cream, 0.005% VEHICLE
      </h1>
      <div class="content-wrapper">
        <!-- <div class="content-wrapper-sidebar">
          <h2 class="bold-note">
            The next step <br />
            in acne treatment <br />
            from Galderma.
          </h2>
          <img
            src="@/assets/images/version-4/svg/icon-3.svg"
            height="122"
            alt=""
          />
        </div> -->
        <div class="content-wrapper-main">
          <div class="content-wrapper-main-content">
            <h3 class="content-wrapper-main-content-title">
              AKLIEF Cream vehicle contains:
            </h3>
            <div class="content-wrapper-main-content-list">
              <ul>
                <li class="simugel">
                  <img
                    src="@/assets/images/version-4/jpg-png/simulgel.png"
                    alt=""
                  />
                  <div class="inner-content">
                    <h4>Simulgel<sup>&reg;</sup> 600 PHA<sup>13</sup></h4>
                    <p>
                      - Non-greasy<br />
                      - Allows for homogenous dispersion of active
                      ingredients<br />
                      - Not sensitive to pH changes<br />
                    </p>
                  </div>
                </li>
                <li class="allantoin">
                  <img
                    src="@/assets/images/version-4/jpg-png/allantoin.png"
                    alt=""
                  />
                  <div class="inner-content">
                    <h4>Allantoin<sup>13</sup></h4>
                    <p>A known skin-conditioning agent and protectant</p>
                  </div>
                </li>
                <li class="emollients">
                  <img
                    src="@/assets/images/version-4/jpg-png/emollients.png"
                    alt=""
                  />
                  <div class="inner-content">
                    <h4>2 emollients<sup>13-15</sup></h4>
                    <p>
                      Cyclomethicone and medium-chain triglycerides allow for
                      <br />
                      greater spreadability
                    </p>
                  </div>
                </li>
                <li class="paraben">
                  <img
                    src="@/assets/images/version-4/jpg-png/paraben-free.png"
                    alt=""
                  />
                  <div class="inner-content">
                    <h4>Paraben-free<sup>13</sup></h4>
                  </div>
                </li>
              </ul>
            </div>
            <div class="last-paragraph">
              <p>
                <span>Emollients</span> are mostly lipids and oils which hydrate
                and <br />
                improve skin softness, flexibility, and smoothness.<sup>16</sup>
              </p>
            </div>
            <div class="footnote">
              <p>
                PHA=(P)olysorbate 80, Iso(H)exadecane, and copolymer of
                (A)crylamide.
              </p>
            </div>
          </div>
          <div class="content-wrapper-main-image">
            <img
              src="@/assets/images/version-4/jpg-png/aklief-1.png"
              height="536"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  </page>
</template>

<script>
import Page from '@/components/common/Page'

export default {
  name: 'cream-vehicle',
  components: {
    page: Page
  }
}
</script>
<style lang="scss" scoped>
.page.cream-vehicle {
  background: url(~@/assets/images/version-4/jpg-png/purple-bg.png) no-repeat;
  background-size: 100%;
  .content {
    &-main-heading {
      font-family: "AG Bold Condensed";
      font-size: 40px;
      color: $dark-blue;
      line-height: 1;
      margin-top: 5px;
      margin-left: 42px;
      margin-bottom: 17px;
      letter-spacing: 0.4px;
      min-height: 79px;
      sup {
        font-size: 16px;
      }
    }
    &-wrapper {
      width: 988.5px;
      height: 484.5px;
      background: $white;
      display: flex;
      &-sidebar {
        position: relative;
        width: 232px;
        background: $dark-violet;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        .bold-note {
          font-family: "AG Medium";
          font-size: 20px;
          color: $white;
          line-height: 1.1;
          margin-top: 79px;
          margin-bottom: 32px;
          margin-left: 26px;
        }
      }
      &-main {
        position: relative;
        width: 90%;
        display: flex;
        overflow: hidden;
        &-content {
          margin-top: 30.5px;
          margin-left: 93px;
          &-title {
            font-family: "AG Medium";
            font-size: 17px;
            margin-top: 13px;
            margin-left: 9px;
            margin-bottom: 24px;
            color: $dark-blue;
            letter-spacing: -0.1px;
            sup {
              font-size: 50%;
            }
          }
          &-list {
            ul {
              margin-left: 5px;
              margin-top: 0;
              padding-left: 0;
            }
            sup {
              font-size: 55%;
            }
            li {
              font-family: "AG Medium";
              font-size: 16px;
              color: $dark-blue;
              line-height: 1.1;
              list-style: none;
              display: flex;
              align-items: flex-end;
              &:nth-child(1) {
                margin-bottom: 6px;
              }
              &:nth-child(2) {
                margin-bottom: 12px;
              }
              &:nth-child(3) {
                margin-bottom: 15px;
              }
              h4 {
                margin: 0 0 4px 0;
              }
              p {
                margin: 0;
                letter-spacing: -0.1px;
                line-height: 18px;
                sup {
                  font-size: 10px;
                  font-family: "AG Light";
                }
              }
              img {
                width: 60px;
                display: block;
                margin-right: 17.5px;
              }
              p {
                font-family: "Sofia Pro";
                font-size: 13px;
                color: $font-color-gray;
              }
            }
            .simugel {
              img {
                margin-top: 1px;
              }
              p {
                margin-left: 3px;
              }
              span {
                // display: inline-block;
                // margin-top: 7px;
                // line-height: 1.35;
                // letter-spacing: -0.08px;
              }
            }
            .allantoin {
              img {
                margin-right: 21px;
              }
              .inner-content {
                margin-top: 10px;
              }
              p {
                margin-left: 2px;
              }
              span {
                // margin-left: 2.5px;
                // letter-spacing: -0.08px;
              }
            }
            .emollients {
              h4 {
                margin-bottom: 7px;
                position: relative;
              }
              img {
                margin-right: 19px;
                margin-top: 1px;
              }
              sup {
                // position: absolute;
                // top: 4px;
                // left: 88px;
              }
              span {
                // display: inline-block;
                // margin-top: 9.5px;
                // margin-bottom: -6px;
                // letter-spacing: -0.17px;
                // line-height: 1.2;
              }
            }
            .paraben {
              img {
                margin-right: 19px;
              }
              .inner-content {
                display: flex;
                align-items: center;
                height: 60px;

                h4 {
                  margin: 0;
                  text-align: center;
                  position: relative;
                }
                sup {
                  // position: absolute;
                  // top: 3px;
                  // left: 94px;
                }
              }
            }
          }
          .last-paragraph {
            p {
              font-family: "Sofia Pro";
              font-size: 13px;
              color: $font-color-gray;
              letter-spacing: -0.07px;
              margin: 26px 0 0 6px;
              span {
                font-family: "Sofia Pro Bold";
                color: $dark-blue;
              }
              sup {
                font-size: 50%;
              }
            }
          }
          .footnote {
            p {
              font-family: "Sofia Pro";
              font-size: 10px;
              color: $font-color-gray;
              margin: 14px 0 0 6px;
              letter-spacing: -0.02px;
            }
          }
        }
        &-image {
          margin-top: 44px;
          margin-left: 123px;
        }
      }
    }
  }
}
</style>
