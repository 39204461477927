/**
 * Mixin that tracking user action when route change
 */
export const tracking = {
  created: function () {
    const saveUrl = localStorage.getItem('akliefV4.actualUrl')
    if (!saveUrl) {
      sendTrackingFromRoute(this.$route)
    } else if (saveUrl === this.$route.path) {
      sendTrackingFromRoute(this.$route)
    }
  },
  watch: {
    $route: (route) => {
      sendTrackingFromRoute(route)
    }
  }
}

const sendTrackingFromRoute = (route) => {
  if (route.meta.datapointId) {
    sendTracking(route.meta.datapointId)
  }
}

export const sendTracking = (id) => {
  if (window.location.origin === 'file://') {
    // eslint-disable-next-line
    M3.Pivot.Asset.SendTracking({ datapointId: id });
  } else {
    // eslint-disable-next-line
    console.log('[SendTracking]:', id);
  }
}
