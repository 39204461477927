<template>
  <div class="leap-study-design">
    <header>
      <div class="pretitle"><strong>Study Design</strong></div>
      <h1>
        LEAP STUDY DESIGN
      </h1>
      <div>
        <p>
          Phase 4 study of reduction in PIH subsequent to acne topical
          <br /> treatment with AKLIEF<sup class="sup-mark">&reg;</sup> (trifarotene) Cream, 0.005%
        </p>
      </div>
    </header>
    <div class="main-content">
      <div class="main-content__inner">
        <div class="top-content">
          <div class="left-content">
            <h2>Key Inclusion Criteria</h2>
            <p>• Aged 13–35 years </p>
            <p>• Clinical diagnosis of acne vulgaris defined by: </p>
            <div class="indented-content">
              <p>• IGA score of 3 </p>
              <p>• ≥20 inflammatory lesions and 25 noninflammatory lesions </p>
            </div>
            <p>• ODS hyperpigmentation scale of 4-6</p>
            <p>• ≤1 nodules or cysts (≥1 cm)</p>
            <p>• Any Fitzpatrick skin type I to VI</p>
          </div>
          <div class="right-content">
            <h2>Key Endpoints</h2>
            <h5>PIH</h5>
            <p>• Absolute change from baseline in PIH overall disease severity<br />
              <span>(ODS) scores at Week 24</span>
            </p>
            <p>• Percent change from baseline in PIH ODS scores at Week 24, and <br />
              <span>absolute and percent changes at Weeks 12, 16 and 20</span>
            </p>
            <p>• PAHPI (size, intensity, number) at Weeks 12, 16, 20 and 24</p>
          </div>
        </div>
        <div class="bottom-content">
          <div class="graph-container">
            <img class="graph" src="@/assets/images/version-4/jpg-png/leap-efficacy/leap-efficacy-graph.png" alt="" />
          </div>
          <div class="products-container">
            <img class="products" src="@/assets/images/version-4/jpg-png/leap-efficacy/leap-efficacy-day-night.png"
              alt="" />
          </div>
        </div>
      </div>
    </div>
    <small>
      <span>BL</span>=baseline;
      <span>IGA</span>=Investigator’s Global Assessment;
      <span>ODS</span>=overall disease severity.
    </small>
  </div>
</template>

<script>
export default {}
</script>
<style lang="scss">
.modal-leap-study-design {
  .modal {
    &__body {
      padding: 27px 0 0 0;
      height: 611px;
      width: 922.5;
      margin-left: -20px;
      margin-top: 2px;
    }
  }
}

.leap-study-design {

  header {
    color: $dark-blue-2;
    margin: 0 0 25px 60px;
    position: relative;

    h1 {
      font-family: "AG Medium Condensed";
      font-size: 41px;
      line-height: 35px;
      text-transform: uppercase;
      margin: 5px 0;

      sup {
        font-family: "AG Light Condensed";
        margin-left: -11px;
        font-size: 18px;
      }
    }

    p {
      font-size: 17px;
      margin: -2px 0 2px 0;
      line-height: 17px;
      font-family: "AG Medium";

      sup {
        font-size: 52%;
        line-height: 0;
        font-family: inherit
      }
    }

    .pretitle {
      font-size: 12px;
      font-family: "Sofia Pro Bold";
      color: $light-blue-2;
      text-transform: uppercase;
    }
  }

  .main-content {
    background-color: $light-gray;
    color: $font-color-gray;
    padding: 39px 0 0 46.5px;
    height: 393px;
    width: 100%;
    box-sizing: border-box;
    position: relative;

    .top-content {
      display: flex;
      flex-wrap: nowrap;
      gap: 47px;
      height: 170px;

      .left-content {
        background-color: $medium-violet;
        padding: 17px 0 0 17px;
        color: $white;
        width: 390px;
        line-height: 18.5px;

        h2 {
          font-size: 12.5px;
          font-family: "AG Bold Regular";
          margin: 0;
        }

        p {
          font-family: "AG BQ Regular";
          text-align: left;
          font-size: 12.5px;
          letter-spacing: 0px;
          line-height: 15px;
          margin: 0;
        }

        .indented-content {
          margin-left: 14px;
        }
      }

      .right-content {
        background-color: $light-violet;
        color: $dark-blue;
        padding: 16px 0 0 30px;
        line-height: 11.8px;
        width: 335px;

        h2 {
          font-family: "Sofia Pro Bold";
          margin: 0 0 10px 0;
          font-size: 14.5px;
        }

        h5 {
          font-family: "AG Bold Regular";
          margin-bottom: 0;
          font-size: 10px;
          line-height: 11.5px;
          letter-spacing: 0.2px;
        }

        p {
          font-family: "AG Medium";
          text-align: left;
          font-size: 10px;
          letter-spacing: 0px;
          line-height: 11.5px;
          margin: 0;

          span {
            margin-left: 6px;
          }
        }
      }
    }

    .bottom-content {
      display: flex;
      flex-wrap: nowrap;
      gap: 46px;

      .graph-container {
        width: 409px;

        .graph {
          width: 100%;
          padding-bottom: 24px;
        }
      }

      .products-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 364px;
        widows: 100%;
        margin-top: 47px;

        .products {
          width: 238px;
          height: auto;
        }
      }
    }

  }

  small {
    margin: 15px 0 0 52.5px;
    font-size: 10px;
    width: 100%;
    font-family: "Sofia Pro";
    line-height: 12.9px;
    display: block;
    letter-spacing: -0.05px;
    color: $black;

    span {
      font-family: "Sofia Pro Bold";
    }
  }
}
</style>
