<template>
  <div>
    <div id="app">
      <v-touch v-on:swipeleft="onSwipeLeft" v-on:swiperight="onSwipeRight" ref="tapper">
        <router-view/>
      </v-touch>
    </div>
    <div id="modals">
      <modal
        :visible="modal.isVisible"
        :on-close="toggleModal"
      >
        <component :is="modal.component"></component>
      </modal>
    </div>
  </div>
</template>
<script>
import Modal from '@/components/common/Modal.vue'

export default {
  name: 'App',
  components: {
    modal: Modal
  },
  methods: {
    onSwipeLeft (event) {
      const path = event.srcEvent.composedPath()
      const disabled = path.some(item => {
        if (item.classList) {
          return item.classList.value.includes('disable-global-swipe')
        } else {
          return false
        }
      })
      if (disabled) {
        return
      }
      if (this.lastPage === this.currentPage) {
        this.next = 0
      } else {
        this.next = this.currentPage + 1
      }
      if (
        [
          'patient-profiles',
          'innovation',
          'efficacy',
          'tolerability',
          'dual-study'
        ].indexOf(this.routes[this.next].name) > -1
      ) {
        this.$router.push(this.routes[this.next + 1]).catch(err => err)
      } else {
        this.$router.push(this.routes[this.next]).catch(err => err)
      }
    },
    onSwipeRight (event) {
      const path = event.srcEvent.composedPath()
      const disabled = path.some(item => {
        if (item.classList) {
          return item.classList.value.includes('disable-global-swipe')
        } else {
          return false
        }
      })
      if (disabled) {
        return
      }
      if (this.currentPage === 0) {
        this.prev = this.lastPage
      } else {
        this.prev = this.currentPage - 1
      }
      if (
        [
          'patient-profiles',
          'innovation',
          'efficacy',
          'tolerability',
          'dual-study'
        ].indexOf(this.routes[this.prev].name) > -1
      ) {
        this.$router.push(this.routes[this.prev - 1]).catch(err => err)
      } else {
        this.$router.push(this.routes[this.prev]).catch(err => err)
      }
    }
  },
  computed: {
    currentPage () {
      this.routes.forEach((route, i) => {
        if (route.name === this.$route.name) {
          // eslint-disable-next-line
          this.page = i
        }
      })
      return this.page
    },
    modal () {
      return this.$store.state.modal
    }
  },
  beforeMount () {
    this.routes = []
    this.$router.options.routes.forEach(route => {
      if ('children' in route) {
        route.children.forEach(child => {
          if ('children' in child) {
            child.children.forEach(child2 => {
              this.routes.push(child2)
            })
          } else {
            this.routes.push(child)
          }
        })
      } else {
        this.routes.push(route)
      }
    })
    this.lastPage = this.routes.length - 1
    const previousRoute = localStorage.getItem('akliefV4.actualUrl')
    if (previousRoute) {
      const routeExists = this.$router.resolve(previousRoute)
      if (routeExists.resolved.matched.length > 0) {
        this.$router.push(previousRoute).catch(err => err)
      }
    }
  },
  watch: {
    $route: function (route) {
      localStorage.setItem('akliefV4.actualUrl', route.fullPath)
    }
  }
}
</script>

<style lang="scss">
 #app {
   width: 1024px;
   height: 768px;
   overflow: hidden;
 }

 body {
  // Font "Smooth" on Photoshop
  margin: 0;
  -webkit-font-smoothing: antialiased;
  font-family: "Sofia Pro";
}

h1, h2, h3, h4, h5, h6 {
  font-family: "AG Medium";
  font-weight: normal;
}

.text-xs {
  font-size: 10px;
  line-height: 10px;
  letter-spacing: -0.5px;
}

.text-xl {
  font-size: 80.5px;
  line-height: 67px;
}

.text-center {
  text-align: center;
}

.text-color-1{
  color: $font-color-gray;
}

.d-flex{
  display: flex;
}
</style>
