<template>
  <div class="important-safety-information">
    <div class="main-content">
      <h2>Important Safety Information</h2>
      <p>
        <span class="strong-word">Indication:</span> AKLIEF<sup>®</sup> (trifarotene) Cream, 0.005% is a retinoid indicated for the topical treatment of acne vulgaris in<br>
        patients 9 years of age and older. <span class="strong-word">Adverse Events:</span> The most common adverse reactions (incidence ≥ 1%) in<br>
        patients treated with AKLIEF Cream were application site irritation, application site pruritus (itching), and sunburn.<br>
        <span class="strong-word">Warnings/Precautions:</span> Patients using AKLIEF Cream may experience erythema, scaling, dryness, and<br>
        stinging/burning. Use a moisturizer from the initiation of treatment, and, if appropriate, depending upon the<br>
        severity of these adverse reactions, reduce the frequency of application of AKLIEF Cream, suspend or discontinue<br>
        use. Avoid application of AKLIEF Cream to cuts, abrasions or eczematous or sunburned skin. Use of “waxing” as a<br
        >depilatory method should be avoided on skin treated with AKLIEF Cream. Minimize exposure to sunlight and<br>
        sunlamps. Use sunscreen and protective clothing over treated areas when exposure cannot be avoided.
      </p>
      <p>
        You are encouraged to report negative side effects of prescription drugs to the FDA.<br>
        Visit <a href="https://www.fda.gov/medwatch" target="_blank">www.fda.gov/medwatch</a> or call 1-800-FDA-1088.
      </p>
    </div>
    <div class="footer-content">
      <p>
        ©2023 Galderma Laboratories, L.P. All rights reserved. All trademarks are the property of their respective owners.
      </p>
      <div class="footer-items">
        <div class="footer-item">US-AFC-2300145</div>
        <div class="footer-item">
          <p>www.aklief.com/hcp</p>
        </div>
        <div class="footer-item">
          <img src="~@/assets/images/version-4/svg/galderma-logo-3.svg">
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
}
</script>
<style lang="scss">
.modal-important-safety-information {
  .modal__body{
    padding: 0px;
    height: 514px;
    top: 7px;
  }
}
.important-safety-information {
  font-family: 'Sofia Pro';
  .main-content{
    padding: 30px 50px 25px 50px;
    color: #58595b;
    font-family: 'Sofia Pro Light';

    .strong-word {
      font-family: 'Sofia Pro Bold';
      line-height: 0px;
    }

    h2{
      margin-bottom: 0px;
      margin-top: 8px;
      font-family: 'AG Bold Condensed';
      color: $modal-title-color;
      text-transform: uppercase;
      font-size: 30px;
      letter-spacing: 0.3px;
    }
    p{
      margin-top: 7px;
      margin-bottom: 20px;
      margin-left: -2px;
      line-height: 20px;
      font-size: 16px;
      letter-spacing: -0.2px;
      sup{
        font-size: 57%;
        vertical-align: text-bottom;
      }
    }
    a{
      color: #58595b;
    }
  }
  .footer-content{
    color: $modal-title-color;
    padding: 13px 50px 54px 50px;
    height: 70px;
    background-color: #ebebeb;
    font-size: 14px;
    font-family: 'Sofia Pro';
    letter-spacing: -0.2px;
    .footer-items{
      margin-top: 30px;
      display: flex;
      text-align: center;
      flex-direction: row;
      .footer-item{
        width: 33%;
        img{
          width: 126.5px;
        }
      }
      .footer-item:first-child{
        text-align: left;
      }
      .footer-item:nth-child(2){
        p {
          margin: 0 58px 0 0;
        }
      }
      .footer-item:last-child{
        text-align: right;
      }
    }
  }
}
</style>
