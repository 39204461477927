<template>
  <div class="lucas-cream">
    <div class="main-content">
      <div class="col-content">
        <h1><span>About</span> AKLIEF<sup>&reg;</sup> <span>Cream</span></h1>
        <ul>
          <li>
            <span class="bold-font">47% of patients</span> in the AKLIEF Cream clinical trials <span class="bold-font">were<br/>
            teens (12-17 years old)</span><sup>2</sup>
          </li>
          <li>
            In the PIVOTAL 1 Study, significant improvement in facial<br/>
            inflammatory lesions was achieved <span class="bold-font">as early as Week 2</span><br/>
            with AKLIEF Cream vs vehicle<sup>2</sup>
          </li>
          <li>
            AKLIEF Cream was <span class="bold-font">proven safe and well-tolerated</span> for <br>
            face and trunk across 4 areas (erythema, scaling, <br>
            dryness, stinging/burning) in all the clinical trials<sup>2</sup>
          </li>
          <li>
            In fact, <span class="bold-font">9 out of 10 clinical trials patients</span> completed <br>
            the trials<sup>2</sup>
          </li>
        </ul>
        <p class="footnote">The most common adverse reactions (incidence ≥ 1%) in patients treated <br/>with AKLIEF Cream were application site irritation, application site pruritus (itching), and sunburn.</p>
      </div>
      <div class="col-image">
        <img
          src="@/assets/images/version3/aklief-packshot-2.png"
          height="492"
          alt=""
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss">
.modal-lucas-aklief-cream {
  .modal__body {
    padding: 38px 72px 85px 72px;
    margin-top: 34px;
    height: 550px;
    overflow: hidden;
  }
  .modal__close {
    button {
      bottom: -34px;
    }
  }
  .main-content {
    display: flex;
    h1 {
      font-family: "Sofia Pro Medium";
      font-size: 31px;
      color: $font-color-blue;
      margin-top: -10px;
      sup {
        font-family: "Myriad Pro";
        vertical-align: text-bottom;
        font-size: 90%;
      }
      span {
        font-family: "Sofia Pro Medium";
        font-size: 31px;
      }
    }
    .col-content {
      margin-top: 30px;
      margin-right: 90.5px;
      margin-left: -7px;
      ul {
        font-family: "Sofia Pro";
        font-size: 16.5px;
        color: $font-color-gray;
        padding-left: 20px;
        margin-top: -14px;
        margin-left: 0px;
        letter-spacing: -0.35px;
        li {
          line-height: 1.3;
          margin-bottom: 15px;
          list-style-type: none;
          position: relative;
          &:before {
            content: "•";
            color: $font-color-blue;
            display: inline-block;
            position: absolute;
            left: -20px;
            top: 1px;
          }
          &:nth-child(4),&:nth-child(5) {
            line-height: 1.2;
            margin: 0 0 10px 0;
          }
          &:nth-child(3) {
            margin-top: 22px;
          }
        }
        .pivotal-text {
          margin-top: 20px;
          letter-spacing: -0.35px;
        }
        .bold-font {
          font-family: "Sofia Pro Bold";
          line-height: 15px;
        }
        .small-text {
          display: inline-block;
          font-size: 13px;
          margin-top: 7px;
          margin-left: 10px;
          letter-spacing: -0.1px;
          line-height: 1.3;
          text-indent: -10px;
        }
        sup {
          font-size: 50%;
        }
      }
      .footnote {
        font-family: "Sofia Pro";
        font-size: 13px;
        color: $font-color-gray;
        line-height: 1.34;
      }
    }
    .col-image {
      margin-left: -26px;
      margin-top: 13px;
    }
  }
}
</style>
