<template>
  <a class="btn-primary-skew" :style="cssVars">
    <span class="btn-primary-skew-text">
      <slot></slot>
    </span>
  </a>
</template>

<script>
export default {
  name: 'PrimaryButton',
  props: {
    colorText: {
      type: String,
      default: '#fff'
    }
  },
  computed: {
    cssVars () {
      return {
        '--color': this.colorText
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.btn-primary-skew {
  display: inline-block;
  transform: skew(-15deg);
  text-decoration: none;
  background: $dark-blue;
  padding: 11.5px 20px;
  &-text {
    text-transform: uppercase;
    text-align: center;
    font-size: 15px;
    letter-spacing: 0.5px;
    color: var(--color);
    display: inline-block;
    transform: skew(15deg);
  }
}
</style>
