<!-- eslint-disable no-tabs -->
<template>
  <page>
    <div class="content">
      <div class="title">
        <h1>
          AKLIEF<sup>®</sup> (TRIFAROTENE) CREAM, 0.005%<br />
          RAPIDLY DECREASED PIH SEVERITY<br />
          DURING THE FIRST 12 WEEKS<sup>20*</sup>
        </h1>
        <div>
          <ul class="nav inner-nav-dual">
            <li>
              <router-link
                :to="{ name: 'dual-study:leap-efficacy' }"
                class="custom-link"
                ><span>Efficacy</span></router-link
              >
            </li>
            <li>
              <router-link
                class="custom-link"
                :to="{ name: 'dual-study:leap-results' }"
                ><span>Results</span></router-link
              >
            </li>
            <li>
              <router-link
                class="custom-link"
                :to="{ name: 'dual-study:leap-tolerability' }"
                ><span>Tolerability</span></router-link
              >
            </li>
          </ul>
        </div>
      </div>
      <div class="main-content">
        <div class="top-content">
          <span class="head-title">
            Percent change in PIH ODS scores from baseline (ITT).<sup>†</sup>
          </span>
          <router-link
            class="ctn-results"
            :to="{ name: 'dual-study:leap-results-patients' }"
          >
            <span class="text">VIEW RESULTS IMAGES</span>
            <span class="arrow">&rsaquo;</span>
          </router-link>
        </div>
        <div class="middle-content">
          <div class="ctn-img-stats">
            <img
              class="chart-1"
              src="@/assets/images/version-4/jpg-png/leap-results/leap-results-graph.png"
              alt=""
            />
            <a
              class="enlarge"
              href="#"
              @click="toggleModal('LeapResultsStats')"
            ></a>
          </div>
          <div class="ctn-rigth">
            <div class="ctn-chart">
              <img
                class="chart-2"
                src="@/assets/images/version-4/jpg-png/leap-results/34-percent.png"
                alt=""
              />
            </div>
            <div class="ctn-label">
              <p>
                <span class="medium-parag">Continued improvement<br />
                in size, intensity, and<br />
                number of post-acne<br />
                lesions was seen in<br />
                subjects</span> with AKLIEF<br />
                Cream at Week 24.
              </p>
            </div>
          </div>
          <div class="ctn-rigth float">
            <div class="ctn-chart">
              <img
                class="chart-2"
                src="@/assets/images/version-4/jpg-png/leap-results/61-percent.png"
                alt=""
              />
            </div>
            <div class="ctn-label">
              <p>
                <span class="medium-parag">Success defined as <br />IGA 0 or 1 and <br />a 2-grade
                improvement <br />from baseline.</span> Vehicle <br />success at week
                13 was <br />39.4%.
              </p>
            </div>
          </div>
        </div>
        <div class="footer-ctn">
          <span
            >*By imputing missing data using multiple imputation under the
            assumption of missing at random (ITT population)<br />.
            <sup>†</sup>For absolute change in PIH ODS scores from baseline
            (MI), the difference between treatment groups [95% CI] was -10.7
            [–20.9, –0.6] at Week 12 (P=0.0378),<br />
            –5.1 [–17.0, 6.9] at Week 16 (P=0.4039), –2.8 [–16.4, 10.7] at Week
            20 (P=0.6816), and –0.6 [–14.9, 13.7] at Week 24 (P=0.9395). Study
            primary endpoint was<br />
            Week 24. From BL to Weeks 16, 20, and 24, there was no meaningful or
            significant difference in mean percent change in PIH ODS score in
            the AKLIEF group<br />
            compared with the vehicle group(–45.4 vs –44.9, respectively, at
            Week 24; Δ0.6, P = 0.9395; MI).<sup>20</sup></span
          >
          <div class="">
            <a href="#" class="button" @click="toggleModal('LeapStudyDesign')">
              <p>Study Design</p>
            </a>
          </div>
        </div>
      </div>
    </div>
  </page>
</template>

<script>
import Page from '@/components/common/Page'

export default {
  name: 'leap-results',
  components: {
    page: Page
  }
}
</script>
<style lang="scss" scoped>
.page.leap-results {
  .enlarge {
    width: 29px;
    height: 29px;
    position: absolute;
    top: -14px;
    right: -14px;
    z-index: 99;
    background: url("~@/assets/images/version-4/svg/enlarge.svg") no-repeat;
    background-size: cover;
    display: block;
  }
  .title {
    color: $dark-blue;
    margin: 2px 0 21px 41px;
    position: relative;

    h1 {
      font-family: "AG Bold Condensed";
      font-size: 40px;
      line-height: 0.975;
      margin: 0 0 6px 0;
      text-transform: uppercase;
      sup {
        font-size: 56%;
      }
    }

    p {
      font-size: 18px;
      margin: -2px 0 2px 0;
      line-height: 17px;
      font-family: "AG Medium";
      top: 42px;
      left: 192px;
      sup {
        font-size: 10px;
        font-family: "AG BQ Regular";
      }
    }

    small {
      font-size: 9.5px;
      text-align: left;
      line-height: 13px;
      letter-spacing: 0px;
      color: $white;

      sup {
        font-family: "AG Light Condensed";
        font-size: 5px;
      }
    }
  }

  .main-content {
    border-top: 7px solid $light-green;
    color: $font-color-gray;
    background: $white;
    padding: 10px 5px 0px 42px;
    height: 438.5px;
    width: 990px;
    box-sizing: border-box;
    position: relative;
    .top-content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-family: "AG Medium";
      margin-bottom: 10.5px;
      .head-title {
        color: $modal-title-color;
        font-size: 19px;

        sup {
          font-size: 60%;
        }
      }
      .ctn-results {
        color: $medium-violet;
        display: flex;
        align-items: center;
        margin-right: 25px;
        text-decoration: none;
        .text {
          font-size: 10px;
          margin-right: 8px;
        }
        .arrow {
          font-size: 25px;
          font-family: monospace;
        }
      }
    }

    .middle-content {
      display: flex;
      justify-content: space-between;
      margin-bottom: 13px;
      .ctn-img-stats {
        position: relative;
        .chart-1 {
          width: 470px;
        }
      }
      .ctn-rigth {
        display: flex;
        height: 155px;
        .ctn-chart {
          background-color: $background-color-pink;
          .chart-2 {
            margin-top: 7px;
            margin-bottom: 9px;
            width: 140px;
          }
        }
        .ctn-label {
          display: flex;
          align-items: flex-start;
          background-color: $background-color-gray;
          color: $modal-title-color;
          padding-top: 10px;

          p {
            margin-left: 28px;
            margin-right: 24px;
            margin-top: 5px;
            font-family: "AG BQ Regular";
            span {
              font-family: "AG Medium";
            }
          }
        }
      }

      .float {
        position: absolute;
        right: 5px;
        top: 188px;
        .chart-2 {
          width: 144.5px !important;
        }
      }
    }
    .footer-ctn {
      display: flex;
      gap: 52px;
      color: $black;
      font-family: "Sofia Pro";
      font-size: 10px;
      line-height: 13px;
      align-items: center;
      span {
        sup {
          font-size: 8px;
          line-height: 0;
        }
      }
    }
  }
  .button {
    padding: 9.5px 15px;
    font-family: "AG Medium";
    -webkit-transform: skew(-15deg);
    transform: skew(-15deg);
    font-size: 14px;
    text-transform: uppercase;
    text-decoration: none;
    display: inline-block;
    color: $white;
    background-color: $dark-blue;
    p {
      margin: 0;
      -webkit-transform: skew(15deg);
      transform: skew(15deg);
    }
  }
}

.page.dual-study {
  .inner-nav-dual {
    top: 90px;
  }
}
</style>
