<template>
  <div class="patient2">
    <header>
      <div class="pretitle"><strong>Face</strong></div>
      <h1 class="title">Patient 2</h1>
    </header>
    <main>
      <img src="@/assets/images/version-4/jpg-png/patient-4-lg.jpg" alt="" />
      <p>
        <strong>STUDY 118295:</strong> Open-label, single-arm study of
        trifarotene cream for 24 weeks.
      </p>
      <small>IGA=Investigator's Global Assessment.</small>
    </main>
  </div>
</template>
<script>
export default {}
</script>
<style lang="scss">
.modal-patient2 {
  .modal {
    &__body {
      padding: 0;
      height: 618px;
    }
  }
}
.patient2 {
  header {
    padding: 43px 0 0 71px;
    margin-bottom: 22px;
  }

  h2 {
    font-size: 18.3px;
  }

  .title {
    line-height: 40px;
    margin: 0 0 0 -1px;
  }

  .pretitle {
    font-size: 11px;
    color: $light-blue-2;
    margin-top: 2px;
    text-transform: uppercase;
  }
  img {
    max-width: 100%;
  }
  main {
    padding: 0 58px 0 72px;
    img {
      margin-bottom: 10px;
    }
    p {
      margin: 0;
      font-size: 12.2px;
      letter-spacing: -0.03px;
      color: $font-color-dark-gray;
      strong {
        color: $modal-title-color;
        font-family: "AG Bold Regular";
      }
    }
    small {
      font-size: 10px;
      color: $font-color-gray;
    }
  }
}
</style>
