<template>
  <div class="prescribing-information">
    <div class="main-content">
      <h1 class="title">Prescribing Information</h1>
      <div class="data">
        <div class="image">
          <img width="100%" src="~@/assets/images/prescribing-info-new-2023.jpg"
            draggable="false" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss">
.modal-prescribing-information {
  .modal__body {
    height: 530px;
    top: -7px;
    padding: 40px 50px 21px;
  }
}
.prescribing-information {
  .title {
    color: $modal-title-color;
    font-size: 30px;
    font-family: "AG Bold Condensed";
    text-transform: uppercase;
    margin-top: 0;
    margin-bottom: 2px;
    letter-spacing: 0.2px;
  }
  img {
    width: 720px;
  }
  .data {
    height: 440px;
    padding: 0 52px;
    overflow-y: scroll;
    overflow-x: hidden;
    .image {
      position: relative;
      img {
        position: relative;
        z-index: 1;
      }
      &::after {
        content: '';
        display: block;
        position: absolute;
        z-index: 2;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
      }
    }
  }
}
</style>
