<template>
  <div class="patient2">
    <header>
      <div class="pretitle"><strong>Dual Study</strong></div>
      <h1 class="title">Patient 2</h1>
    </header>
    <main>
      <img src="@/assets/images/version-4/jpg-png/patient-2-lg.jpg" alt="" />
      <p>
        <strong>STUDY:</strong> Phase 4 double-blind, randomized, placebo and vehicle-controlled safety and efficacy 12-week study of trifarotene cream and doxycycline vs vehicle cream and placebo.
      </p>
      <p class="small">IGA=Investigator's Global Assessment.</p>
    </main>
  </div>
</template>
<script>
export default {}
</script>
<style lang="scss">
.modal-dual-study-patient2 {
  .modal {
    &__body {
      padding: 0;
      height: 632px;
      width: 900px;
      top: -3px;
      right: 5px;
    }
  }
  .patient2 {
    header {
      padding: 36px 0 0 76px;
      margin-bottom: 11px;
    }

    h2 {
      font-size: 18.3px;
    }

    .title {
      line-height: 40px;
      margin: 0 0 0 -1px;
    }

    .pretitle {
      font-size: 11px;
      color: $light-blue-2;
      margin-top: 2px;
      text-transform: uppercase;
    }
    img {
      max-width: 100%;
    }
    main {
      padding: 0 80px 0 74px;
      img {
        margin-bottom: 9px;
      }
      p {
        margin: 1px 0 0 3px;
        font-size: 12.2px;
        letter-spacing: -0.03px;
        line-height: 15px;
        color: $font-color-dark-gray;
        strong {
          color: $modal-title-color;
          font-family: "AG Bold Regular";
        }
      }
      p.small {
        margin: 0px 3px;
        font-size: 10px;
        color: $font-color-gray;
        letter-spacing: -0.07px;
      }
    }
  }
}
</style>
