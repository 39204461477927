<template>
  <div class="start-tolerability-graph">
    <header>
      <h1 class="title">Almost all subjects (99%) remained on AKLIEF Cream for 24 weeks in the<br />Phase 4 scarring study
        (START) with less than 2% of subjects reporting<br />burning sensation, erythema and skin tightness.<sup>2</sup>
      </h1>
    </header>
    <main>
      <img src="@/assets/images/version-4/jpg-png/start-tolerability/start-tolerability-graph.png" alt="" />
    </main>
  </div>
</template>
<script>
export default {}
</script>
<style lang="scss">

.modal-start-tolerability-graph {
  .modal {
    &__body {
      padding: 26px 17px 77.5px 17px;
      width: 846px;
      margin-left: -20px;
      margin-top: 3px;
    }
  }

  .start-tolerability-graph {
    display: flex;
    flex-direction: column;

    .title {
      text-align: center;
      letter-spacing: 0px;
      color: $modal-title-color;
      opacity: 1;
      font-family: "AG Medium";
      text-transform: none;
      font-size: 22.5px;
      margin-bottom: 33.5px;
      line-height: 26.5px;

      sup {
        font-size: 12px;
        vertical-align: text-top;
      }
    }

    img {
      width: 100%;
    }
  }
}
</style>
