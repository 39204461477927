<template>
  <div class="content">
    <div class="content_copy">
      <div class="content_copy_step">
        <img class="faces" src="~@/assets/images/version-4/svg/graphic-2.svg" />
        <div class="text-wrapper">
          <h2><span>1</span>Cleanse</h2>
          <p>
            the area to be treated with<br />
            a mild or soap-free <br />cleanser (such as Cetaphil<sup>®</sup
            ><br />
            Gentle Skin Cleanser).
          </p>
        </div>
        <img
          class="product-image"
          src="~@/assets/images/version-4/jpg-png/cetaphil-2.png"
        />
      </div>
      <div class="content_copy_step">
        <img class="faces" src="~@/assets/images/version-4/svg/graphic-3.svg" />
        <div class="text-wrapper">
          <h2><span>2</span>Treat</h2>
          <p>
            once skin is dry, apply a thin <br />layer of AKLIEF&reg;
            (trifarotene) <br />Cream, 0.005% to the affected areas once daily
            in the evening.
          </p>
          <p class="pump-parag">
            One pump for the face,<br />two pumps for the chest,<br />shoulders,
            and back.
          </p>
        </div>
        <img
          class="product-image"
          src="~@/assets/images/version-4/jpg-png/aklief-3.png"
        />
      </div>
      <div class="content_copy_step">
        <img class="faces" src="~@/assets/images/version-4/svg/graphic-4.svg" />
        <div class="text-wrapper">
          <h2><span>3</span>Moisturize</h2>
          <p>
            your skin with a product <br />
            (such as Cetaphil<sup>&reg; </sup>Moisturizing<br />
            Lotion) as often as needed to<br />
            help minimize skin dryness <br />
            and irritation.
          </p>
        </div>
        <img
          class="product-image"
          src="~@/assets/images/version-4/jpg-png/cataphil-1.png"
        />
      </div>
      <div class="content_copy_step">
        <img class="faces" src="~@/assets/images/version-4/svg/graphic-5.svg" />
        <div class="text-wrapper">
          <h2><span>4</span>Protect</h2>
          <p class="avoid-sun-text">
            by using sunscreen with<br />
            SPF 15 or higher (such as<br />
            Cetaphil<sup>&reg;</sup> DermaControl Oil<br />
            Absorbing Moisturizer with<br />
            SPF 30) and wearing <br />
            protective clothing.
          </p>
        </div>
        <img
          class="product-image"
          src="~@/assets/images/version-4/jpg-png/cetaphil-3.jpg"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'how-to-use',
  components: {}
}
</script>

<style lang="scss">
.page.how-to-use {
  background-image: url("~@/assets/images/version-4/jpg-png/bg-screen.jpg");
  background-repeat: no-repeat;
  background-position: center 0;
  background-size: 100%;
  color: #fff;

  .title {
    h1 {
      color: $dark-blue;
      font-family: "AG Bold Condensed";
      font-size: 41px;
      line-height: 0.975;
      margin: 2px 0 18px 40px;
      letter-spacing: -0.4px;
    }
    sup {
      font-size: 50%;
    }
  }

  .content {
    justify-content: flex-start;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 20px;
    padding: 0 !important;

    .pump-parag {
      margin-top: 5px !important;
    }

    sup {
      font-size: 10px;
    }

    &-heading {
      padding-top: 36px;
      font-size: 40px;
      color: $font-color-blue;
      text-align: center;
      letter-spacing: -0.4px;
      margin: 8px;
      margin-top: 0;
      margin-left: -10px;
    }

    &_copy {
      height: 437px;
      margin-right: 0px;
      margin-left: -20px;
      display: flex;
      text-align: center;
      flex-direction: row;
      padding-top: 28px;
      padding-bottom: 0;
      &_step {
        flex-grow: 1;
        width: 215px;
        height: 328px;
        border-right: 2px solid #f8f8f8;
        .text-wrapper {
          margin-top: 8px;
        }
        .product-image {
          width: 100px;
          margin: 64px 0 0;
        }
        img.faces {
          position: relative;
          left: 6px;
          width: 127px;
          height: auto;
        }
        h2 {
          font-family: "AG Bold Condensed";
          color: $dark-blue;
          font-size: 24px;
          text-transform: uppercase;
          margin-top: -16px;
          margin-left: -4px;
          margin-bottom: -3px;
          letter-spacing: 0.9px;
        }
        span {
          font-size: 40px;
          color: $light-green;
          margin-right: 10px;
        }
        p {
          font-family: "Sofia Pro";
          color: #78787a;
          width: 190px;
          margin: auto;
          margin-block-start: 0;
          margin-inline-start: 0;
          margin-inline-end: 0;
          margin-block-end: 0;
          margin-left: 30px;
          letter-spacing: -0.3px;
          font-size: 14.5px;
          text-align: center;
          line-height: 1.25;
          sup {
            font-size: 55%;
          }
        }
      }
      &_step:nth-child(1) {
        padding-left: 5px;
        p {
          width: 240px;
          margin: 2px auto 0;
          line-height: 1.1;
        }
      }
      &_step:nth-child(2) {
        padding-left: 0px;
        img.faces {
          left: 2px;
        }
        .product-image {
          width: 80px;
          margin: 0px 15px 0 0;
        }
        p {
          margin: 3px auto 0 -3px;
          width: 240px;
          line-height: 1.1;
        }
      }
      &_step:nth-child(3) {
        padding-left: 0px;
        img.faces {
          left: 2px;
        }
        .product-image {
          width: 90px;
          margin: 50px 5px 0 0;
        }
        p {
          width: 240px;
          margin: 2px auto 0 -1px;
          line-height: 1.1;
        }
      }
      &_step:last-child {
        border-right: none;
        padding-left: 0px;
        img.faces {
          top: 5px;
          left: -6px;
          width: 120px;
          height: auto;
        }
        .product-image {
          width: 96px;
          margin: 40px -3px 0 0;
        }
        h2 {
          margin: -12px 7px 0 0;
        }
        p {
          width: 240px;
          margin: 0px auto 0 -1px;
          line-height: 1.1;
        }
        img {
          margin-top: 78px;
          height: 86px;
        }
      }
    }
  }
  .button-area {
    position: absolute;
    right: 406px;
    bottom: 29px;
    .btn {
      color: #fff;
      background: $light-blue;
      padding: 12px 35px;
      border-radius: 3px;
      font-family: "Sofia Pro Medium";
      font-size: 15px;
      letter-spacing: 0.5px;
    }
  }
}
</style>
