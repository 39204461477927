<template>
    <div class="main-video">
        <div class="main-content">
            <video controls="controls" width="750" poster="@/assets/images/cover-video.jpg">
                <source src="@/assets/video/aklief-video.mp4" type="video/mp4">
            </video>
        </div>
    </div>
</template>

<script>
export default {}
</script>

<style lang="scss">
 .modal-main-video {
     .modal__body {
        padding: 85px 72px 85px 72px;
     }
     video {
         display: block;
         margin: 0 auto;
     }
 }
</style>
