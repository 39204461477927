<template>
  <page>
    <!-- Title -->
    <div class="title">
      <h1>ACNE RELIEF YOUR PATIENTS<br />CAN SEE.</h1>
    </div>
    <!-- Description -->
    <div class="description">
      <div class="main-content">
        <div class="d-flex">
          <!-- Column -->
          <div class="column left">
            <h2 class="icon-aside">
              <img src="~@/assets/images/version-4/jpg-png/icon-7.png" alt="" />
              Face
            </h2>
            <!-- box -->
            <div class="box one">
              <div class="box__content">
                <h3>Patient 1</h3>
                <p>
                  Subject: 8511-004<br />
                  Age: 16<br />
                  Gender: Female
                </p>
              </div>
              <div class="box__image">
                <img
                  src="~@/assets/images/version-4/jpg-png/patient-5-sm.jpg"
                  alt=""
                />
              </div>
              <a class="enlarge" @click="toggleModal('Patient1')"></a>
            </div>
            <!-- box -->
            <div class="box two">
              <div class="box__content">
                <h3>Patient 2</h3>
                <p>
                  Subject: 8255-001<br />
                  Age: 15<br />
                  Gender: Male
                </p>
              </div>
              <div class="box__image">
                <img
                  src="~@/assets/images/version-4/jpg-png/patient-4-sm.jpg"
                  alt=""
                />
              </div>
              <a class="enlarge" @click="toggleModal('Patient2');"></a>
            </div>
          </div>
          <!-- Column -->
          <div class="column right">
            <h2 class="icon-aside">
              <img src="~@/assets/images/version-4/jpg-png/icon-8.png" alt="" />
              Trunk
            </h2>
            <!-- box -->
            <div class="box one">
              <div class="box__content">
                <h3>Patient 3</h3>
                <p>
                  Subject: 8255-002<br />
                  Age: 16<br />
                  Gender: Male
                </p>
              </div>
              <div class="box__image">
                <img
                  src="~@/assets/images/version-4/jpg-png/patient-6-sm.jpg"
                  alt=""
                />
              </div>
              <a class="enlarge" @click="toggleModal('Patient3')"></a>
            </div>
            <!-- box -->
            <div class="box two">
              <div class="box__content">
                <h3>Patient 4</h3>
                <p>
                  Subject: 8255-001<br />
                  Age: 15<br />
                  Gender: Male
                </p>
              </div>
              <div class="box__image">
                <img
                  src="~@/assets/images/version-4/jpg-png/patient-7-sm.jpg"
                  alt=""
                />
              </div>
              <a class="enlarge" @click="toggleModal('Patient4')"></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </page>
</template>

<script>
import Page from '@/components/common/Page'
export default {
  name: 'see-results',
  components: {
    page: Page
  }
}
</script>

<style lang="scss">
.page.see-results {
  background-image: url("~@/assets/images/version-4/jpg-png/bg-screen.jpg");
  background-repeat: no-repeat;
  background-position: center 0;
  background-size: 100%;
  color: $font-color-gray;
  .title {
    h1 {
      color: $dark-blue;
      font-family: "AG Bold Condensed";
      font-size: 41px;
      line-height: 0.975;
      margin: 2px 0 22px 41px;
    }
    sup {
      font-size: 50%;
    }
  }
  .main-content {
    background: $white;
    padding: 21px 50px 0px 42px;
    height: 485px;
    width: 990px;
    box-sizing: border-box;
    h2,
    h3 {
      color: $modal-title-color;
      text-transform: uppercase;
    }
    img {
      display: block;
    }
    .column {
      img {
        max-width: 100%;
      }
      .box {
        display: flex;
        position: relative;
        background-color: $light-gray;
        align-items: center;
        justify-content: space-between;
        box-sizing: border-box;
        &__content {
          padding-left: 21px;
          box-sizing: border-box;
          h3 {
            font-size: 14px;
            margin: 6px 0 6px 0;
            letter-spacing: -0.1px;
          }
          p {
            font-size: 13px;
            margin: 0;
            line-height: 1.2;
          }
        }
      }
      .enlarge {
        width: 29px;
        height: 29px;
        position: absolute;
        top: -14px;
        right: -14px;
        z-index: 99;
        background: url("~@/assets/images/version-4/svg/enlarge.svg") no-repeat;
        background-size: cover;
        display: block;
      }
      &.left {
        width: 428px;
        margin-right: 51px;
        h2 {
          font-family: "AG Bold Condensed";
          font-size: 30px;
          margin: 0 0 14px -24px;
          &.icon-aside {
            display: flex;
            align-items: baseline;
            justify-content: center;
            img {
              width: 32px;
              margin-right: 15px;
            }
          }
        }
        .box {
          &.one {
            margin-bottom: 34px;
          }
          &.two {
            h3 {
              margin-top: -4px;
            }
          }
          img {
            width: 261px;
          }
        }
      }
      &.right {
        width: 392px;
        .box {
          &__content {
            padding-left: 22px;
          }
        }
        h2 {
          font-family: "AG Bold Condensed";
          font-size: 30px;
          margin: -2px -24px 15px 0;
          &.icon-aside {
            display: flex;
            align-items: baseline;
            justify-content: center;
            img {
              width: 40px;
              margin-right: 15px;
            }
          }
        }
        .box {
          img {
            width: 226px;
          }
          &.one {
            margin-bottom: 26px;
          }
          &.two {
            h3 {
              margin-top: 0;
            }
          }
        }
      }
    }
  }
}
</style>
