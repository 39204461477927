import Vue from 'vue'
import Vuex from 'vuex'
import { sendTracking } from '@/mixins/tracking.js'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    modal: {
      component: undefined,
      isVisible: false
    }
  },
  mutations: {
    toggleModal (state, payload) {
      if (payload) {
        state.modal.component = payload
        state.modal.isVisible = true
        if (payload === 'StudyDesign') {
          sendTracking(723)
        }
        if (payload === 'EfficacyFaceStudyDesign') {
          sendTracking(417)
        }
        if (payload === 'DualStudyPatient1') {
          sendTracking(726)
        }
        if (payload === 'DualStudyPatient2') {
          sendTracking(727)
        }
        if (payload === 'ResultsPatientThree') {
          sendTracking(728)
        }
        if (payload === 'StartStudyDesign') {
          sendTracking(1084)
        }
        if (payload === 'LeapResultsStats') {
          sendTracking(1094)
        }
        if (payload === 'LeapStudyDesign') {
          sendTracking(1095)
        }
        if (payload === 'LeapResultPatient1') {
          sendTracking(1097)
        }
        if (payload === 'LeapResultPatient2') {
          sendTracking(1098)
        }
        if (payload === 'LeapResultPatient3') {
          sendTracking(1099)
        }
        if (payload === 'LeapTolerabilityGraph1') {
          sendTracking(1101)
        }
        if (payload === 'LeapTolerabilityGraph2') {
          sendTracking(1102)
        }
        if (payload === 'StartResultPatient1') {
          sendTracking(1087)
        }
        if (payload === 'StartResultPatient2') {
          sendTracking(1088)
        }
        if (payload === 'StartTolerabilityGraph') {
          sendTracking(1091)
        }
        if (payload === 'StartResultPatient3') {
          sendTracking(1089)
        }
        if (payload === 'EfficacyTrunkStudyDesign') {
          sendTracking(420)
        }
        if (payload === 'TolerabilityFaceLongTerm') {
          sendTracking(437)
        }
        if (payload === 'TolerabilityTrunkLongTerm') {
          sendTracking(442)
        }
        if (payload === 'SummaryPatientSavingsCard') {
          sendTracking(732)
        }
        if (payload === 'ImportantSafetyInformation') {
          sendTracking(733)
        }
        if (payload === 'PrescribingInformation') {
          sendTracking(734)
        }
        if (payload === 'References') {
          sendTracking(735)
        }
        if (payload === 'InnovationMoa') {
          sendTracking(705)
        }
        if (payload === 'EfficacyStudyDesignModal') {
          sendTracking(711)
        }
        if (payload === 'Patient1') {
          sendTracking(713)
        }
        if (payload === 'Patient2') {
          sendTracking(714)
        }
        if (payload === 'Patient3') {
          sendTracking(715)
        }
        if (payload === 'Patient4') {
          sendTracking(716)
        }
      } else {
        state.modal.isVisible = !state.modal.isVisible
      }
    }
  },
  actions: {

  }
})
