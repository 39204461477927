<template>
  <div class="references">
    <div class="main-content">
      <h2>REFERENCES</h2>
      <div class="p">
        <div>
          <strong>1.</strong> Tan J, et al. <i>J Am Acad Dermatol</i>. 2019;80(6):1691-1699.
          <strong>2.</strong> Galderma. Data on File. START Clinical Study Report: RD.06.SPR.202395 (May 2023).
          <strong>3.</strong> Blume-Peytavi U, et al. J Eur Acad Dermatol Venereol. 2020;34(1):166-173. <br />
          <strong>4.</strong> AKLIEF Press Release. Federal Drug Administration approval 2019.
          <strong>5.</strong> Del Rosso JQ, Bikowski JB, Baum E, et al. A closer look at truncal acne vulgaris: prevalence, severity, and
          clinical significance. <i>J Drugs Dermatol.</i> 2007;6(6):597-600.
          <strong>6.</strong> Galderma Laboratories, L.P. Burden Summary Report: Combined Facial & Truncal Acne. March 2021. <br />
          <strong>7.</strong> AKLIEF (trifarotene) Cream 0.005% [Prescribing Information].
          <strong>8.</strong> Callender VD, Alexis AF, Daniels SR, et al. Racial differences in clinical
          characteristics, perceptions and behaviors, and psychosocial impact of adult female acne. <i>J Clin Aesthet Dermatol</i>.
          2014;7(7):19-31.
          <strong>9.</strong> Fisher GJ, Talwar HS, Xiao JH, et al. Immunological Identification and
          functional quantitation of retinoic acid and retinoid X receptor proteins in human skin. <i>J Biol Chem</i>.
          1994;269(32):20629-20635.
          <strong>10.</strong> Aubert J, Piwnica D, Bertino B, et al. Nonclinical and human
          pharmacology of the potent and selective topical retinoic acid receptor-γ agonist trifarotene. <i>Br J Dermatol</i>.
          2018;179(2):442-456. <br />
          <strong>11.</strong> Czernielewski J, Michel S, Bouclier M, Baker M, Hensby JC. Adapalene biochemistry and
          the evolution of a new topical retinoid for treatment of acne. <i>J Eur Acad Dermatol Venereol</i>. 2001;15 Suppl 3:5-12.
          <strong>12.</strong> Goyette P, Chen Cf, Wang W, Senguin F, Lohnes D. Characterization of retinoic acid receptor-deficient keratinocytes. <i>J Biol
          Chem</i>. 2000;275(22):16497-16505.
          <strong>13.</strong> Data on File, Galderma Laboratories, L.P.
          <strong>14.</strong> Parente ME, Gámaro A, Solana G. Study of sensory properties of emollients used in cosmetics and their correlation with physicochemical
          properties. <i>J Cosmet Sci</i>. 2005;56(3):175-182.
          <strong>15.</strong> Nikitakis J, Lange, B. Medium chain
          trigylcerides. In: <i>International Cosmetic Ingredient Dictionary and Handbook</i>. 16th ed. Personal Care Products Council;
          2016:299.
          <strong>16.</strong> Sehti A, Kaur T, Malhotra SK, Gambhir ML. Moisturizers: the slippery road. <i>Indian J
          Dermatol</i>. 2016;61(3):279-287.
          <strong>17.</strong> Galderma. Clinical Study Report 18251.
          <strong>18.</strong> Data on File. Galderma Laboratories, L.P.
          Study RD.06.SRE.018251 and Study RD.06.SRE.018252. Data source: TFL 14.2.3.4.1
          <strong>19.</strong> Data on File. Galderma Laboratories, L.P. CSR RD.06.SPR.202394. 2021.
          <strong>20.</strong> Galderma. Data on File. LEAP Clinical Study Report:
          RD.06.SRE.204245 (May 2023).
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {}
</script>
<style lang="scss">
.modal-references {
  .modal__body {
    height: 612px;
    padding: 16px 0 21px 49px;
    top: -6px;

    h2 {
      font-size: 28px;
      margin: 25px 0 0 1px;
      letter-spacing: 1.01px;
      font-family: "AG Bold Condensed";
      color: $modal-title-color;
    }

    .p {
      margin: 27px 0 0 0;
      padding: 0;
      display: block;
      width: 815px;
      color: $font-color-gray;
      line-height: 20.5px;
      font-size: 16px;
      letter-spacing: -0.19px;
      font-family: "Sofia Pro Light";

      strong {
        font-family: "Sofia Pro Bold";
        line-height: 0px;
        letter-spacing: -0.3px;
      }

      >div {
        margin-bottom: 0.75px;
      }
    }
  }
}</style>
