<template>
  <div class="trifarotene-modal">
    <div class="row">
      <div class="column column-1 background-blue">
        <div class="image">
          <img
            src="@/assets/images/version3/trifarotene-molecule-white.png"
            alt=""
          />
          <div class="title">
            Trifarotene <br />is the first <br />topical retinoid<br />
            MOLECULE to<br />
            target RAR-&gamma;
          </div>
          <div class="subtitle">
            the most common retinoic acid receptor in the skin.<sup>3,11</sup>
          </div>
        </div>
      </div>
      <div class="column column-2">
        <div class="title">
          Unlike other retinoids, Trifarotene has a 20x greater affinity <br />
          to RAR-&gamma;.<sup>12</sup>
        </div>
        <div class="para">
          Genes express themselves differently in skin with acne compared to
          healthy <br />
          skin. Targeting RAR-&gamma; with a retinoid like
          <span class="bold"
            >trifarotene modifies gene expression, <br />
            inhibiting immune-cell migration and inflammation in patients with
            acne.</span
          ><sup>15</sup>
        </div>
        <div class="title title-2">
          In a study, patients with acne on their back had biopsies of their
          <br />skin taken to compare<sup>15</sup>:
        </div>
        <div class="para para-2">
          <div><span class="bullet">•</span>Healthy skin</div>
          <div>
            <span class="bullet">•</span>Acne lesions
            <div class="sub">
              <span class="bullet-dash">- </span>before treatment <br /><span
                class="bullet-dash"
                >- </span
              >after treatment with a vehicle <br /><span class="bullet-dash"
                >- </span
              >after treatment with trifarotene
            </div>
          </div>
        </div>
        <div class="title title-3">
          Gene expression was analyzed to determine the difference <br />
          between naturally healing acne and treatment with trifarotene.<sup
            >15</sup
          >
        </div>
        <div class="para para-3">
          <div>
            <span class="bullet">•</span>354 genes were identified to have
            changes in expression in both acne lesions <br />
            and in trifarotene-treated healed lesions
          </div>
          <div>
            <span class="bullet">•</span>In healed lesions, of those 354 genes,
            <span class="bold">67 were unique to trifarotene treatment</span>
          </div>
        </div>
        <div class="title title-4">
          Trifarotene can modify the gene expression of 67 genes integral
          <br />to immunity, inflammation, skin-cell turnover, and skin
          repair.<sup>15</sup>
        </div>
        <div class="para para-4">
          Trifarotene induces expression of genes involved in skin hydration and
          cutaneous <br />
          barrier function.<sup>15</sup>
        </div>
        <div class="para para-5">
          The exact process by which trifarotene ameliorates acne is unknown.
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {}
</script>
<style lang="scss">
.modal-trifarotene {
  .modal__body {
    width: 995px;
    height: 696px;
    top: -9px;
    padding: 0;
  }

  .modal__close {
    width: 958px;
    top: 34px;
    position: relative;
    button {
      background: url(~@/assets/images/close-icon.svg) no-repeat;
      width: 14px;
      height: 14px;
    }
  }
}

.trifarotene-modal {
  font-family: "Sofia Pro";
  height: 541px;
  margin: 0 0 0 -10px;
  color: $font-color-gray;

  .bold {
    font-family: "Sofia Pro Bold";
  }

  h2 {
    font-size: 18.5px;
  }

  sup {
    line-height: 0;
    font-size: 60%;
    position: relative;
    top: 1px;
    left: -2px;
  }

  .title {
    font-size: 40px;
    line-height: 40px;
    letter-spacing: 0;
    color: #58595b;
    font-family: "Oregon";
    margin: 2px 0 38px -1px;
  }

  .description {
    font-size: 18px;
    line-height: 21.5px;
    color: #58595b;
    letter-spacing: -0.15px;
    white-space: nowrap;
    p {
      font-family: "Oregon";
      margin: 8px 0 23px -1px;
      letter-spacing: 0.05px;
    }
    sup {
      margin-top: -3px;
      display: inline-block;
      font-size: 10px;
    }
    img {
      width: 545px;
      margin: 0 0 0 -8px;
    }
  }

  .row {
    display: flex;
    justify-content: initial;

    .column {
      flex: 1;
      flex: 0 0 auto;
    }
  }

  .text-blue {
    color: $light-blue;
  }

  .column-1 {
    width: 354px;
    height: 690px;
    text-align: center;
    .image img {
      margin-top: 89px;
      margin-left: 7px;
      width: 255px;
    }
    .title {
      color: #fff;
      margin: 22px 0 0 2px;
      line-height: 1.1;
      letter-spacing: -1px;
      position: relative;
      left: 2px;
    }
    .subtitle {
      line-height: 1.2;
      font-size: 20px;
      margin: 8px 0 0 10px;
    }
  }

  .column-2 {
    padding: 80px 40px 0;
    .title {
      color: $dark-blue;
      font-size: 17.5px;
      line-height: 1.2;
      letter-spacing: 0.1px;
      margin: 0 0 15px 0;
      &.title-3 {
        margin: 16px 0 7px 0;
      }
    }
    .para {
      line-height: 1.2;
      letter-spacing: -0.05px;
      font-size: 15px;
      margin: 0 0 13px 0;
      &.para-2 {
        margin: -10px 0 0 0px;
        div {
          margin: 0 0 2px 0;
          line-height: 1.5;
        }
        .sub {
          margin: 0 0 0 10px;
        }
      }
      &.para-3 {
        text-indent: -12px;
        margin: 0 0 17px 12px;
      }
    }
  }

  .bullet {
    color: $dark-blue;
    margin: 0 5px 0 0;
  }

  .bullet-dash {
    margin: 0 5px 0 0;
  }

  .background-blue {
    color: #fff;
    background: $dark-blue;
  }

  .sidebar {
    position: absolute;
    top: 0;
    left: -6px;
    bottom: 0;
    right: initial;
    text-align: center;
    font-size: 15px;
    width: 273px;

    > div:nth-child(1) {
      margin: 80px 0 0 0;
      text-transform: uppercase;
      line-height: 1.2;
    }

    .text-large {
      font-size: 31px;
      line-height: 0.975;
      margin: 8px 0 65px 0;
      font-family: "Oregon";
      letter-spacing: -0.5px;
    }

    .text-small {
      text-align: left;
      font-size: 13px;
      line-height: 1.3;
      margin: 0 0 0 30px;
    }
  }
}
</style>
