<template>
  <page>
    <div class="content">
      <!-- Title -->
      <div class="title">
        <h1>
          OVER 300 PATIENTS WITH SKIN OF COLOR TOOK<br />
          PART IN THE AKLIEF Cream PIVOTAL STUDIES.<sup>1</sup>
        </h1>
      </div>

      <!-- Description -->
      <div class="description">
        <div class="main-image">
          <img
            src="~@/assets/images/version-4/jpg-png/skin-of-color-img.jpg"
            alt=""
          />
          <span class="copy">Not actual patients.</span>
        </div>

        <div class="main-content">
          <div class="text-top">
            Percentages of women with clinical acne<sup>8</sup>:
          </div>

          <div class="text-center">
            <div class="left-2">
              <img src="~@/assets/images/version-4/svg/37.svg" alt="" />
              <p>Black/African <br />American</p>
            </div>

            <div class="middle">
              <img src="~@/assets/images/version-4/svg/32.svg" alt="" />
              <p>Hispanic/ <br />Latina</p>
            </div>

            <div class="right">
              <img src="~@/assets/images/version-4/svg/30.svg" alt="" />
              <p>Asian</p>
            </div>

            <div class="right2">
              In contrast, clinical
              <br />acne affects 23% of <br />Continental Indian <br />and 24%
              of White/ <br />Caucasian women.<sup>8</sup>
            </div>
          </div>

          <div class="text-bottom">
            <h3>AKLIEF Cream and patients with skin of color</h3>
            <ul>
              <li>
                Proven effectiveness and tolerability for patients across a
                range of <br />Fitzpatrick scale skin types<sup>3</sup>
              </li>
              <li>
                Darker Fitzpatrick scale skin types (IV-VI) on average
                demonstrated <br />equal or better tolerability with AKLIEF
                Cream over a 52-week long-term<br />
                safety study<sup>7</sup>
              </li>
              <li>
                In clinical trials, the most common adverse reactions (incidence
                > 1%) in <br />patients treated with AKLIEF Cream were
                application site irritation, <br />application site pruritus
                (itching), and sunburn<sup>1</sup>
              </li>
            </ul>
            <div class="image">
              <img
                src="~@/assets/images/version-4/jpg-png/aklief-1.png"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </page>
</template>

<script>
import Page from '@/components/common/Page'
export default {
  name: 'skin-of-color',
  components: {
    page: Page
  }
}
</script>

<style lang="scss">
.page.patient-profiles.skin-of-color {
  background-image: url("~@/assets/images/version-4/jpg-png/bg-screen.jpg");
  background-repeat: no-repeat;
  background-position: center 0;
  background-size: 100%;
  color: #fff;
  ul.nav ul.inner-nav > li .custom-link.router-link-active:before {
    left: 10px;
  }
  img {
    max-width: 100%;
  }
  sup {
    font-size: 60%;
  }
  .title {
    h1 {
      color: $dark-blue;
      font-family: "AG Bold Condensed";
      font-size: 41px;
      line-height: 0.975;
      margin: 6px 0 18px 40px;
      letter-spacing: 0.1px;
    }
    sup {
      font-family: "AG Light Condensed";
      font-size: 50%;
    }
  }
  .description {
    display: flex;
    color: $dark-blue;
    > .main-image {
      order: 2;
      flex: 0 0 361px;
      position: relative;
      .copy {
        position: absolute;
        bottom: 17px;
        right: 17px;
        color: $white;
        font-family: "AG Medium";
        font-size: 12px;
      }
    }
    > .main-content {
      background: #fff;
      padding: 40px 50px 0px;
      height: 444px;
      width: 528px;
      .text {
        &-top {
          font-family: "AG Medium";
          font-size: 20px;
          line-height: 1;
          margin: 22px 0 0 15px;
          sup {
            font-family: "AG BQ Regular";
          }
        }
        &-center {
          font-family: "Sofia Pro";
          display: flex;
          width: 530px;
          padding: 22px 0 0 38px;
          img {
            width: 92px;
            display: block;
            margin: auto;
          }
          > div {
            flex: auto;
          }
          > .left-2 {
            img {
              position: relative;
              left: -36px;
              width: 99px;
            }
            p {
              position: relative;
              top: -5px;
              left: -35px;
              font-size: 16.5px;
              line-height: 1.15;
              text-align: center;
              font-family: "AG Medium";
            }
          }
          > .middle {
            img {
              position: relative;
              top: 3px;
              left: -25px;
              width: 98px;
            }
            p {
              position: relative;
              top: -5px;
              left: -28px;
              font-size: 16.5px;
              line-height: 1.15;
              text-align: center;
              font-family: "AG Medium";
            }
          }
          > .right {
            img {
              position: relative;
              top: 4px;
              left: -12px;
              width: 98px;
            }
            p {
              position: relative;
              top: -5px;
              left: -10px;
              font-size: 16.5px;
              line-height: 0.95;
              text-align: center;
              font-family: "AG Medium";
            }
          }
          > .right2 {
            text-align: left;
            position: relative;
            top: 10px;
            left: 7px;
            font-size: 13.5px;
            line-height: 1.26;
            font-family: "Sofia Pro";
          }
        }
        &-bottom {
          width: 593px;
          margin: 10px 0 0 4px;
          padding: 45px 25px 0 50px;
          box-sizing: border-box;
          font-family: "Sofia Pro";
          img {
            width: 74px;
            height: auto;
            position: absolute;
            right: 21px;
            top: 80px;
          }
          h3 {
            font-family: "AG Medium";
            font-size: 13px;
            letter-spacing: -0.3px;
            margin: 22px 0 0 1px;
          }
          ul {
            margin: 0;
            padding: 1px 0 0 15px;
          }
          p,
          ul,
          li {
            font-size: 12px;
            color: $font-color-gray;
            margin: 2px 0 0 0;
            line-height: 1.2;
          }
        }
      }
    }
  }
}
</style>
