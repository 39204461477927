<template>
  <div class="tolerability-face-content">
    <div slot="content">
      <div class="tabs__content">
        <!-- Description -->
        <div class="description">
          <div class="inner">
            ALL MEAN<br />
            TOLERABILITY SCORES <br />
            ON THE 4 PARAMETERS <br />
            MEASURED <br />
            REMAINED BELOW <br />
            <div class="large">MILD<sup>1</sup></div>
            <br />
            <div class="line"></div>
            <div class="bottom">
              <div class="bottom-1">
                IN THE PIVOTAL <br />
                STUDIES, <br />
              </div>
              <div class="large">
                <span class="number">9</span>
                <span class="out-of"> out of </span>
                <span class="number">10</span>
              </div>
              <div class="bottom2">
                SUBJECTS COMPLETED <br />
                THE TRIAL<sup>1</sup>
              </div>
            </div>
          </div>
        </div>

        <!--  Chart -->
        <div class="chart">
          <div class="chart__title">
            Trunk: Tolerability Signs and Symptoms at Week 12<sup>1</sup>
          </div>
          <div class="chart__image">
            <img src="~@/assets/images/version-4/svg/chart-6.svg" alt="" />
          </div>
        </div>

        <!-- Footnote -->
        <div class="foot-note">
          <ul>
            <li>
              Mean tolerability scores for erythema, scaling, dryness, and
              stinging/burning peaked at Weeks 2 and 4 and decreased
              thereafter<sup>1</sup>
            </li>
            <li>
              Local tolerability signs and symptoms were evaluated on a 4-point
              severity scale: 0 (none), 1 (mild), 2 (moderate), and 3 (severe)
              from <br />baseline to Week 12—all scores stayed below 1 through
              Week 12<sup>1</sup>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'tolerability-face-tab'
}
</script>

<style lang="scss" scoped>
.description {
  position: absolute;
  width: 276px;
  right: 0px;
  bottom: 0;
  left: initial;
  top: 51px;
  background: $modal-border-color;
  text-align: center;
  padding: 64px 0px 0 0;
  font-weight: bold;

  .inner {
    position: relative;
    left: -1px;
    line-height: 17px;
    font-size: 16px;
    letter-spacing: -1.1px;
    font-family: "Sofia Pro Bold";
  }

  .large {
    font-size: 48px;
    font-family: "AG Bold Condensed";
    color: $light-green;
    font-weight: normal;
    line-height: 40px;

    sup {
      font-family: "AG Light Condensed";
      font-size: 45% !important;
    }
  }

  .line {
    border-top: 1px solid $light-green;
    width: 160px;
    margin: 20px auto 30px;
  }

  .bottom {
    padding: 0 0 0 6px;

    .large {
      font-size: 34px;
    }

    .number {
      font-size: 61px;
      position: relative;
      left: -4px;
    }

    .out-of {
      position: relative;
      left: 0px;
    }
  }

  .bottom2 {
    padding: 0 2px 0 0;
    sup {
      font-family: "Sofia Pro Light";
    }
  }
}

.chart {
  position: absolute;
  width: 670px;
  left: 0;
  &__title {
    color: $dark-blue;
    margin: 24px 0 19px 44px;
    font-size: 19.5px;
    letter-spacing: -0.4px;
    font-family: "AG Medium";
    sup {
      font-family: "AG Light";
    }
  }
  &__image {
    padding: 11px 31px 0 43px;
  }
}

.foot-note {
  color: $font-color-gray;
  position: absolute;
  top: 473px;
  color: #58595b;
  font-size: 10px;
  left: 53px;
  letter-spacing: -0.03px;
  line-height: 1.2;
  ul {
    margin: 0;
    padding: 0;
  }
}
</style>
