<template>
  <div class="efficacy-study-design-modal text-color-1">
    <header>
      <div class="pretitle"><strong>STUDY DESIGN</strong></div>
      <h1 class="title">Pivotal Studies 1 & 2</h1>
      <div class="description">
        <p>
          <span class="strong-text"
            >First large-scale clinical trials.<sup>1,4</sup></span
          ><br />PIVOTAL Studies 1 & 2 were the first large-scale, randomized,
          Phase 3 clinical trials to simultaneously evaluate a topical therapy
          for the treatment of both moderate facial and truncal acne
          vulgaris.<sup>1,4</sup>
        </p>
      </div>
    </header>
    <div class="full-width-area">
      <div class="row">
        <div class="column column--patients text-center">
          <h2>
            <div class="text-xl">2,420</div>
            <span
              >patients combined <br />from PIVOTAL <br />studies 1 & 2;
              Randomized 1:1</span
            >
          </h2>
          <p>
            from sites across US, Canada, Europe, and<br />
            Russia Mean age:<br />
            19 years (9-58 years); <br />AKLIEF Cream once daily<sup>1</sup>
          </p>
        </div>
        <div class="column column--cream">
          <img
            src="@/assets/images/version-4/svg/graphic-1.svg"
            alt="treatment arms"
            width="256.5"
          />
        </div>
        <div class="column column--endpoints">
          <h2>Primary Endpoints (Face)<sup>1</sup></h2>
          <ul>
            <li>
              <div class="bullet-space-fix">
                Success rate: Percentage of subjects with an
                <b>Investigator’s Global Assessment (IGA)</b>* of clear (0) or
                almost clear (1) and at least a 2-grade improvement from
                baseline to Week 12
              </div>
            </li>
            <li>
              <div class="bullet-space-fix">
                Absolute change in facial inflammatory and non-inflammatory
                lesion count from baseline to Week 12
              </div>
            </li>
          </ul>
          <h2>Secondary Endpoints (Trunk)<sup>1</sup></h2>
          <ul>
            <li>
              <div class="bullet-space-fix">
                Success rate: Percentage of subjects with a
                <b>Physician’s Global Assessment (PGA)</b>* of clear (0) or
                almost clear (1) and at least a 2-grade improvement from
                baseline to Week 12
              </div>
            </li>
            <li>
              <div class="bullet-space-fix">
                Absolute change in truncal inflammatory and non-inflammatory
                lesion count from baseline to Week 12
              </div>
            </li>
          </ul>
          <h2>Safety Endpoints<sup>1</sup></h2>
          <ul>
            <li>
              <div class="bullet-space-fix">
                Incidence of adverse events
              </div>
            </li>
            <li>
              <div class="bullet-space-fix">
                Local tolerability (erythema, scaling, dryness,
                stinging/burning)
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <p class="footnotes text-xs">
      *The definitions of severity for the 5-point IGA (face) and PGA (trunk)
      scales were the same: 0 (clear), 1 (almost clear), 2 (mild), 3 (moderate),
      and 4 (severe).<sup>1</sup>
    </p>
  </div>
</template>
<script>
export default {}
</script>
<style lang="scss">
.modal-efficacy-study-design-modal {
  .modal {
    &__body {
      padding: 0;
      height: 618px;
    }
  }
}
.efficacy-study-design-modal {
  font-family: "Sofia Pro";
  height: 541px;

  header {
    padding: 43px 0 0 71px;
  }

  h2 {
    font-size: 18.3px;
  }

  .title {
    line-height: 40px;
    margin: 0 0 0 -1px;
  }

  .pretitle {
    font-size: 11px;
    color: $light-blue-2;
    margin-top: 2px;
  }

  .description {
    font-size: 15px;
    line-height: 21.5px;
    letter-spacing: -0.15px;
    width: 750px;
    p {
      margin: 8px 0 28px;
      strong {
        letter-spacing: 0.1px;
      }
    }
    sup {
      margin-top: -3px;
      display: inline-block;
      font-size: 10px;
      font-family: "Sofia Pro Light";
    }

    .strong-text {
      font-family: "Sofia Pro Bold";
      font-size: 15.8px;
    }
  }

  .row {
    display: flex;
    justify-content: left;
    align-items: center;
    width: 100%;
    .column {
      h2 {
        color: $font-color-rose;
        sup {
          font-size: 10px;
          font-family: "AG Light";
        }
      }

      &--patients {
        width: 165px;
        margin-left: 20px;
        margin-top: 3px;

        h2 {
          line-height: 1.13;
          letter-spacing: -0.3px;
          margin-bottom: 6px;
          margin-top: 0;
          span {
            display: block;
            margin: 3px 0 0 5px;
          }
          .text-xl {
            letter-spacing: -0.1;
            font-family: "AG Bold Condensed";
            margin: 9px 0 3px 7px;
          }
        }
        p {
          font-size: 14px;
          line-height: 1.1;
          letter-spacing: -0.1px;
          margin-top: 0;
          sup {
            font-size: 7px;
          }
        }
      }

      &--cream {
        width: 252px;
        img {
          width: 100%;
        }
      }

      &--endpoints {
        width: 442px;
        margin-left: 15px;
        margin-top: -4px;
        h2 {
          margin: 0 0 1px 0;
        }
        ul {
          margin: 0 0 4px 0;
          padding: 0 0 0 13px;
          font-size: 15px;
          line-height: 1.2;
          letter-spacing: -0.1px;
          font-family: "Sofia Pro Light";
          li {
            list-style: none;
            position: relative;
            &:before {
              content: "■";
              width: 3px;
              height: 3px;
              font-size: 12px;
              position: absolute;
              top: 2px;
              left: -15px;
              display: block;
            }
          }
          .bullet-space-fix {
            position: relative;
            left: -1px;
          }
          b {
            letter-spacing: -1px;
          }
        }
      }
    }
  }

  .footnotes {
    margin: 33px 0 0 72px;
    letter-spacing: -0.125px;
    sup {
      font-size: 6px;
    }
  }

  .full-width-area {
    background: $light-gray;
    margin: 0;
    height: 339px;
    display: flex;
    align-items: center;
  }
}
</style>
