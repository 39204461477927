<template>
  <page>
    <div class="content">
      <h1 class="content-main-heading">
        AKLIEF<sup>&reg;</sup> (trifarotene) Cream, 0.005%:<br />
        THE FIRST TOPICAL TREATMENT<br />
        CLINICALLY PROVEN TO BE EFFECTIVE<br />
        FOR FACIAL AND TRUNCAL ACNE.<sup class="number">4</sup>
      </h1>
      <div class="content-main-info">
        <div class="content-main-info-wrapper">
          <div class="item innovation">
            <img
              src="@/assets/images/version-4/jpg-png/icon-5.png"
              width="60"
              alt=""
            />
            <div class="item-content-info">
              <h2>Innovation</h2>
              <ul>
                <li>
                  Selectively targets the skin's most common retinoid receptor,
                  RAR-&gamma;<sup>1</sup>
                </li>
                <li>
                  The exact process by which trifarotene ameliorates acne is
                  unknown
                </li>
              </ul>
            </div>
          </div>
          <div class="item rapid-results">
            <img
              src="@/assets/images/version-4/jpg-png/icon-4.png"
              width="60"
              alt=""
            />
            <div class="item-content-info">
              <h2>Rapid Results* & Powerful Efficacy<sup>1</sup></h2>
              <ul>
                <li>
                  In both clinical trials, >50% fewer inflammatory lesions on
                  the face and <br />
                  trunk at Week 12<sup>1</sup>
                </li>
                <li>
                  Results visible in as little as 2 weeks on the face<sup
                    >1</sup
                  >
                </li>
              </ul>
            </div>
          </div>
          <div class="item proven-long-term-safety">
            <img
              src="@/assets/images/version-4/jpg-png/icon-3.png"
              width="60"
              alt=""
            />
            <div class="item-content-info">
              <h2>Proven Long-Term Safety & Tolerability<sup>3</sup></h2>
              <ul>
                <li>
                  Favorable tolerability on both face and trunk, in 12-week and
                  <br />
                  52-week studies<sup>3</sup>
                </li>
              </ul>
            </div>
          </div>
          <div class="item patient-quality">
            <img
              src="@/assets/images/version-4/jpg-png/icon-2.png"
              width="60"
              alt=""
            />
            <div class="item-content-info">
              <h2>Impact on Patient Quality of Life</h2>
              <ul>
                <li>
                  90% of AKLIEF Cream patients surveyed after 1 year of
                  treatment reported <br />
                  their acne had small to no impact on their quality of life<sup
                    >3</sup
                  >
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="main-image">
        <img
          src="@/assets/images/version-4/jpg-png/aklief-3.png"
          height="587.7"
          alt=""
        />
      </div>
      <div class="patient-savings-card-btn">
        <primary-button
          ><div @click="toggleModal('SummaryPatientSavingsCard')">
            Patient Savings Card
          </div></primary-button
        >
      </div>
      <div class="footnote">
        <p>
          *Indicates a 2-week time frame until results on the face; a 4-week
          time frame until results on the trunk.<sup>5</sup>
        </p>
      </div>
    </div>
  </page>
</template>

<script>
import Page from '@/components/common/Page'
import PrimaryButton from '@/components/common/PrimaryButton'

export default {
  name: 'summary-page',
  components: {
    page: Page,
    PrimaryButton
  }
}
</script>

<style lang="scss">
.page.summary {
  background: url(~@/assets/images/version-4/jpg-png/purple-bg.png) no-repeat;
  background-size: 100%;
  .content {
    &-main-heading {
      font-family: "AG Bold Condensed";
      font-size: 40px;
      color: $dark-blue;
      line-height: 1;
      margin-top: 0;
      margin-left: 42px;
      margin-bottom: 0;
      letter-spacing: 0.4px;
      sup {
        font-size: 45%;
        vertical-align: top;
        display: inline-block;
        margin-top: 3px;
      }
      .number {
        font-family: "AG Light";
        display: inline-block;
        margin-left: -3px;
        margin-top: -1px;
      }
      span {
        text-transform: initial;
      }
    }
    &-main-info {
      &-wrapper {
        background: $white;
        width: 983px;
        height: 369px;
        margin-left: 42px;
        padding-left: 25px;
        margin-top: 10px;
        .item {
          display: flex;
          align-items: center;
          margin-top: 0;
          sup {
            font-size: 60%;
          }
          img {
            margin-right: 20px;
          }
          &-content-info {
            h2 {
              font-family: "AG Medium";
              font-size: 16px;
              color: $dark-blue;
              margin-bottom: 2px;
              letter-spacing: -0.28px;
              sup {
                font-family: "AG BQ Regular";
              }
            }
            ul {
              margin-top: 0;
              padding-left: 15px;
              li {
                font-size: 14px;
                color: $font-color-gray;
                list-style-type: square;
                letter-spacing: -0.14px;
              }
            }
          }
        }
        .innovation {
          padding-top: 15px;
          margin-left: -2px;
          li {
            margin-left: -2.5px;
          }
        }
        .rapid-results {
          img {
            margin-top: -10px;
          }
          h2 {
            margin-top: 9px;
            margin-left: -2px;
            letter-spacing: -0.13px;
          }
          li {
            margin-left: -5px;
            line-height: 1.25;
          }
        }
        .proven-long-term-safety {
          img {
            margin-top: -13px;
          }
          h2 {
            margin-top: 0px;
            margin-left: -4px;
            letter-spacing: -0.02px;
          }
          li {
            margin-left: -5px;
            line-height: 1.25;
          }
        }
        .patient-quality {
          img {
            margin-top: -12px;
          }
          h2 {
            margin-top: 5px;
            margin-left: -2px;
            letter-spacing: -0.03px;
          }
          li {
            letter-spacing: -0.1px !important;
            margin-left: -6px;
            line-height: 1.3;
          }
        }
      }
    }
    .patient-savings-card-btn {
      margin-top: 14px;
      margin-left: 231px;
      a {
        font-family: "AG Medium";
        padding: 7.5px 14px;
        span {
          font-size: 14px;
          letter-spacing: 0.3px;
        }
      }
    }
    .main-image {
      position: absolute;
      top: 87px;
      right: 25px;
    }
    .footnote {
      position: absolute;
      right: 39px;
      bottom: 77px;
      p {
        font-family: "Sofia Pro";
        font-size: 10px;
        color: $dark-blue;
        letter-spacing: -0.1px;
        sup {
          font-size: 60%;
        }
      }
    }
  }
}
</style>
