<template>
    <div class="start-result-patient3">
        <header>
            <div class="pretitle"><strong>Start Study</strong></div>
            <h1 class="title">Patient 3</h1>
        </header>
        <main>
            <img src="@/assets/images/version-4/jpg-png/start-results/col-3-results.png" alt="" />
        </main>
    </div>
</template>
<script>
export default {}
</script>
<style lang="scss">
.modal-start-result-patient3 {
    .modal {
        &__body {
            padding: 24px 0 24.5px 15px;
            width: 846px;
            margin-left: -20px;
            margin-top: 3px;
        }
    }

    .start-result-patient3 {
        display: flex;
        flex-wrap: nowrap;

        header {
            width: 142px;
        }

        .title {
            line-height: 40px;
            margin: 0 0 0 -1px;
        }

        .pretitle {
            font-size: 11px;
            color: $light-blue-2;
            margin-top: 4px;
            text-transform: uppercase;
        }

        img {
            height: 562px;
            width: 610px;
        }
    }
}
</style>
