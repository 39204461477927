<template>
  <page>
    <div class="content">
      <div class="title">
        <h1>
          AKLIEF<sup>®</sup> (TRIFAROTENE) CREAM, 0.005%<br />DEMONSTRATED FAVORABLE TOLERABILITY
        </h1>
        <div>
          <ul class="nav inner-nav-dual">
            <li>
              <router-link :to="{ name: 'dual-study:start-efficacy' }"><span>Efficacy</span></router-link>
            </li>
            <li>
              <router-link class="custom-link"
                :to="{ name: 'dual-study:start-results' }"><span>Results</span></router-link>
            </li>
            <li>
              <router-link class="custom-link"
                :to="{ name: 'dual-study:start-tolerability' }"><span>Tolerability</span></router-link>
            </li>
          </ul>
        </div>
      </div>
      <div class="main-content">
        <div class="main-content__inner">
          <h2>Almost all subjects (99%) remained on AKLIEF Cream for 24 weeks in the Phase 4 scarring study
            (START) with less than 2% of<br />subjects reporting burning sensation, erythema and skin
            tightness.<sup>2</sup>
          </h2>
          <div class="graph-container">
            <div class="box">
              <img class="graph" src="@/assets/images/version-4/jpg-png/start-tolerability/start-tolerability-graph.png"
                alt="" />
              <a class="enlarge" href="#" @click="toggleModal('StartTolerabilityGraph')"></a>
            </div>
          </div>
        </div>
        <div class="graph-footer">
          <small>
            Local TEAEs (localized to right or left side of face) are presented by treatment side (AKLIEF Cream versus
            vehicle) and overall. Bilateral local TEAEs<br /> are presented in AKLIEF Cream group and overall. TEAEs that
            are subject specific are presented overall only.<br />*Each subject is counted only once within the closest
            relationship to the study drug and the greatest severity. TEAEs under the subject’s closest<br />relationship
            to the study drug and the subject’s greatest severity are counted in the events columns.<sup>2</sup>
          </small>
          <div class="study-design-container">
            <a href="#" class="button" @click="toggleModal('StartStudyDesign')">
              <p>Study Design</p>
            </a>
          </div>
        </div>
      </div>
    </div>
  </page>
</template>

<script>
import Page from '@/components/common/Page'

export default {
  name: 'start-tolerability',
  components: {
    page: Page
  }
}
</script>
<style lang="scss" scoped>
.page.start-tolerability {
  .title {
    color: $dark-blue;
    margin: 2px 0 10px 41px;
    position: relative;

    h1 {
      font-family: "AG Bold Condensed";
      font-size: 41px;
      line-height: 0.975;
      margin: 0 0 6px 0;
      text-transform: uppercase;

      sup {
        font-family: "AG Light Condensed";
        font-size: 56%;
        font-weight: 600;
        text-align: center;
      }
    }

    p {
      font-size: 18px;
      margin: -2px 0 2px 0;
      line-height: 17px;
      font-family: "AG Medium";
      top: 42px;
      left: 192px;

      sup {
        font-size: 10px;
        font-family: "AG BQ Regular";
      }
    }

    small {
      font-size: 9.5px;
      text-align: left;
      line-height: 13px;
      letter-spacing: 0px;
      color: $white;

      sup {
        font-family: "AG Light Condensed";
        font-size: 5px;
      }
    }
  }

  .main-content {
    border-top: 7px solid $light-green;
    color: $font-color-gray;
    background: $white;
    padding: 13px 43px 0px 43px;
    height: 438.5px;
    width: 990px;
    box-sizing: border-box;
    position: relative;
    margin-top: 60px;

    h2 {
      color: $modal-title-color;
      font-size: 14px;
      margin-top: 0px;
      margin-bottom: 16px;
      letter-spacing: 0px;
      font-family: "AG Medium";
      line-height: 15px;

      sup {
        font-size: 8px;
        vertical-align: text-top;
      }
    }

    .graph-container {
      display: flex;
      justify-content: center;
      margin-bottom: 20px;

      img {
        width: 649px;
      }
    }

    .box {
      position: relative;
    }

    .graph-footer {
      display: flex;
      width: 100%;
      justify-content: space-between;

      small {
        font-size: 10px;
        width: auto;
        font-family: "Sofia Pro";
        line-height: 12.9px;
        display: block;
        letter-spacing: -0.05px;
        color: $black;

        sup {
          font-size: 5px;
          line-height: 8px;
          vertical-align: text-top;
        }
      }

      .study-design-container {
        display: flex;
        align-self: flex-end;
        margin-bottom: 4px;

        .button {
          padding: 9.5px 15px;
          font-family: "AG Medium";
          -webkit-transform: skew(-15deg);
          transform: skew(-15deg);
          font-size: 14px;
          text-transform: uppercase;
          text-decoration: none;
          display: inline-block;
          color: $white;
          background-color: $dark-blue;

          p {
            margin: 0;
            -webkit-transform: skew(15deg);
            transform: skew(15deg);
          }
        }
      }
    }
  }

  .enlarge {
    width: 29px;
    height: 29px;
    position: absolute;
    top: -14px;
    right: -14px;
    z-index: 99;
    background: url("~@/assets/images/version-4/svg/enlarge.svg") no-repeat;
    background-size: cover;
    display: block;
  }
}

.page.dual-study {
  .inner-nav-dual {
    top: 90px;
  }
}
</style>
