<template>
  <div class="tolerability-face-long-term">
    <div class="row">
      <div class="column">
        <div class="pretitle strong-parag">LONG-TERM SAFETY</div>
        <h1 class="title">WELL-TOLERATED THROUGHOUT <br>THE 1-YEAR study.<sup>4</sup></h1>
        <div class="description">
          <p><span class="text-blue">FACE: </span>TOLERABILITY SIGNS AND SYMPTOMS AT WEEK 52.<sup>4</sup></p>
          <img src="@/assets/images/tolerability-face-overlay.svg" alt="">
        </div>
      </div>
      <div class="column">
        <div class="background-blue sidebar">
          <div>As observed with <br>other retinoids, </div>
          <div class="text-large">the majority <br>of local <br>adverse events <br>occurred during <br>the first weeks <br>of treatment, <br>subsiding <br>thereafter.<sup>4</sup></div>
          <div class="text-small"><span class="strong-parag">Results of the long-term safety study:</span><br>A multicenter, open-label study of <br>453 patients with moderate facial <br>and truncal acne assessing the <br>long-term safety (primary endpoint), <br>efficacy, and quality of life <br>(secondary endpoints) of AKLIEF <br>Cream over 52 weeks.<sup>4</sup>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
}
</script>
<style lang="scss">
.tolerability-face-long-term {
  font-family: "Sofia Pro";
  height: 541px;
  margin: 0 0 0 -10px;

  .strong-parag {
    font-family: "Sofia Pro Bold";
  }

  h2 {
    font-size: 18.5px;
  }

  sup {
    line-height: 0;
    font-size: 60%;
  }

  .title {
    font-size: 40px;
    line-height: 40px;
    letter-spacing: 0;
    color: #58595b;
    font-family: "Oregon";
    margin: 2px 0 38px -1px;
  }

  .pretitle {
    font-size: 11px;
    letter-spacing: 1px;
    color: $dark-blue;
    margin: 27px 0 1px 0;
  }

  .description {
    font-size: 18px;
    line-height: 21.5px;
    color: #58595b;
    letter-spacing: -0.15px;
    white-space: nowrap;
    p {
      font-family: "Oregon";
      margin: 8px 0 23px -1px;
      letter-spacing: 0.05px;
    }
    sup {
      margin-top: -3px;
      display: inline-block;
      font-size: 10px;
    }
    img {
      width: 545px;
      margin: 0 0 0 -8px;
    }
  }

  .row {
    display: flex;
    justify-content: initial;

    .column {
      flex: 1;
      flex: 0 0 auto;
    }
  }

  .text-blue {
    color: $light-blue;
  }

  .background-blue {
    color: #fff;
    background: $light-blue;
  }

  .sidebar {
    position: absolute;
    top: 0;
    right: -6px;
    bottom: 0;
    left: initial;
    text-align: center;
    font-size: 15px;
    width: 273px;

    > div:nth-child(1) {
      margin: 80px 0 0 0;
      text-transform: uppercase;
      line-height: 1.2;
    }

    .text-large {
      font-size: 31px;
      line-height: .975;
      margin: 8px 0 65px 0;
      font-family: "Oregon";
      letter-spacing: -0.5px;
    }

    .text-small {
      text-align: left;
      font-size: 13px;
      line-height: 1.3;
      margin: 0 0 0 30px;
    }

  }
}
</style>
