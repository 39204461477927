<template>
  <div class="innovation-moa">
    <video controls="controls" width="750" poster="@/assets/images/version-4/jpg-png/moa-modal.jpg">
        <source src="@/assets/video/aklief-video.mp4" type="video/mp4">
    </video>
  </div>
</template>
<script>
export default {}
</script>
<style lang="scss">
.innovation-moa {
  display: flex;
  justify-content: center;
  align-items: center;
  video {
    display: inline-block;
    padding-bottom: 27px;
    margin-top: 10px;
  }
}
</style>
