<template>
  <div :class="className">
    <div class="header">
      <secondary-navigation></secondary-navigation>
    </div>
    <div class="logo" @click="goHome()">
      <img src="@/assets/images/version-4/jpg-png/aklief-logo.png" alt="logo" />
    </div>
    <main>
      <slot></slot>
    </main>
    <div class="footer">
      <main-navigation></main-navigation>
    </div>
  </div>
</template>

<script>
import SecondaryNavigation from '@/components/common/navigation/SecondaryNavigation'
import MainNavigation from '@/components/common/navigation/MainNavigation'

export default {
  components: {
    SecondaryNavigation,
    MainNavigation
  },
  computed: {
    className: function () {
      return [
        'page',
        this.$route.name
          .replace(/([a-z])([A-Z])/g, '$1-$2')
          .replace(/\s+/g, '-')
          .toLowerCase()
          .split(':')
      ]
    }
  },
  methods: {
    goHome () {
      return this.$router.push('/')
    }
  }
}
</script>

<style lang="scss">
.page {
  height: 724px;
  overflow: hidden;
  position: relative;
}

.header {
  height: 51px;
  width: 100%;
}

.logo {
  position: absolute;
  top: 18px;
  right: 34.5px;

  img {
    width: 173px;
  }
}

.footer {
  display: flex;
  flex-direction: column;
  justify-self: end;
  font-family: "AG Medium";
  font-weight: 600;
  font-style: normal;
  position: absolute;
  width: 100%;
  bottom: 0px;
}
</style>
